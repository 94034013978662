import React from 'react'
import './GenerateFormtitle.css'
import PropTypes from 'prop-types'
// import { ReactPropTypes } from 'react';

function GenerateFormTitle(Props) {

    return(

        <>
        <div id='formMainTitle'>
        <h5 className='FormTitle'>{Props.title}</h5>
        {/* <div className='TitleUl'></div> */}
        </div>
        </>
    )
    
}

export default GenerateFormTitle;