import React from 'react'
import GenerateFormTitle from '../../Components/NotUsable/GenerateFormTitle';
import GenerateForm from '../../Components/NotUsable/GenerateForm';
import Actionheader from './Components/Actionheader';
import axios from 'axios';
import ListHeader from '../../Components/ListHeader/sellerHeader'
import ListContent1 from '../../Components/ListContent/ListContent1';
import './Myinvoices.css'
import PrivateRoute from '../../Authentication/PrivateRoute';
import TopLoadingBar from 'react-top-loading-bar';
import { Link,useNavigate } from 'react-router-dom';
import TopLoader from '../../Components/TopLoader/TopLoader';
import { handleApiError } from '../../Authentication/AuthService';
import { useState, useEffect  } from 'react';
import Alert from '../../Components/CustomAlert/Alert1';
import Dateinput from './Components/dateinput';
import SearchComponent from './Components/searchbox';
import './Components/dateinput.css'
import { Form, Button } from 'react-bootstrap';
import { InputGroup, FormControl } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import axiosInstance from '../../api';




function FollowUp() {
  const [progress, setProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  
  const [isActiveOverlay, setIsActiveOverlay] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  const [alertVariant, setAlertVariant] = useState('success');

  const [data, setData] = useState([]);

  // const [filteredData, setFilteredData] = useState(data);
  
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedItemsIrnNumber, setSelectedItemsIrnNumber] = useState();

  const [selectedItemsEwayNumber, setSelectedItemsEwayNumber] = useState();

  const [selectedItemsSupplierGstin, setSelectedItemsSupplierGstin] = useState();

  const [cancelirnData, setCancelirnData] = useState({
    Irn: '',
    CnlRsn: '',
    CnlRem: ''
    
});

const [cancelEwayData, setCancelEwayData] = useState({
  ewbNo: '',
  CnlRsn: '',
  CnlRem: ''
  
});

const [generateEwayData, setGenerateEwayData] = useState({
  Irn: null,
  Distance: 0,
  TransMode: null,
  TransId: null,
  TransName: null,
  TransDocDt: null,
  TransDocNo: null,
  VehNo: null,
  VehType: null
  
});



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelIrnModalOpen, setIsCancelIrnModalOpen] = useState(false);
  const [isCancelEwayModalOpen, setIsCancelEwayModalOpen] = useState(false);
  const [isGenerateEwayModalOpen, setIsGenerateEwayModalOpen] = useState(false);

  const itemsPerPage = 50;
 
   // const userId = localStorage.getItem('userid');

  const [userId, setuserId] = useState(localStorage.getItem('userid'));
  const [sellerId, setsellerId] = useState(localStorage.getItem('seller_id'));

  const [noDataText, setNoDataText] = useState('No data');
  
  const [sellerGstin, setsellerGstin] = useState(localStorage.getItem('sellerGstin'));

  // const sellerGstin = localStorage.getItem('sellerGstin');

  const [selectedItems, setSelectedItems] = useState([]);
  
  const [selectedItemsIrnStatus, setSelectedItemsIrnStatus] = useState("");
  const [selectedItemsIrnErrorStatus, setSelectedItemsIrnErrorStatus] = useState();

  const [selectedItemsEwayStatus, setSelectedItemsEwayStatus] = useState("");
  const [selectedItemsDocTyp, setSelectedItemsDocTyp] = useState("");
  const [selectedItemsSupTyp, setSelectedItemsSupTyp] = useState("");
 
 

  const navigate = useNavigate();
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const currentDate = getCurrentDate();
  const [todayDate, setTodayDate] = useState(currentDate.split('-').reverse().join('/') )
  const [loading, setLoading] = useState(false);
  
  const [startDate, setStartDate] = useState(currentDate.split('-').reverse().join('/'));
  const [endDate, setEndDate] = useState(currentDate.split('-').reverse().join('/'));

  useEffect(() => {
    console.log("userid " + userId);
    
    setProgress(30);
    fetchInvoicesDataFromApi();
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    }); // Adjust the time as needed
  },[]);

  const fetchInvoicesDataFromApi = async () => {

    if (sellerGstin != null && sellerGstin != undefined) {

      try {
        

          const data = {
            authorId: userId,  // Example parameter
            sellerGSTIN: sellerGstin,
            fromDate: startDate,
            toDate: endDate

              // Add other parameters as needed
          };

      const response = await axiosInstance.post(`/get-invoices`, data); // Use Axios to make the GET request
      
      setData(response.data);
      setFilteredData(response.data);
      
      
      // setSellerGStin(response.data.GSTIN);
      console.log('Invoice data is :', response.data);
      setProgress(100);
      setLoading(false);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching invoice data:', error);
      handleApiError(error, navigate)
      setLoading(false);
      setAlertMessage("Server could not responding")
      setAlertVariant("danger")
      setShowAlert(true)
    }
      
    }else{

      setNoDataText('Select seller')
    }

    
    
  };
  // console.log(data[0]);
  // console.log(data[0].GenerateStatus);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Create a function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the start and end index for the items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the items to display on the current page
  const itemsToDisplay = filteredData.slice(startIndex, endIndex);

  // setFilteredData

  // Determine the number of page numbers to display
  const maxPageNumbers = 3;

  const toggleCheckbox = (item, irnStatus, irnErrorStatus, irnNumber, ewayStatus, ewayNumber, DocTyp, SubTyp, SupplierGstin) => {
    setSelectedItemsIrnStatus(irnStatus);
    setSelectedItemsIrnErrorStatus(irnErrorStatus);
    setSelectedItemsIrnNumber(irnNumber);
    setSelectedItemsEwayStatus(ewayStatus);
    setSelectedItemsDocTyp(DocTyp);
    setSelectedItemsSupTyp(SubTyp);
    setSelectedItemsEwayNumber(ewayNumber);
    setSelectedItemsSupplierGstin(SupplierGstin);
    console.log(irnErrorStatus);
    
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }

  };

  const isItemChecked = (itemId, irnStatus, irnError) => selectedItems.includes(itemId);

  const toggleSelectAll = () => {
    if (selectedItems.length === itemsToDisplay.length) {
      setSelectedItems([]);
      
    } else {
      setSelectedItems(itemsToDisplay.map((item) => item._id));
    }
  };

  const deleteItems = async () => {
    

    if (selectedItems.length > 0) {
      setProgress(30);
      // Redirect to the edit page with the selected item's ID
      try {

        // Extract selected item IDs
        
        const itemIds = selectedItems.map((item) => item);
        console.log(itemIds);
  
        // Send a DELETE request to your API with the selected item IDs
        
       await axiosInstance.delete(`/delete-invoice`, {
          data: { ids: itemIds }, // Pass the selected item IDs as data
        });
        // const eror = response.error;
        // After successful deletion, refresh the data
        fetchInvoicesDataFromApi();
        console.log(" Delete Invoice API called successfully");
      setAlertMessage('Invoice deleted successfully')
      setAlertVariant('success');
      setShowAlert(true);
        // Clear the selected items
        setSelectedItems([]);
        setProgress(100);
      } catch (error) {
        console.error('Delete Invoice API called FAILED:', error);
        handleApiError(error, navigate);
        setAlertMessage(error.response.data.error)
        setAlertVariant('danger');
        setProgress(100);
        setShowAlert(true);
        // setProgress(100);
      }
    } else {
      // You can provide a user-friendly error message if no item or more than one item is selected.
      console.log('Please select item to delete.');
      setAlertMessage('Please select item to delete')
      setAlertVariant('warning');
      setShowAlert(true);
      // setProgress(100);
    }

    
  };

  const generateIrn = async () => {
    

    if (selectedItems.length > 0) {

      if (selectedItemsIrnStatus != "Active"){
      setProgress(30);
      setIsActiveOverlay(true);
      // Redirect to the edit page with the selected item's ID
      try {
        // Extract selected item ID
        const itemIds = selectedItems.map((item) => item);
        console.log(itemIds);
        const formdata= { invoiceIds:itemIds,
          sellerId: sellerId}
  
        // Send a DELETE request to your API with the selected item IDs
        
        const response = await axiosInstance.post(`/generate-irn`, formdata);
        // const eror = response.error;
        // After successful deletion, refresh the data
        fetchInvoicesDataFromApi();

        

        if (response.data.irnResponses[0].status === 0) {
          setAlertMessage('IRN failed to generated')
          setAlertVariant('danger');
          setShowAlert(true);
          
        }else if(response.data.irnResponses[0].status === 1 ){
          setAlertMessage('IRN Successfully generated')
            setAlertVariant('success');
            setShowAlert(true);
          if (response.data.ewayResponses[0].status === 0) {

          setAlertMessage('IRN Successfully generated! '+ "Eway not generated: "+ response.data.ewayResponses[0].responseData[0].ErrorMessage)
          setAlertVariant('success');
          setShowAlert(true);
          // setIsActiveOverlay(false);
          }
          
        }
        
        // console.log("IRN has been generated successfully");
        
        // Clear the selected items
        setSelectedItems([]);
        setProgress(100);
        // setIsActiveOverlay(false);
      } catch (error) {
        console.error('Error generating items:', error);
        handleApiError(error, navigate);
        setAlertMessage(error.response.data.error)
        setAlertVariant('danger');
        setProgress(100);
        setShowAlert(true);
        
        // setProgress(100);
      }finally{
        setIsActiveOverlay(false);

      }

    }else{
      setAlertMessage('IRN already generated')
      setAlertVariant('warning');
      setShowAlert(true);
  
  }
    } else {
      // You can provide a user-friendly error message if no item or more than one item is selected.
      // console.log('Please select item to delete.');
      setAlertMessage('Please select invoice to generate')
      setAlertVariant('warning');
      setShowAlert(true);
      // setProgress(100);
    }

  
  };
  

  const downloadExcelPrint = async (FormatTyp) => {
    if (selectedItems.length === 1) {
      setProgress(30);
      setIsActiveOverlay(true);
      try {
        // Extract selected item ID
        const itemIds = selectedItems.map((item) => item);
        console.log(itemIds);
  
        const formdata = { 
          invoiceId: itemIds[0],
          formatTyp: FormatTyp
        };
  
        // Create an Axios instance
        
  
        // Send a POST request to your API with the selected item IDs
        const response = await axiosInstance.post(
          '/download-invoicePrint', 
          formdata, 
          {
            responseType: 'blob', // Specify response type
          }
        );

        // const blob = new Blob([response.data], { type: FormatTyp === 'PDF' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } );
        // const url1 = window.URL.createObjectURL(blob);
        let blob;
        if (FormatTyp === 'PDFVIEW') {
          blob = new Blob([response.data], { type: 'application/pdf' });
          const url1 = window.URL.createObjectURL(blob);
          window.open(url1, '_blank'); // Opens the PDF in a new tab
        }else{
          // Create a URL for the file and trigger download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const fileName = `${selectedItemsSupplierGstin}_${selectedItemsDocTyp}.${FormatTyp === 'PDF' ? 'pdf' : 'xlsx'}`;
        link.href = url;
        link.setAttribute('download', fileName); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        }

  
        
  
        // After successful download, refresh the data
        fetchInvoicesDataFromApi();
        
        // Clear the selected items and update progress
        setSelectedItems([]);
        setProgress(100);
        setIsActiveOverlay(false)
      } catch (error) {
        console.error('Error downloading invoice:', error);
        handleApiError(error, navigate);
        setAlertMessage(error.response?.data?.message || 'Error downloading invoice');
        setAlertVariant('danger');
        setProgress(100);
        setShowAlert(true);
        setIsActiveOverlay(false);
      }
    } else {
      setAlertMessage('Please select one invoice to download');
      setAlertVariant('warning');
      setShowAlert(true);
    }
  };
  

  const refreshIrn = async () => {

    if (selectedItems.length === 1) {
      setProgress(30);
      setIsActiveOverlay(true);
      // Redirect to the edit page with the selected item's ID
      try {
        // Extract selected item ID
        const itemIds = selectedItems.map((item) => item);
        console.log(itemIds);

        const formdata= { id:itemIds[0],
          sellerId: sellerId}
  
        // Send a DELETE request to your API with the selected item IDs
        
        const response = await axiosInstance.post(`/refresh-status`, formdata);
        // const eror = response.error;
        // After successful deletion, refresh the data
        fetchInvoicesDataFromApi();

        if (response.data.refreshResponse === 0) {
          setAlertMessage('IRN status update failed')
          setAlertVariant('danger');
          setShowAlert(true);
        }else{
          setAlertMessage('IRN status updated successfully')
          setAlertVariant('success');
          setShowAlert(true);
        }
        // console.log("IRN has been generated successfully");
        // Clear the selected items
        setSelectedItems([]);
        setProgress(100);
        setIsActiveOverlay(false);
      } catch (error) {
        console.error('Error refreshing invoice:', error);
        handleApiError(error, navigate);
        setAlertMessage(error.response.data.message)
        setAlertVariant('danger');
        setProgress(100);
        setShowAlert(true);
        setIsActiveOverlay(false);
        // setProgress(100);
      }

     } else {
      // You can provide a user-friendly error message if no item or more than one item is selected.
      // console.log('Please select item to delete.');
      setAlertMessage('Please select one invoice to update status')
      setAlertVariant('warning');
      setShowAlert(true);
      // setProgress(100);
    }

  
  };

  const cancelIrn = async () => {
      setProgress(30);
      setIsActiveOverlay(true);
      setIsCancelIrnModalOpen(false);
      // Redirect to the edit page with the selected item's ID
      try {
        // Extract selected item ID
        const itemIds = selectedItems.map((item) => item);
        console.log(itemIds);
        const formdata= { 
          id:itemIds[0],
          cancelJson: cancelirnData,
          sellerId: sellerId
        }
  
        // Send a DELETE request to your API with the selected item IDs
        
        const response = await axiosInstance.post(`/cancel-invoice`, formdata);
        // const eror = response.error;
        // After successful deletion, refresh the data
        fetchInvoicesDataFromApi();

        if (response.data.status === 0) {
          setAlertMessage('IRN failed to cancel: ' + response.data.cancelResponse[0].ErrorMessage)
          setAlertVariant('danger');
          setShowAlert(true);
        }else{
          setAlertMessage('IRN successfully cancelled')
          setAlertVariant('success');
          setShowAlert(true);
        }
        // console.log("IRN has been generated successfully");
        
        // Clear the selected items
        setSelectedItems([]);
        setProgress(100);
        setIsActiveOverlay(false);
      } catch (error) {
        console.error('Error cancel IRN:', error);
        handleApiError(error, navigate);
        setAlertMessage(error.response.data.error)
        setAlertVariant('danger');
        setProgress(100);
        setIsActiveOverlay(false);
        setShowAlert(true);
        // setProgress(100);
      }
  
  };

  const cancelEway = async () => {
    setProgress(30);
    setIsActiveOverlay(true);
    setIsCancelEwayModalOpen(false);
    // Redirect to the edit page with the selected item's ID
    try {
      // Extract selected item ID
      const itemIds = selectedItems.map((item) => item);
      console.log(itemIds);
      const formdata= { id:itemIds[0],
        cancelJson: cancelEwayData,
        sellerId: sellerId
      }

      // Send a DELETE request to your API with the selected item IDs
      
      const response = await axiosInstance.post(`/cancel-eway`, formdata);
      // const eror = response.error;
      // After successful deletion, refresh the data
      
      fetchInvoicesDataFromApi();

      if (response.data.status === 0) {
        setAlertMessage('Eway failed to cancel: ' + response.data.cancelResponse[0].ErrorMessage)
        setAlertVariant('danger');
        setShowAlert(true);
      }else{
        setAlertMessage('Eway successfully cancelled')
        setAlertVariant('success');
        setShowAlert(true);
      }
      // console.log("IRN has been generated successfully");
      
      // Clear the selected items
      setSelectedItems([]);
      setProgress(100);
      setIsActiveOverlay(false);
    } catch (error) {
      console.error('Error cancel Eway:', error);
      handleApiError(error, navigate);
      setAlertMessage(error.response.data.error)
      setAlertVariant('danger');
      setProgress(100);
      setIsActiveOverlay(false);
      setShowAlert(true);
      // setProgress(100);
    }

};

const generateEway = async () => {
  setProgress(30);
  setIsActiveOverlay(true);
  setIsGenerateEwayModalOpen(false);
  // Redirect to the edit page with the selected item's ID
  try {
    // Extract selected item ID
    const itemIds = selectedItems.map((item) => item);
    console.log(itemIds);
    const formdata= { id:itemIds[0],
      ewayJson: generateEwayData,
      sellerId: sellerId
    }

    // Send a DELETE request to your API with the selected item IDs
    
    const response = await axiosInstance.post(`/generate-eway`, formdata);
    // const eror = response.error;
    // After successful deletion, refresh the data
   
    fetchInvoicesDataFromApi();

    if (response.data.status === 0) {
      setAlertMessage('Eway failed to generate: ' + response.data.ewayResponse[0].ErrorMessage)
      setAlertVariant('danger');
      setShowAlert(true);
    }else{
      setAlertMessage('Eway successfully generated: ' + response.data.ewayResponse.EwbNo)
      setAlertVariant('success');
      setShowAlert(true);
    }
    // console.log("IRN has been generated successfully");
    
    // Clear the selected items
    setSelectedItems([]);
    setProgress(100);
    setIsActiveOverlay(false);
  } catch (error) {
    console.error('Error generated Eway:', error);
    handleApiError(error, navigate);
    setAlertMessage(error.response.data.error)
    setAlertVariant('danger');
    setProgress(100);
    setShowAlert(true);
    setIsActiveOverlay(false);
    // setProgress(100);
  }

};

  const editItems = () => {
    // Check if only one item is selected for editing
    if (selectedItems.length === 1) {
      // Redirect to the edit page with the selected item's ID
      // navigate(`/App/TaxInvoice/${selectedItems[0]}`);
     

        navigate(`/App/TaxInvoice?CustomerId=${selectedItems[0]}&DocTyp=${selectedItemsDocTyp}&SupTyp=${selectedItemsSupTyp}`);
  
      
    }else{
      console.log('Please select one item to edit.');
      setAlertMessage('Please select one item to edit');
      setAlertVariant('warning');
      setShowAlert(true);

    }
  };

  const handleStartDateChange = (e) => {
    const { name, value } = e.target;
        const [year, month, day] = value.split('-');
        const formattedDate = `${day}/${month}/${year}`;
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (e) => {
    const { name, value } = e.target;
        const [year, month, day] = value.split('-');
        const formattedDate = `${day}/${month}/${year}`;
        setEndDate(formattedDate);
  };

  // Function to handle the form submission and filter the data
  const handleSubmit = (e) => {
    e.preventDefault();

    if (sellerGstin != null && sellerGstin != undefined) {
      setProgress(30);
      // setLoading(true);
      fetchInvoicesDataFromApi();
    // Filter the data based on the selected dates
    // You can perform your desired filtering logic here
    console.log('Filtered data:', startDate, endDate);
      
    }else{
      setAlertMessage("Please select seller")
      setAlertVariant("warning")
      setShowAlert(true)

    }

    
  };

  const openViewErrorModal = () => {

    if (selectedItems.length === 1) {
      // Redirect to the edit page with the selected item's ID
      // navigate(`/App/TaxInvoice/${selectedItems[0]}`);
      if (selectedItemsIrnStatus === "Error") {
        setIsModalOpen(true);
  
      } else {
        // console.log('Please select one item to edit.');
        setAlertMessage('No error found');
        setAlertVariant('warning');
        setShowAlert(true);
      }
    }else{
      console.log('Please select one invoice to view error');
      setAlertMessage('Please select one invoice to view error');
      setAlertVariant('warning');
      setShowAlert(true);

    }

    // setIsModalOpen(true);

      };

  const openCancelIrnModal = () => {

        if (selectedItems.length === 1) {
          // Redirect to the edit page with the selected item's ID
          // navigate(`/App/TaxInvoice/${selectedItems[0]}`);
          if (selectedItemsIrnStatus === "Cancel") {
            setAlertMessage('IRN already cancelled');
            setAlertVariant('warning');
            setShowAlert(true);
          } else if (selectedItemsIrnStatus === "Active") {
            setIsCancelIrnModalOpen(true); // Only open modal for active IRNs
          } else {
            setAlertMessage('IRN not generated');
            setAlertVariant('warning');
            setShowAlert(true);
          }
        }else{
          console.log('Please select one invoice to cancel ');
          setAlertMessage('Please select one invoice to cancel');
          setAlertVariant('warning');
          setShowAlert(true);
    
        }
    
        // setIsModalOpen(true);
    
          };

//This function is run when we click on close icon or button.
       const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
      };
      // onSubmit={handleGetCancelIrnModal}
      const handleCloseCancelIrnModal = () => {
        setIsCancelIrnModalOpen(false); // Close the modal
      };

      const handleGetCancelIrnModal = (event) => {
        event.preventDefault();

        cancelIrn();
        
        // console.log('get gst detail btn clicked ' + gstNumberGet);
        
        // fetchGstDetailApi(gstNumberGet)
         // Close the modal
      };
      
      const handleGetIrnCancelDetail = (e) => {

        const {value, name} = e.target;
        console.log(value);

        setCancelirnData(prevData => ({
          ...prevData,
          Irn: selectedItemsIrnNumber,
          [name]: value,   // Directly update the field
        }));

        // setGstNumberGet(value);
        
        // fetchGstDetailApi(value);

        // const file = e.target.files[0];
        // readExcelFile(file);
      };

      const openCancelEwayModal = () => {

        if (selectedItems.length === 1) {
          // Redirect to the edit page with the selected item's ID
          // navigate(`/App/TaxInvoice/${selectedItems[0]}`);
          if (selectedItemsEwayStatus === "Cancel") {
            setAlertMessage('Eway already cancelled');
            setAlertVariant('warning');
            setShowAlert(true);
          } else if (selectedItemsEwayNumber === "" || selectedItemsEwayNumber === null) {
            setAlertMessage('Eway not generated');
            setAlertVariant('warning');
            setShowAlert(true);
             // Only open modal for active IRNs
          } else {
            setIsCancelEwayModalOpen(true);
            
          }
        }else{
          console.log('Please select one invoice to cancel eway ');
          setAlertMessage('Please select one invoice to cancel eway');
          setAlertVariant('warning');
          setShowAlert(true);
    
        }
    
        // setIsModalOpen(true);
    
          };

      const handleCloseCancelEwayModal = () => {
        setIsCancelEwayModalOpen(false); // Close the modal
      };

      const handleGetCancelEwayModal = (event) => {
        event.preventDefault();

        cancelEway();
        
        // console.log('get gst detail btn clicked ' + gstNumberGet);
        
        // fetchGstDetailApi(gstNumberGet)
         // Close the modal
      };
      
      const handleGetEwayCancelDetail = (e) => {

        const {value, name} = e.target;
        console.log(value);

        setCancelEwayData(prevData => ({
          ...prevData,
          ewbNo: selectedItemsEwayNumber,
          [name]: value,   // Directly update the field
        }));

      };

      // GENERATE EWAY MODAL................
 
      const openGenerateEwayModal = () => {

        if (selectedItems.length === 1) {
          // Redirect to the edit page with the selected item's ID
          // navigate(`/App/TaxInvoice/${selectedItems[0]}`);
          if (selectedItemsEwayStatus === "Cancel") {
            setAlertMessage('Eway already cancelled');
            setAlertVariant('warning');
            setShowAlert(true);
          }  else if (selectedItemsIrnStatus !== "Active") {
            setAlertMessage('IRN not generated');
            setAlertVariant('warning');
            setShowAlert(true);
             // Only open modal for active IRNs
          }else if (selectedItemsEwayNumber === "" || selectedItemsEwayNumber === null) {
            
            setIsGenerateEwayModalOpen(true);
             // Only open modal for active IRNs
          }
           else {
            setAlertMessage('Eway already generated');
            setAlertVariant('warning');
            setShowAlert(true);
            
          }
        }else{
          console.log('Please select one invoice to generate eway ');
          setAlertMessage('Please select one invoice to generate eway');
          setAlertVariant('warning');
          setShowAlert(true);
    
        }
    
        // setIsModalOpen(true);
    
          };

      const handleCloseGenerateEwayModal = () => {
        setIsGenerateEwayModalOpen(false); // Close the modal
      };

      const handleGetGenerateEwayModal = (event) => {
        event.preventDefault();

        generateEway();
        
        // console.log('get gst detail btn clicked ' + gstNumberGet);
        
        // fetchGstDetailApi(gstNumberGet)
         // Close the modal
      };
      
      const handleGetGenerateDetail = (e) => {

        const {value, name} = e.target;
        console.log(value);

        setGenerateEwayData(prevData => ({
          ...prevData,
          Irn: selectedItemsIrnNumber,
          [name]: value,   // Directly update the field
        }));

      };
      // console.log('Data before filtering:', data);


      const filterData = (term) => {
        const filteredData = data.filter((item) => {

          return item.invoiceJson.some((invoice) => {
            const docNumber = invoice.DocDtls.No || '';
            const docDate = invoice.DocDtls.Dt || '';
            const docType = invoice.DocDtls.Typ || '';
            const buyerGstin = invoice.BuyerDtls.Gstin || '';
            const buyerTradeName = invoice.BuyerDtls.TrdNm || '';
            const invoiceValue = invoice.ValDtls.TotInvVal || '';
            const status = item.GenerateStatus || ''; // Adjust this key if needed
            const ewayNo = item.ewayNumber || ''; // Adjust this key if needed
            const irnNo = item.irnNumber || '';
      
            // Check if any of the fields contain the search term
            return (
              docNumber.toLowerCase().includes(term.toLowerCase()) ||
              docDate.toLowerCase().includes(term.toLowerCase()) ||
              docType.toLowerCase().includes(term.toLowerCase()) ||
              buyerGstin.toLowerCase().includes(term.toLowerCase()) ||
              buyerTradeName.toLowerCase().includes(term.toLowerCase()) ||
              invoiceValue.toString().includes(term) || // Convert to string for comparison
              status.toLowerCase().includes(term.toLowerCase()) ||
              ewayNo.toLowerCase().includes(term.toLowerCase()) ||
              irnNo.toLowerCase().includes(term.toLowerCase()) ||
              item.irnStatus.toLowerCase().includes(term.toLowerCase())
              // item.ewayNumber.toString().includes(term)

            );
          });
        });
      
        // setData(filteredData);
        setFilteredData(filteredData);
      };
      
    

      const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        filterData(term);
      };

      // Function to highlight search term
      const highlightSearchTerm = (text, term) => {
        if (!term || typeof text !== 'string') return text; // Return original text if there's no term or text is not a string
        const regex = new RegExp(`(${term})`, 'gi'); // Create a regex to match the term
        const parts = text.split(regex); // Split the text by the search term
        return parts.map((part, index) =>
            part.toLowerCase() === term.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
            ) : part
        );
      };

     

    return(
      <>
      <LoadingOverlay
  active={isActiveOverlay}
  spinner
  text='Please wait..'
  >
    {loading ? (
      <TopLoader />
    ) : (

        <>
        {isCancelIrnModalOpen && (
        <>
      <div className="modal-backdrop fade show"/>
          <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <form className='formDiv' onSubmit={handleGetCancelIrnModal} name="taxInvoiceForm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Cancel E-invoice</h1>
                  <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseCancelIrnModal}></button>
                </div>
                <div className="modal-body">
                <div className='CancelIrnDIV'>
                    <p id='irnCancelField'>IRN :</p>
                    <input id='impGSTINField' name='Irn' value={selectedItemsIrnNumber} onChange={handleGetIrnCancelDetail}  type="text" className="form-control" disabled
                       required/></div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelreasonField'>Cancel Reason :</p>
                    {/* <input id='impGSTINField' name='CnlRsn' onChange={handleGetIrnCancelDetail}  type="text" className="form-control"   required/> */}
                    <select id="impGSTINField"  name='CnlRsn' onChange={handleGetIrnCancelDetail} className="form-select" required>
                    <option className='option' value='' hidden>Select Reason</option>
                    <option className='option' value="1" >Duplicate</option>
                    <option className='option' value="2">Data Entry Mistake</option>
                    <option className='option' value="3">Order Cancel</option>
                    <option className='option' value="4">Others</option>
                    </select>
                    
                    </div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelremarkField'>Cancel Remark :</p>
                    <input id='impGSTINField' name='CnlRem' onChange={handleGetIrnCancelDetail}   type="text" className="form-control"   required/></div>
                  
                </div>  
                <div className="modal-footer">
                  <button type="button" class="btn  btn-dark" id="cancelinvbtn" onClick={handleCloseCancelIrnModal}>Close</button>
                  <button type="submit" className="btn  btn-dark" id="saveinvbtn"  >Cancel IRN</button>
                </div>
              </div></form>
            </div>
          </div></>
        )}

{isCancelEwayModalOpen && (
        <>
      <div className="modal-backdrop fade show"/>
          <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <form className='formDiv' onSubmit={handleGetCancelEwayModal} name="taxInvoiceForm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Cancel E-way</h1>
                  <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseCancelEwayModal}></button>
                </div>
                <div className="modal-body">
                <div className='CancelIrnDIV'>
                    <p id='irnCancelField'>Eway Bill No :</p>
                    <input id='impGSTINField' name='Irn' value={selectedItemsEwayNumber} onChange={handleGetEwayCancelDetail}  type="text" className="form-control" disabled   required/></div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelreasonField'>Cancel Reason :</p>
                    {/* <input id='impGSTINField' name='CnlRsn' onChange={handleGetIrnCancelDetail}  type="text" className="form-control"   required/> */}
                    <select id="impGSTINField"  name='CnlRsn' onChange={handleGetEwayCancelDetail} className="form-select" required>
                    <option className='option' value="" hidden>Select Reason</option>
                    <option className='option' value="1" >Duplicate</option>
                    <option className='option' value="2">Data Entry Mistake</option>
                    <option className='option' value="3">Order Cancel</option>
                    <option className='option' value="4">Others</option>
                    </select>
                    
                    </div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelremarkField'>Cancel Remark :</p>
                    <input id='impGSTINField' name='CnlRem' onChange={handleGetEwayCancelDetail}   type="text" className="form-control"   required/></div>
                  
                </div>  
                <div className="modal-footer">
                  <button type="button" class="btn  btn-dark" id="cancelinvbtn" onClick={handleCloseCancelEwayModal}>Close</button>
                  <button type="submit" className="btn  btn-dark" id="saveinvbtn"  >Cancel Eway</button>
                </div>
              </div></form>
            </div>
          </div></>
        )}

{isGenerateEwayModalOpen && (
        <>
      <div className="modal-backdrop fade show"/>
          <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <form className='formDiv' onSubmit={handleGetGenerateEwayModal} name="taxInvoiceForm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Generate E-way</h1>
                  <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseGenerateEwayModal}></button>
                </div>
                <div className="modal-body">
                <div className='CancelIrnDIV'>
                    <p id='irnCancelField'>Transporter Id :</p>
                    <input id='impGSTINField' name='TransId'  onChange={handleGetGenerateDetail}  type="text" className="form-control"   /></div>
                    
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelField'>Transporter Name :</p>
                    <input id='impGSTINField' name='TransName'  onChange={handleGetGenerateDetail}  type="text" className="form-control"   />
                    </div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelField'>Trans Mode :</p>
                    <select id="impGSTINField"  name='TransMode' onChange={handleGetGenerateDetail} className="form-select" >
                    <option className='option' value="" hidden>Select Trans Mode</option>
                    <option className='option' value="1" >Road</option>
                    <option className='option' value="2">Rail</option>
                    <option className='option' value="3">Air</option>
                    <option className='option' value="4">Ship</option>
                    </select>
                    </div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelField'>Distance* :</p>
                    <input id='impGSTINField' name='Distance'  onChange={handleGetGenerateDetail}  type="number" className="form-control"   required/></div>
                    
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelremarkField'>Vehicle No :</p>
                    <input id='impGSTINField' name='VehNo' onChange={handleGetGenerateDetail}   type="text" className="form-control"   /></div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelremarkField'>Vehicle type :</p>
                    <select id="impGSTINField"  name='VehType' onChange={handleGetGenerateDetail} className="form-select" >
                    <option className='option' value="" hidden>Select Vehicle Type</option>
                    <option className='option' value="R" >Regular</option>
                    <option className='option' value="O">ODC (Over Dimension Cargo)</option>
                    </select></div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelremarkField'>TransDocNo :</p>
                    <input id='impGSTINField' name='VehNo' onChange={handleGetGenerateDetail}   type="text" className="form-control"   /></div>
                    <div className='CancelIrnDIV'>
                    <p id='irnCancelremarkField'>TransDocDt :</p>
                    <input id='impGSTINField' name='VehNo' onChange={handleGetGenerateDetail}   type="text" className="form-control"   /></div>
                </div>  
                <div className="modal-footer">
                  <button type="button" class="btn  btn-dark" id="cancelinvbtn" onClick={handleCloseGenerateEwayModal}>Close</button>
                  <button type="submit" className="btn  btn-dark" id="saveinvbtn"  >Generate Eway</button>
                </div>
              </div></form>
            </div>
          </div></>
        )}

        {isModalOpen && (
        <>
      <div className="modal-backdrop fade show"/>
          <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <form className='formDiv'  name="taxInvoiceForm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Errors</h1>
                  <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                <div className='impGstin'>
                <ol>
  {selectedItemsIrnErrorStatus.map((item, index) => (
    <li key={index}>{item.ErrorMessage}</li>
  ))}
</ol>
                  {/* <p id='impGstinText'>GSTIN / UIN :</p> */}
                    {/* <input id='impGSTINField' type="text" className="form-control"  onChange={handleGetGstinDetail}  required/> */}
                  </div>
                </div>  
                <div className="modal-footer">
                  <button type="button" class="btn  btn-dark" id="cancelinvbtn" onClick={handleCloseModal}>OK</button>
                  {/* <button type="submit" className="btn  btn-dark" id="saveinvbtn"  >Get Details</button> */}
                </div>
              </div></form>
            </div>
          </div></>
        )}
        
        <div id='alertDIV'>
        
          {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? alertMessage : alertMessage}
        onClose={() => setShowAlert(false)}
        />
      )}</div>
        
        <div id='MyinvoiceDiv'>
        
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />

        <GenerateFormTitle title="My Invoices" />
        {/* <GenerateForm></GenerateForm>    */}
        {/* <Actionheader></Actionheader> */}
        <div id="actioncon">
        <button type="button" onClick={generateIrn}  className="btn  btn-primary" id='genbtn'>Generate</button>
        <button type="button" onClick={editItems} className="btn btn-primary" id='editbtn'>Edit</button>
        <button type="button" onClick={deleteItems} className="btn btn-primary" id='dltbtn'>Delete</button>
        
        
        <div className="dropdown">
  <button className="btn btn-secondary dropdown-toggle" id='Morebtn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
    More Action
  </button>
  <ul className="dropdown-menu">
    <li><button className="dropdown-item" onClick={openGenerateEwayModal}>Generate e-way</button></li>
    <li><button className="dropdown-item" onClick={openCancelIrnModal}>Cancel e-invoice</button></li>
    <li><button className="dropdown-item" onClick={openCancelEwayModal}>Cancel e-way</button></li>
    <li><button className="dropdown-item" onClick={refreshIrn}>Refresh status</button></li>
    <li><button className="dropdown-item" onClick={() => downloadExcelPrint('EXCEL')}>Download excel print</button></li>
    <li><button className="dropdown-item" onClick={() => downloadExcelPrint('PDF')}>Download pdf print</button></li>
    <li><button className="dropdown-item" onClick={openViewErrorModal}>Check Error</button></li>
  </ul>
</div>
<div className="dropdown">
<button type="button" onClick={() => downloadExcelPrint('PDFVIEW')} className="btn btn-primary" id='dltbtn'>Preview</button>

</div>
                      
        <div className='rightcon'>
        <Form onSubmit={handleSubmit}>
          <div className='rightcon2'>
      <Form.Group>
      <div className='datepickdiv'>
        <Form.Label className='strdate'>From</Form.Label>
        <Form.Control type="date" value={startDate.split('/').reverse().join('-')} name='FromDt' max={currentDate}  className='form-control2'  onChange={handleStartDateChange} /></div>
      </Form.Group>
       <Form.Group>
       <div className='datepickdiv'>
        <Form.Label className='strdate'>To</Form.Label>
        <Form.Control type="date" value={endDate.split('/').reverse().join('-')} name='StartDt' max={currentDate}  className='form-control2'    onChange={handleEndDateChange} /></div>
      </Form.Group>
      <Button variant="primary" type="submit"  id='applydatebtn'>
        Apply
      </Button></div>
    </Form>
        <div className='srch'>
          
        <InputGroup className="mb-3">
      <FormControl
        className="srchtext"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </InputGroup>
          
          
          </div>

</div>
            {/* <h1>heelow </h1> */}
        </div>

        <div className='tablediv'>
          {/* <hr className="my-4  mb-0 mt-2"></hr> */}

          <div className="table-responsive" id="MyinvoiceTableDiv">
  <table className="table table-hover table-sm" id="myInvoiceTable">
    <thead>
      <tr>
        <th style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
          <div className="form-check">
            <input
              className="form-check-input checkInput1"
              onChange={toggleSelectAll}
              checked={itemsToDisplay.length > 0 && selectedItems.length === itemsToDisplay.length}
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
          </div>
        </th>
        <th style={{ width: "160px", minWidth: "160px", maxWidth: "160px" }}>Doc Number</th>
        <th style={{ width: "120px", minWidth: "120px", maxWidth: "120px" }}>Doc Date</th>
        <th style={{ width: "120px", minWidth: "120px", maxWidth: "120px" }}>Doc Type</th>
        <th style={{ width: "150px", minWidth: "150px", maxWidth: "150px" }}>Buyer Gstin</th>
        <th style={{ width: "190px", minWidth: "190px", maxWidth: "190px" }}>Buyer Trade Name</th>
        <th style={{ width: "150px", minWidth: "150px", maxWidth: "150px" }}>Invoice Value</th>
        <th style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }}>Status</th>
        <th style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }}>Eway No</th>
        <th style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }}  > Irn No</th>
      </tr>
    </thead>
    <tbody>
      {itemsToDisplay.length === 0 ? (
        <div id='noDataDiv'><p id="noDataText">{noDataText}</p></div>
        
      ) : (
        itemsToDisplay.map((item, index) => {
          const isActive = item.irnStatus === 'Active';
          const hasError = item.irnError && item.irnError.length > 0;
          const isSelected = isItemChecked(item._id, item.irnStatus, item.irnError, item.irnNumber, item.ewayStatus, item.ewayNumber, item.invoiceJson[0].DocDtls.Typ, item.invoiceJson[0].TranDtls.SupTyp, item.invoiceJson[0].SellerDtls.Gstin);
          return (
            <tr
              key={index}
              onClick={() =>
                toggleCheckbox(item._id, item.irnStatus, item.irnError, item.irnNumber, item.ewayStatus, item.ewayNumber, item.invoiceJson[0].DocDtls.Typ, item.invoiceJson[0].TranDtls.SupTyp, item.invoiceJson[0].SellerDtls.Gstin )
              }
              className={`${isSelected ? 'selected-row' : ''} ${hasError ? 'error-row' : ''} ${isActive && !hasError ? 'active-row' : ''}`}
            >
              <td style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    checked={isSelected}
                    onChange={() =>
                      toggleCheckbox(item._id, item.irnStatus, item.irnError, item.irnNumber, item.ewayStatus, item.ewayNumber, item.invoiceJson.DocDtls.Typ, item.invoiceJson[0].TranDtls.SupTyp, item.invoiceJson[0].SellerDtls.Gstin)
                    }
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                </div>
              </td>
              <td style={{ width: "160px", minWidth: "160px", maxWidth: "160px" }}>
                {highlightSearchTerm(item.invoiceJson?.[0]?.DocDtls?.No || 'N/A', searchTerm)}
              </td>
              <td style={{ width: "120px", minWidth: "120px", maxWidth: "120px" }}>
                {highlightSearchTerm(item.invoiceJson?.[0]?.DocDtls?.Dt || 'N/A', searchTerm)}
              </td>
              <td style={{ width: "120px", minWidth: "120px", maxWidth: "120px" }}>
                {highlightSearchTerm(item.invoiceJson?.[0]?.DocDtls?.Typ || 'N/A', searchTerm)}
              </td>
              <td style={{ width: "150px", minWidth: "150px", maxWidth: "150px" }}>
                {highlightSearchTerm(item.invoiceJson?.[0]?.BuyerDtls?.Gstin || 'N/A', searchTerm)}
              </td>
              <td style={{ width: "190px", minWidth: "190px", maxWidth: "190px" }}>
                {highlightSearchTerm(item.invoiceJson?.[0]?.BuyerDtls?.TrdNm || 'N/A', searchTerm)}
              </td>
              <td style={{ width: "150px", minWidth: "150px", maxWidth: "150px" }}>
                {highlightSearchTerm(item.invoiceJson?.[0]?.ValDtls?.TotInvVal || 'N/A', searchTerm)}
              </td>
              <td style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }}>
                {highlightSearchTerm(item.irnStatus || 'N/A', searchTerm)}
              </td>
              <td>{highlightSearchTerm(item.ewayNumber || 'N/A', searchTerm)}</td>
              <td>{highlightSearchTerm(item.irnNumber || 'N/A', searchTerm)}</td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>
</div>

    
    
    </div>

    <div className='bottomline2'></div>
    <div className='MyinvoicePaginationDiv'>

    <p id='numberOfItemstext'>Page {currentPage} of {totalPages} ({data.length} items)</p>


  <ul className="pagination">
    {currentPage > 1 && (
      <li className="page-item">
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &laquo;
        </button>
      </li>
    )}

    {Array.from({ length: totalPages > maxPageNumbers ? maxPageNumbers : totalPages }, (_, i) => (
      <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
        <button className="page-link" onClick={() => handlePageChange(i + 1)}>
          {i + 1}
        </button>
      </li>
    ))}

    {currentPage < totalPages && (
      <li className="page-item">
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          &raquo;
        </button>
      </li>
    )}
  </ul>
</div>


        </div>
        </>
        )}
        </LoadingOverlay>
        </>
    )
    
}

export default FollowUp;