import axios from 'axios';// function to refresh token
import { refreshAccessToken } from './Authentication/AuthService';
import { logoutUser } from './Authentication/AuthService';
import { useNavigate } from 'react-router-dom';
import Login from './LoginSignup/Login';

// Create Axios instance
const axiosInstance = axios.create({
  // http://localhost:3000/api
  // https://rapidbill.in/api
  baseURL:'https://rapidbill.in/api', // or your API URL
  withCredentials: true, // to send cookies with requests
});



// Request interceptor to refresh token if near expiration
// axiosInstance.interceptors.request.use(
//   async (config) => {
//     const expirationTime = localStorage.getItem("accessTokenExpiration");

//     if (Date.now() >= expirationTime - 1 * 60 * 1000) { // 1 minute before expiry
//       const success = await refreshAccessToken();
//       if (!success) {
//         throw new Error("Token refresh failed");
//       }
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// Response interceptor to handle 401 errors

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    
    if (error.response && (error.response.status === 401 || error.response.status === 500)) { 
      console.log('entered in error conditon ');
      if(error.response.data.message === "jwt expired"){
        console.log('entered in jwt expired conditon ');
        const success = await refreshAccessToken();
        console.log('token is refreshing.... ');
        console.log('refreshsuccess ' + success);
        if (success) {
          // Retry the original request
          console.log('token is refresh successfull.... ');
          return axiosInstance(error.config);
        }else {
          console.log('token is refresh unsuccessfull.... ');
          console.log('logout condition running now login page should appear ');
          logoutUser(); // if token refresh fails, log the user out
          return Promise.reject(error);
        }

      }
       
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
