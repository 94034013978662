import {React, useState, useEffect} from 'react'
import './Masterforms.css'
import { SlPeople } from 'react-icons/sl';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import { CiDeliveryTruck } from 'react-icons/ci';
import { useParams } from 'react-router-dom';
import TopLoader from '../TopLoader/TopLoader';
import axiosInstance from '../../api';


function EditTransporter() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);

  const [isChecked, setIsChecked] = useState(false);

  const value = isChecked ? 'Y' : 'N';
  const { transporterId } = useParams();
  const [loading, setLoading] = useState(true);

  const [TransportData, setTransportData] = useState({
    TransporterId: null,
    TransporterName: null,
    TransportationMode: null,
    VehicleNumber: null,
    VehicleType: null,
    author: userId 
  });


  const {TransporterId, TransporterName, TransportationMode, VehicleNumber, VehicleType, author} = TransportData

  useEffect(() => {
    fetchDataFromApi();
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });
  }, []);

  const fetchDataFromApi = async () => {
    try {

      const response = await axiosInstance.get(`/transpoterDtl/${transporterId}`); // Use Axios to make the GET request
      
      const TransportData = response.data || {}; // Ensure OtherChargeData is not null or undefined
        setTransportData(TransportData);
      // setSellerGStin(response.data.GSTIN);
      console.log('Transporter data is :', response.data);
    //   console.log('OtherCharge data from OtherChargeData var :', TransportData[0]);
      setLoading(false);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
      handleApiError(error, navigate)
      setLoading(false);
      setAlertMessage("Server could not responding")
      setAlertVariant("danger")
      setShowAlert(true)
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransportData({ ...TransportData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setTransportData({
      ...TransportData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(TransportData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.put(`/transpoter/${transporterId}`,
        TransportData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Transporter Edited successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?TransporterEdit=Transport Details Updated ');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Transporter Edited failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    // Make API requests or perform other actions here
  };

const handleCancelButton = () => {
  navigate(-1);; // Close the modal
  };

    return(
        <>
    {loading ? (
      <TopLoader />
    ) : (
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Transport Detail Updated' : alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<CiDeliveryTruck></CiDeliveryTruck>} Edit Transporter</h4>
        <div className='AddDiv'><form onSubmit={handleSubmit} className="row g-3">
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Transport ID/GSTIN:</label>
    <input type="text" name='TransporterId' value={TransportData.TransporterId} onChange={handleChange} className="form-control Addmasterform" id="inputPassword4" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Transport Name:</label>
    <input type="text" name='TransporterName' value={TransportData.TransporterName} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" required/>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Transportation Mode:</label>
    <select id="inputState" name='TransportationMode' value={TransportData.TransportationMode} onChange={handleChange} className="form-select Addmasterform">
    <option value="">Select a Mode</option>
        <option value="1">Road</option>
        <option value="2">Rail</option>
        <option value="3">Air</option>
        <option value="4">Ship</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Vehicle Number:</label>
    <input type="text" name='VehicleNumber' value={TransportData.VehicleNumber} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" />
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Vehicle Type:</label>
    <select id="inputState" name='VehicleType' value={TransportData.VehicleType} onChange={handleChange} className="form-select Addmasterform">
    <option value="">Select a Type</option>
      <option value="R">Regular</option>
      <option value="O">ODC (Over Dimension Cargo)</option>
    </select>
  </div>
  <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
</form></div>

        </>

)}
</>

    )
    
}

export default EditTransporter;