import {React, useState} from 'react'
import './Masterforms.css'
import { SlPeople } from 'react-icons/sl';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import { CiBank } from 'react-icons/ci';
import axiosInstance from '../../api';





function AddBank() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);

  const [isChecked, setIsChecked] = useState(false);

  const [formData, setFormData] = useState({
    PayeeName: null,
    Payeeaccountno: null,
    NameofBank: null,
    Branchcode: null,
    Bankaddress: null,
    author: userId,  
  });


  const {PayeeName, Payeeaccountno, NameofBank, Branchcode, Bankaddress, author} = formData

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setFormData({
      ...formData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(formData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.post('/addBankDetails', formData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Bank Added successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?BankAdded=Bank added successfully!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Bank added failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    // Make API requests or perform other actions here
  };

  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };
    return(
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Bank Added!' : 'Failed to add Bank'}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<CiBank></CiBank>} Add New Bank</h4>
        <div className='AddDiv'>

        <form onSubmit={handleSubmit} className="row g-3"> 
      <div className="col-md-2">
        <label for="inputAddress" className="form-label">Payee Name:</label>
        <input type="text" name='PayeeName' value={PayeeName} onChange={handleChange}  className="form-control Addmasterform" id="inputAddress" />
      </div>
    
      <div className="col-md-2">
        <label for="inputAddress" className="form-label">Payee A/C Number:</label>
        <input type="text" name='Payeeaccountno' value={Payeeaccountno} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" required/>
      </div>
    
      <div className="col-md-2">
        <label for="inputState" className="form-label">Name of Bank:</label>
        <select id="inputState" name='NameofBank' value={NameofBank} onChange={handleChange} className="form-select Addmasterform" required>
        <option value="">Select a Bank</option>
  <option value="Bank of Baroda">Bank of Baroda</option>
  <option value="Bank of India">Bank of India</option>
  <option value="Bank of Maharashtra">Bank of Maharashtra</option>
  <option value="Canara Bank">Canara Bank</option>
  <option value="Central Bank of India">Central Bank of India</option>
  <option value="Indian Bank">Indian Bank</option>
  <option value="Indian Overseas Bank">Indian Overseas Bank</option>
  <option value="Punjab and Sind Bank">Punjab and Sind Bank</option>
  <option value="Punjab National Bank">Punjab National Bank</option>
  <option value="State Bank of India">State Bank of India</option>
  <option value="UCO Bank">UCO Bank</option>
  <option value="Union Bank of India">Union Bank of India</option>
  <option value="Axis Bank Ltd.">Axis Bank Ltd.</option>
  <option value="City Union Bank Ltd.">City Union Bank Ltd.</option>
  <option value="DCB Bank Ltd">DCB Bank Ltd</option>
  <option value="Federal Bank Ltd.">Federal Bank Ltd.</option>
  <option value="HDFC Bank Ltd.">HDFC Bank Ltd.</option>
  <option value="ICICI Bank Ltd.">ICICI Bank Ltd.</option>
  <option value="IDBI Bank Ltd.">IDBI Bank Ltd.</option>
  <option value="IDFC FIRST Bank Ltd">IDFC FIRST Bank Ltd</option>
  <option value="IndusInd Bank Ltd">IndusInd Bank Ltd</option>
  <option value="Jammu and Kashmir Bank Ltd.">Jammu and Kashmir Bank Ltd.</option>
  <option value="Karnataka Bank Ltd.">Karnataka Bank Ltd.</option>
  <option value="Karur Vysya Bank Ltd.">Karur Vysya Bank Ltd.</option>
  <option value="Kotak Mahindra Bank Ltd.">Kotak Mahindra Bank Ltd.</option>
  <option value="RBL Bank Ltd">RBL Bank Ltd</option>
  <option value="South Indian Bank Ltd.">South Indian Bank Ltd.</option>
  <option value="Yes Bank Ltd.">Yes Bank Ltd.</option>
  <option value="Dhanlaxmi Bank Ltd.">Dhanlaxmi Bank Ltd.</option>
  <option value="Bandhan Bank Ltd.">Bandhan Bank Ltd.</option>
  <option value="CSB Bank Ltd.">CSB Bank Ltd.</option>
        </select>
      </div>
    
      <div className="col-md-2">
        <label for="inputZip" className="form-label">Branch or IFSC Code.:</label>
        <input type="text" name='Branchcode' value={Branchcode} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
      </div>
      <div className="col-md-2">
        <label for="inputZip" className="form-label">Bank Address:</label>
        <input type="text" name='Bankaddress' value={Bankaddress} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
      </div>
      <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" class="btn  btn-dark" onClick={handleCancelButton} id="cancelinvbtn">Cancel</button></div>
    </form>
        </div>
        
            
        </>

    )
    
}

export default AddBank;