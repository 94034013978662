import React from 'react'

import './customerdtl.css'



function Consigneedtl() {

return(
<div className='customerdtlDiv'>
<form className="row g-3">
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">GSTIN:</label>
    <input type="password" className="form-control" id="inputPassword4"/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Trade Name:</label>
    <input type="text" className="form-control" id="inputAddress" p/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress2" className="form-label">Legal Name:</label>
    <input type="text" className="form-control" id="inputAddress2"/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 1:</label>
    <input type="text" className="form-control" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 2:</label>
    <input type="text" className="form-control" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">City:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Pincode:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">State:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Select State</option>
      <option className='option'>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Contact Person Name:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Phone:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Email:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
</form></div>
)
    
}

export default Consigneedtl;
