import React from 'react'
import './Landingpage.css';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
export default function container() {





return(
    <>
    <Navbar title = "Rapid Invoice" aboutText = "About us"/>
    <div className='contain'>
        <div className='col1'><h3 id='head1' >E-Invoice Smarter, Faster, and Better</h3><Link   to="./Login"><button type="button" className="btn btn-primary" id='getstartbtn'>Signup & Start Free Trial</button></Link> </div>
        <div className='col2'><img src="/imagefirst.png"></img></div>
        </div>
<div className='cards'>
        <div className="row row-cols-1 row-cols-md-2 g-4">
  <div className="col">
    <div className="card">
      <img src="/easy.png" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title">Easy to use</h5>
        <p className="card-text">Our intuitive interface ensures you can create, send, and track invoices effortlessly. No technical expertise required—just log in and get started.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card">
      <img src="/time.png" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title">Time-saving</h5>
        <p className="card-text"> Automate routine tasks like invoice creation, reminders, and payment tracking. Spend less time on admin work and more on growing your business.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card">
      <img src="/music-controller.png" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title">Customizable</h5>
        <p className="card-text">Personalize every aspect of your invoices with branding, templates, and settings tailored to your needs. Create invoices that reflect your unique business identity.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card">
      <img src="/money.png" className="card-img-top" alt="..."/>
      <div className="card-body" id='lastcard'>
        <h5 className="card-title">Affordable</h5>
        <p className="card-text">Packed with powerful features at a price that fits your budget, Rapid Bill offers the best value for your money without compromising quality.</p>
      </div>
    </div>
  </div>
</div>
</div>

<div className='accord'>

<h1 id='heading2'>What you can do?</h1>

<div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" id='accordionbut' type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      Can I create invoices quickly?
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Absolutely! With Rapid Bill, you can create professional invoices in seconds using pre-designed templates.</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" id='accordionbut' type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      Can I customize my invoices?
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Yes! Add your logo, adjust colors, and modify layouts to create invoices that perfectly reflect your brand.</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" id='accordionbut' type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      Can I access my account on the go?
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Certainly! Our web app is mobile-friendly, allowing you to manage invoices from anywhere, anytime.</div>
    </div>
  </div>
</div>
</div>

<div className='callToAction'>
    <div className='hl1'></div>
    <h1 className='heading3' id='heading2'>Streamline Your E-Invoicing with Ease!</h1>
    <h4 className='subhead1'>Rapid Bill is designed to make e-invoicing seamless, fast, and secure for businesses of all sizes. Whether you're a GST Professional, small business, or enterprise</h4>
    <button className='callToButton'>Get Started for Free</button>
</div>

<footer>
  <div className="container">
    <div className="row">
        <p className='foot'>&copy; 2024 RapidBill. All rights reserved.</p>
    </div>
  </div>
</footer>


</>
)
    
}