  import {React, useState, useEffect} from 'react'
  import { Form, Button } from 'react-bootstrap';
  import {MdOutlineExpandCircleDown } from "react-icons/md";
  import GenerateFormTitle from '../../Components/NotUsable/GenerateFormTitle';
  import StateDropdown from '../../Components/CustomDropdown/StateDropdown';
  import SelectSeller from '../../Components/SelectMasterData/SelectSeller';
  import SelectCustomer from '../../Components/SelectMasterData/SelectCustomer';
  import SelectItem from '../../Components/SelectMasterData/SelectItem';
  import SelectTransporter from '../../Components/SelectMasterData/SelectTransporter';
  import SelectBank from '../../Components/SelectMasterData/SelectBank';
  import axios from 'axios';
  import { useNavigate } from 'react-router-dom'; 
  import { handleApiError } from '../../Authentication/AuthService';
  import TopLoader from '../../Components/TopLoader/TopLoader';
  import Alert from '../../Components/CustomAlert/Alert1';
  import TopLoadingBar from 'react-top-loading-bar';
  import PortSelector from '../../Components/CustomDropdown/portCodeDropdown';
  import CountrySelector from '../../Components/CustomDropdown/countryCodeDropdown';
  import CurrencySelector from '../../Components/CustomDropdown/currencyCodeDropdown'
  import axiosInstance from '../../api'
  import Api from '../../api';
  import Selecter from '../../Components/SelectMasterData/selecter';


  // import TopLoader from '../../Components/TopLoader/TopLoader';
import { inView } from 'framer-motion/dom';
import { useLocation,useParams,  } from 'react-router-dom';
import { scryRenderedDOMComponentsWithTag } from 'react-dom/test-utils';
import { set } from 'mongoose';

  function Taxinvoice() {
      const [showAlert, setShowAlert] = useState(false);
      const [loading, setLoading] = useState(false);
      const location = useLocation();
      const queryParams = new URLSearchParams(location.search);
      const [alertMessage, setAlertMessage] = useState('');
      const [gstNumberGet, setGstNumberGet] = useState('');
      const [alertVariant, setAlertVariant] = useState('success');
      const navigate = useNavigate();
      const [progress, setProgress] = useState(0);
      const [charges, setCharges] = useState([]);
      const [isIntrastate, setIsIntrastate] = useState(true);
      const [iseditdataSet, setEditdataSet] = useState(false);
      const [isirnGenerated, setisirnGenerated] = useState(false);
    
      // const [iseditdataSet, setEditdataSet] = useState(false);
      // const [sellerState, setSellerState] = useState('');
      const [items, setItems] = useState([{ id: 0 }]);
      const [buyerState, setBuyerState] = useState("");
      const [sellerState, setSellerState] = useState("");
      const [sellerid, setsellerid] = useState(localStorage.getItem('seller_id'));
      const [userid, setuserid] = useState(localStorage.getItem('userid'));
      const [SellerData, setSellerData] = useState({
        GSTIN: '',
        IEC: '',
        MSME_REG_NO: '',
        TradeName: '',
        legalName: '',
        Address1: '',
        Address2: '',   
        City: '',
        Pincode: '',
        State: '',
        Phone: '',  
        Email: '',
        Seller_ID:'',
        NIC_username:'',
        NIC_Password:'',
        author:'',
        _id:''
      });
      //   gstin: '',
      //   lgnm: '',
      //   tradeNam: '',
      //   pradr : {addr:
      //     {
      //       st:'',
      //       loc:'',
      //       dst:'',
      //       pncd:'',
      //       stcd:''
      //     }}
      // });

      const [CustomerData, setCustomerData] = useState({
        Gstin: '',
        LegalName: '',
        Tradename: '',
        Address1: '',
        Address2: '',
        City: '',
        pinCode: '',
        State: '',
        phone: '',
        email: ''
      });
      const [ItemData, setItemData] = useState({
        ItemName: '',
        ItemDescription: '',
        Isservice: '',
        HSNCode: '',
        QTY: '',
        Price: '',
        Unit: '',
        TaxRate: ''
      });
      const [sellerId, setSellerId] = useState('');
      const [isChecked, setIsChecked] = useState({
        igstOnIntra: false,
        isRegRev: false,
        isService: true,
        can_edit: false,
        to_download: false
      })
      const [isServiceChecked, setIsServiceChecked] = useState(false)
      const [isModalOpen, setIsModalOpen] = useState(false);

      const [Chargesitems, setChargesItems] = useState([{ id: 1 }]);
      const DocTyp = queryParams.get('DocTyp');
      const SupTyp = queryParams.get('SupTyp');
      
      // var defaultDocumentType = DocTyp;
      const [defaultDocumentType, setDefaultDocumentType] = useState(DocTyp);

      const [defaultTransactionType, setDefaultTransactionType] = useState(SupTyp);

      // if (defaultDocumentType === 'EXP') {
      //   setDefaultDocumentType("INV");
      // }
      // const defaultTransactionType = 'B2B';
      const [totAmt, setTotAmt] = useState(0);
      const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      const currentDate = getCurrentDate();
      console.log("this is doctype"+ DocTyp);
      
      const customerID = queryParams.get('CustomerId');

      const fetchDataFromApi = async (selectedValue) => {

        if (sellerId !== null && sellerId !== undefined) {
          try {

          const response = await axiosInstance.get(`/SellerDtl/${selectedValue}`); // Use Axios to make the GET request
          
          setSellerData(response.data);
          const sellerDetails  = {
            Gstin: response.data.GSTIN,
            LglNm: response.data.legalName,
            TrdNm: response.data.TradeName,
            Addr1: response.data.Address1,
            Addr2: response.data.Address2,
            Loc: response.data.City,
            Pin: response.data.Pincode,
            Stcd: response.data.State,
            Ph: response.data.Phone,
            Em: response.data.Email
          }
          setInvoiceData(prevData => ({ ...prevData, SellerDtls: sellerDetails }));
          setSellerState(response.data.State);
          console.log(response.data);
          setLoading(false);
          setProgress(30);
          // Simulate an asynchronous operation (e.g., fetching data or loading resources)
          // Replace the setTimeout with your actual asynchronous operation
          setTimeout(() => {
          setProgress(100); // Set isLoading to false when the component is done loading
  });
           // Axios automatically parses JSON responses
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
          console.log('Please select Seller');
          setAlertMessage('Please select Seller')
          setAlertVariant('danger');
          setShowAlert(true);
          
        }
          
        }else{
          setAlertMessage('Please Select Seller')
          setAlertVariant('danger');
          setShowAlert(true);

        }
          

          
      };

      const fetchCustomerDataFromApi = async (selectedValue) => {
        try {

          const response = await axiosInstance.get(`/customer/${selectedValue}`);// Use Axios to make the GET request


          const Gstin =  response.data.Gstin;
          console.log(response.data);
          
          const StateCode = Gstin.substring(0, 2);
          
          handleDropdownChange('BuyerDtls.Stcd', StateCode);
          handleDropdownChange('BuyerDtls.Pos', StateCode);

          // setSellerData(response.data);

          setCustomerData(response.data);

          const customerDetails  = {
            Gstin: response.data.Gstin,
            LglNm: response.data.LegalName,
            TrdNm: response.data.Tradename,
            Addr1: response.data.Address1,
            Addr2: response.data.Address2,
            Loc: response.data.City,
            Pin: response.data.pinCode,
            Stcd: response.data.State,
            Pos: response.data.State,
            Ph: response.data.phone,
            Em: response.data.email
          }

          setInvoiceData(prevData => ({ ...prevData, BuyerDtls: customerDetails }));
          // setSellerState(response.data.State);
          // console.log(response.data);
           // Axios automatically parses JSON responses
        } catch (error) {
          console.error('Error fetching data:', error);
          // setLoading(false);
          console.log('Please select Seller');
          setAlertMessage('Customer data caused server error')
          setAlertVariant('warning');
          setShowAlert(true);
          
        }

        
       };
    
      const fetchItemDataFromApi = async (selectedValue, itemIndex) => {
      try {

        const response = await axiosInstance.get(`/StockDtl/${selectedValue}`);// Use Axios to make the GET request


        // const Gstin =  response.data.Gstin;
        console.log(response.data);
        
        // const StateCode = Gstin.substring(0, 2);
        
        // handleDropdownChange('BuyerDtls.Stcd', StateCode);
        // handleDropdownChange('BuyerDtls.Pos', StateCode);

        // setSellerData(response.data);

        setItemData(response.data);


        const itemDetails  = {
              PrdSlNo: null,
              OrgCntry: null,
              OrdLineRef: null,
              TotItemVal: 0,
              OthChrg: 0,
              StateCesNonAdvlAmt: 0,
              StateCesAmt: 0,
              StateCesRt: 0,
              CesNonAdvlAmt: 0,
              CesAmt: 0,
              CesRt: 0,
              SgstAmt: 0,
              CgstAmt: 0,
              IgstAmt: 0,
              Qty: 0,
              AssAmt: 0,
              PreTaxVal: '',
              Discount: 0,
              TotAmt: '',
              UnitPrice: parseFloat(response.data.Price),
              Unit: response.data.Unit,
              FreeQty: '',
              GstRt: parseFloat(response.data.TaxRate),
              Barcde: null,
              HsnCd: response.data.HSNCode,
              IsServc: response.data.Isservice,
              PrdDesc: response.data.ItemName,
              SlNo: items.length // Set SlNo based on the current length of items
          };
        

        // const updatedItem = { ...invoiceData.ItemList[itemIndex], itemDetails };

        console.log("this is updated item");
        // console.log(updatedItem);
        
        
        
        const newItems = [...invoiceData.ItemList];
        newItems[itemIndex] = itemDetails;

        setInvoiceData(prevData => ({
          
          ...prevData,
          ItemList: newItems,
      }));
        
        // setInvoiceData(prevData => ({ ...prevData, BuyerDtls: customerDetails }));
        // setSellerState(response.data.State);
        // console.log(response.data);
         // Axios automatically parses JSON responses
      } catch (error) {
        console.error('Error fetching data:', error);
        // setLoading(false);
        console.log('Please select Seller');
        setAlertMessage('Item data caused server error')
        setAlertVariant('warning');
        setShowAlert(true);
        
      }

      
      };
  
      const fetchTransporterDataFromApi = async (selectedValue) => {
    try {

      const response = await axiosInstance.get(`/transpoterDtl/${selectedValue}`);// Use Axios to make the GET request


      // const Gstin =  response.data.Gstin;
      // console.log(response.data);
      
      // const StateCode = Gstin.substring(0, 2);
      
      // handleDropdownChange('BuyerDtls.Stcd', StateCode);
      // handleDropdownChange('BuyerDtls.Pos', StateCode);

      // setSellerData(response.data);

      // setCustomerData(response.data);

      const transporterDetails  = {
        TransId: response.data.TransporterId,
        TransName: response.data.TransporterName,
        TransMode: response.data.TransportationMode,
        Distance: 0,
        TransDocNo: null,
        TransDocDt: null,
        VehNo: response.data.VehicleNumber,
        VehType: response.data.VehicleType
      }

      setInvoiceData(prevData => ({ ...prevData, EwbDtls: transporterDetails }));
      // setSellerState(response.data.State);
      // console.log(response.data);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
      // setLoading(false);
      console.log('Please select Seller');
      setAlertMessage('Eway Bill data caused server error')
      setAlertVariant('warning');
      setShowAlert(true);
      
    }

    
      };

      const fetchBankDataFromApi = async (selectedValue) => {
      try {

    const response = await axiosInstance.get(`/BankDtl/${selectedValue}`);// Use Axios to make the GET request


    // const Gstin =  response.data.Gstin;
    // console.log(response.data);
    
    // const StateCode = Gstin.substring(0, 2);
    
    // handleDropdownChange('BuyerDtls.Stcd', StateCode);
    // handleDropdownChange('BuyerDtls.Pos', StateCode);

    // setSellerData(response.data);

    // setCustomerData(response.data);

    const bankDetails  = {
      Nm: response.data[0].PayeeName,
      AccDet: response.data[0].Payeeaccountno,
      Mode: response.data[0].TransportationMode,
      FinInsBr: response.data[0].Branchcode,
      CrTrn: null,
      PayInstr: null,
      PayTerm: null,
      DirDr: null
    }

    console.log("bank dtl "+ response.data[0].PayeeName);
    

    setInvoiceData(prevData => ({ ...prevData, PayDtls: bankDetails }));
    // setSellerState(response.data.State);
    // console.log(response.data);
     // Axios automatically parses JSON responses
  } catch (error) {
    console.error('Error fetching data:', error);
    // setLoading(false);
    console.log('Please select Bank');
    setAlertMessage('Bank data caused server error')
    setAlertVariant('warning');
    setShowAlert(true);
    
  }

  
      };

      const fetchEditDataFromApi = async (selectedValue) => {
          console.log("fetcheditfunction RUNNING...");
          
        if (selectedValue !== null && selectedValue !== undefined) {
          try {
          
          const response = await axiosInstance.get(`/invoices/tax/${selectedValue}`); // Use Axios to make the GET request

          const sellerDetails= {

            GSTIN: response.data.invoiceJson[0].SellerDtls.Gstin,
            IEC: '',
            MSME_REG_NO: '',
            TradeName: response.data.invoiceJson[0].SellerDtls.TrdNm,
            legalName: response.data.invoiceJson[0].SellerDtls.LglNm,
            Address1: response.data.invoiceJson[0].SellerDtls.Addr1,
            Address2: response.data.invoiceJson[0].SellerDtls.Addr2,   
            City: response.data.invoiceJson[0].SellerDtls.Loc,
            Pincode: response.data.invoiceJson[0].SellerDtls.Pin,
            State: response.data.invoiceJson[0].SellerDtls.Stcd,
            Phone: response.data.invoiceJson[0].SellerDtls.Ph,  
            Email: response.data.invoiceJson[0].SellerDtls.StEmcd,
            Seller_ID:'',
            NIC_username:'',
            NIC_Password:'',
            author:'',
            _id:''

          }


          setSellerData(sellerDetails);
          setSellerState(response.data.invoiceJson[0].BuyerDtls.Stcd);
          handleDropdownChange('BuyerDtls.Stcd', response.data.invoiceJson[0].BuyerDtls.Stcd);
          handleDropdownChange('BuyerDtls.Pos', response.data.invoiceJson[0].BuyerDtls.Pos);
          

          if (response.data.irnStatus === "Active") {
            setisirnGenerated(true); 
          }
           
          // if (response.data.invoiceJson.TranDtls.RegRev === "Y") {
          //   setIsChecked({igstOnIntra: true})
            
          // }else if (response.data.invoiceJson.TranDtls.IgstOnIntra === "Y"){
          //   setIsChecked({isRegRev: true})

          // }
        
          setInvoiceData(prevData => ({ ...prevData, 
            TranDtls:  response.data.invoiceJson[0].TranDtls,
            DocDtls: response.data.invoiceJson[0].DocDtls,
            SellerDtls: response.data.invoiceJson[0].SellerDtls, 
            BuyerDtls: response.data.invoiceJson[0].BuyerDtls,
            DispDtls: response.data.invoiceJson[0].DispDtls,
            ShipDtls: response.data.invoiceJson[0].ShipDtls,
            EwbDtls: response.data.invoiceJson[0].EwbDtls,
            ExpDtls: response.data.invoiceJson[0].ExpDtls,
            ItemList: response.data.invoiceJson[0].ItemList,
            ValDtls: response.data.invoiceJson[0].ValDtls,
            PayDtls:response.data.invoiceJson[0].PayDtls,
            RefDtls: response.data.invoiceJson[0].RefDtls,
            AddlDocDtls: response.data.invoiceJson[0].AddlDocDtls
             }));
             setEditdataSet(true)
        
          console.log(response.data);
          setLoading(false);
          setProgress(30);
          // Simulate an asynchronous operation (e.g., fetching data or loading resources)
          // Replace the setTimeout with your actual asynchronous operation
          setTimeout(() => {
          setProgress(100); // Set isLoading to false when the component is done loading
  });
           // Axios automatically parses JSON responses
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
          // console.log('Please select Seller');
          // setAlertMessage('Please select Seller')
          setAlertVariant('danger');
          // setShowAlert(true);
          
        }
          
        }else{
          setAlertMessage('Please Select Seller')
          setAlertVariant('danger');
          setShowAlert(true);

        }
          

          
      };
      
      const [invoiceData, setInvoiceData] = useState({
          TranDtls: { TaxSch: 'GST', SupTyp: defaultTransactionType, RegRev: 'N', EcmGstin: null, IgstOnIntra: 'N' },
          DocDtls: { Typ: defaultDocumentType, No: '', Dt: currentDate.split('-').reverse().join('/') },
          BuyerDtls: { Gstin: '', LglNm: null, TrdNm: null, Pos: null, Addr1: null, Addr2: null, Loc: null, Pin: null, Stcd: null, Ph: null, Em: null },
          SellerDtls: { },
          DispDtls: null,
          ShipDtls: null,
          EwbDtls: null,
          ExpDtls: { ShipBNo: null, ShipBDt: null, CntCode: null, ForCur: null, Port: null, RefClm: null, ExpDuty: null},
          ItemList: [
            {
                PrdSlNo: null,
                OrgCntry:null,
                OrdLineRef:null,
                TotItemVal:0,
                OthChrg:0,
                StateCesNonAdvlAmt:0,
                StateCesAmt:0,
                StateCesRt:0,
                CesNonAdvlAmt:0,
                CesAmt:0,
                CesRt:0,
                SgstAmt:0,
                CgstAmt:0,
                IgstAmt:0,
                Qty:0,
                AssAmt: 0 , 
                PreTaxVal:'',
                Discount:0,
                TotAmt:totAmt,
                UnitPrice:0,
                Unit:'',
                FreeQty:'',
                GstRt:0,
                Barcde:null,  
                HsnCd:'',
                IsServc:'N',
                PrdDesc:'',
                SlNo:''
            }
        ],
          ValDtls: { AssVal: 0, CgstVal: 0, SgstVal: 0, IgstVal: 0, CesVal: 0, StCesVal: 0, RndOffAmt: 0, TotInvVal: 0, TotInvValFc: 0, Discount: 0, OthChrg: 0 },
          PayDtls: { Nm: null, AccDet: null, Mode: null, FinInsBr: null, CrTrn: null, PayInstr: null, PayTerm: null, DirDr: null, CrDay: null, PaidAmt: null, PaymtDue: null },
          RefDtls: { InvRm: null, PrecDocDtls: [], ContrDtls: [{PORefr:null, PORefDt: null}], DocPerdDtls: [] },
          AddlDocDtls: []
      });


      
      useEffect(() => {

        if (customerID) {
                
          if (!iseditdataSet) {
            fetchEditDataFromApi(customerID);     
          }
        }   

        fetchDataFromApi(sellerid);

  if (buyerState !== "" && sellerState !== "") {
    if (buyerState === sellerState) {
      setIsIntrastate(true);
      console.log("Intrastate Transaction");
    } else {
      setIsIntrastate(false);
      console.log("Interstate Transaction");
    }
  }

  // Recalculate items only if isIntrastate changes
  const recalculateItems = () => {
    const newItems = invoiceData.ItemList.map((item) => {
      const updatedItem = { ...item };
      const gstRate = parseFloat(updatedItem.GstRt) || 0;
      const assAmt = parseFloat(updatedItem.AssAmt) || 0;
      
      

      if (isIntrastate) {
        updatedItem.CgstAmt = parseFloat(((assAmt * (gstRate / 100) / 2)).toFixed(2)); 
        updatedItem.SgstAmt = parseFloat(((assAmt * (gstRate / 100) / 2)).toFixed(2));
        updatedItem.IgstAmt = 0; // Reset IGST if intrastate
        
      } else {
        updatedItem.CgstAmt = 0; // Reset CGST if interstates
        updatedItem.SgstAmt = 0; // Reset SGST if interstate
        updatedItem.IgstAmt = parseFloat(((assAmt * (gstRate / 100))).toFixed(2));
      }

      updatedItem.TotItemVal = parseFloat((updatedItem.AssAmt + updatedItem.CgstAmt + updatedItem.SgstAmt + updatedItem.IgstAmt).toFixed(2));
      
      

      return updatedItem;

    });

    // const newItem = [...invoiceData.ItemList];

    console.log("u r entered");
    
    
      const TotcgstValue = newItems.reduce((acc, item) => acc + parseFloat(item.CgstAmt || 0), 0).toFixed(2);
      const TotsgstValue = newItems.reduce((acc, item) => acc + parseFloat(item.SgstAmt || 0), 0).toFixed(2);
      const TotigstValue = newItems.reduce((acc, item) => acc + parseFloat(item.IgstAmt || 0), 0).toFixed(2);
      const TotInvoiceValue = parseFloat(newItems.reduce((acc, item) => acc + parseFloat(item.TotItemVal || 0), 0).toFixed(2));
      const valDtlOtherChrg = parseFloat(invoiceData.ValDtls.OthChrg);
      const valDtlDiscountChrg = parseFloat(invoiceData.ValDtls.Discount);
      // console.log(invoiceData.ValDtls.IgstVal);
      invoiceData.ValDtls.CgstVal = parseFloat(TotcgstValue);
      invoiceData.ValDtls.SgstVal = parseFloat(TotsgstValue);
      invoiceData.ValDtls.IgstVal = parseFloat(TotigstValue);
      invoiceData.ValDtls.TotInvVal = parseFloat((TotInvoiceValue+valDtlOtherChrg)-valDtlDiscountChrg);

      // updatedItem.TotItemVal = parseFloat((updatedItem.AssAmt + updatedItem.CgstAmt + updatedItem.SgstAmt + updatedItem.IgstAmt).toFixed(2));

      // console.log(invoiceData.ValDtls.CgstVal);

    setInvoiceData(prevData => ({
      ...prevData,
      ItemList: newItems
    }));

    
  };

  recalculateItems();
  setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });

      }, [buyerState, isIntrastate]);

      const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isirnGenerated) {

          const formData = {
            invoiceJson: {
                Version: '1.1',
                TranDtls: invoiceData.TranDtls,
                DocDtls: invoiceData.DocDtls,
                BuyerDtls: invoiceData.BuyerDtls,
                SellerDtls: invoiceData.SellerDtls,
                DispDtls: invoiceData.DispDtls,
                ShipDtls: invoiceData.ShipDtls,
                EwbDtls: invoiceData.EwbDtls,
                ExpDtls: invoiceData.ExpDtls,
                ItemList: invoiceData.ItemList,
                ValDtls: invoiceData.ValDtls,
                PayDtls: invoiceData.PayDtls,
                RefDtls: invoiceData.RefDtls,
                AddlDocDtls: invoiceData.AddlDocDtls
            },
            ewayJson:invoiceData?.EwbDtls ?? [],
            SellerGSTIN: invoiceData.SellerDtls.Gstin,
            irnStatus: "Not generated",
            ewayStatus: "Not generated",
            irnError: null,
            author: userid
          };
          if (customerID) {
            console.log("Edit API RUNING");
            
            try {
              setShowAlert(false);
             
              const response = await axiosInstance.put(`/edit-invoice/tax/${customerID}`, 
              
                formData);
                
              // Handle the API response (e.g., display a success message, redirect, etc.)
              console.log('Customer Edited successful:', response.data);
              setAlertVariant('success');
        
              // navigate('../Masters?EditedDone=customer Updated');
              navigate('../?Edited=Invoice Edit successfully!');
            } catch (error) {
              // Handle errors (e.g., display error messages to the user)
              console.error('Customer added failed:', error.response.data);
              setAlertVariant('danger');
        
              handleApiError(error, navigate)
              
            }finally {
              // Set isLoading to false when the API request completes (whether it succeeds or fails)
              setProgress(100);
              setShowAlert(true);
            }
          }
          else{
  
            console.log(formData);
  
            try {
              console.log("Add new invoice API RUNING");
              setShowAlert(false);
             
              const response = await axiosInstance.post('/add-tax-invoice', formData);
          
              // Handle the API response (e.g., display a success message, redirect, etc.)
              console.log('Invoice Added successful:', response.data);
              setAlertVariant('success');
        
              navigate('../?success=Invoice added successfully!');
            } catch (error) {
              // Handle errors (e.g., display error messages to the user)
              console.error('Invoice added failed:', error.response.data);
              setAlertVariant('danger');
        
              handleApiError(error, navigate)
              
            }finally {
              // Set isLoading to false when the API request completes (whether it succeeds or fails)
              setProgress(100);
              setShowAlert(true);
            }
  
  
          }
          
        }else{
          navigate('../');
        }

        
          
      };

      const handleCustomerFieldsChange = (e) => {
          const { name, value } = e.target;
          const [section, field] = name.split('.');
          setInvoiceData(prevData => ({
              ...prevData,
              [section]: { ...prevData[section], [field]: value }
          }));

      };
      const handleExportFieldsChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');
        setInvoiceData(prevData => ({
            ...prevData,
            [section]: { ...prevData[section], [field]: value }
        }));

    };

      const handlePrecDocDtlsFieldsChange = (e) => {
        const { name, value } = e.target;
        const [section, subSection, field2] = name.split('.');
    
        console.log("this is section " + section);
        console.log("this is subSection " + subSection);
        console.log("this is field2 " + field2);
        console.log("this is value " + value);
    
        setInvoiceData(prevData => {
            // Ensure the section and subSection exist, otherwise initialize them
            const updatedArray = prevData[section]?.[subSection] 
                ? [...prevData[section][subSection]] // If subSection exists, clone the array
                : []; // Otherwise, initialize as an empty array
    
            // Update the first object in the array (index 0). Modify this for other objects if needed.
            if (updatedArray.length > 0) {
                updatedArray[0] = { ...updatedArray[0], [field2]: value }; // Update the specific field
            } else {
                updatedArray.push({ [field2]: value }); // Add new object if array is empty
            }
    
            return {
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [subSection]: updatedArray // Update the array in the specific subSection
                }
            };
        });
    };
    
    

      const handleItemFieldsChange = (index, e) => {
        
        const { name, value, type } = e.target;
        
        const parsedValue = name === 'GstRt' ? parseFloat(value) : (type === 'number' ? parseFloat(value) : value);
        
        // Create a copy of the item being updated
        const updatedItem = { ...invoiceData.ItemList[index], [name]: parsedValue };
        
        // Calculate TotAmt if Qty or UnitPrice is updated
        if (name === 'Qty' || name === 'UnitPrice' || name === 'Discount') {
            const qty = name === 'Qty' ? parsedValue : parseFloat(updatedItem.Qty).toFixed(3) ;
            const discount = name === 'Discount' ? parsedValue : parseFloat(updatedItem.Discount) || 0;
            const unitPrice = name === 'UnitPrice' ? parsedValue : parseFloat(updatedItem.UnitPrice);
            updatedItem.TotAmt = parseFloat((qty * unitPrice).toFixed(2) );
            updatedItem.AssAmt = parseFloat((updatedItem.TotAmt - (discount || 0)).toFixed(2));
        }
       
        
        const gstRate = name === 'GstRt' ? parsedValue : parseFloat(updatedItem.GstRt) || 0;
        const assAmt = name === 'AssAmt' ? parsedValue : parseFloat(updatedItem.AssAmt) || 0;

        // checkIsIntrastate();

        if(isIntrastate){
          
          updatedItem.CgstAmt = parseFloat(((assAmt * (gstRate / 100) / 2)).toFixed(2)); 
          updatedItem.SgstAmt = parseFloat(((assAmt * (gstRate / 100) / 2)).toFixed(2));

        }else{
          updatedItem.IgstAmt = parseFloat(((assAmt * (gstRate / 100))).toFixed(2));
        }

        updatedItem.TotItemVal = parseFloat((updatedItem.AssAmt + updatedItem.CgstAmt + updatedItem.SgstAmt + updatedItem.IgstAmt).toFixed(2));

        // console.log(updatedItem.TotItemVal);
        
    
        // Update the SlNo based on the updated array length
        updatedItem.SlNo = index+1;
    
        // Create a new array of items with the updated item
        const newItems = [...invoiceData.ItemList];
        newItems[index] = updatedItem;

        const TotInvoiceValue = parseFloat(newItems.reduce((acc, item) => acc + parseFloat(item.TotItemVal || 0), 0).toFixed(2));
        const TotAssValue = newItems.reduce((acc, item) => acc + parseFloat(item.AssAmt || 0), 0).toFixed(2);
        const TotcgstValue = newItems.reduce((acc, item) => acc + parseFloat(item.CgstAmt || 0), 0).toFixed(2);
        const TotsgstValue = newItems.reduce((acc, item) => acc + parseFloat(item.SgstAmt || 0), 0).toFixed(2);
        const TotigstValue = newItems.reduce((acc, item) => acc + parseFloat(item.IgstAmt || 0), 0).toFixed(2);
        const valDtlOtherChrg = parseFloat(invoiceData.ValDtls.OthChrg);
        const valDtlDiscountChrg = parseFloat(invoiceData.ValDtls.Discount);

          invoiceData.ValDtls.TotInvVal = parseFloat((TotInvoiceValue+valDtlOtherChrg)-valDtlDiscountChrg);
          invoiceData.ValDtls.AssVal = parseFloat(TotAssValue);
          invoiceData.ValDtls.CgstVal = parseFloat(TotcgstValue);
          invoiceData.ValDtls.SgstVal = parseFloat(TotsgstValue);
          invoiceData.ValDtls.IgstVal = parseFloat(TotigstValue);
          
          console.log("This is valDtlOtherChrg " +  valDtlOtherChrg);
          console.log("This is TotInvoiceValue " +  TotInvoiceValue);
          console.log("This is total " +  TotInvoiceValue+valDtlOtherChrg);
        // Update invoiceData with the new array of items
        setInvoiceData(prevData => ({
          
            ...prevData,
            ItemList: newItems,
        }));

      };

      const handleEwayBillFieldsChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');
    
        setInvoiceData(prevData => {
            const updatedSection = {
                ...prevData[section],
                // Set null if the value is an empty string
                [field]: value.trim() === '' ? null : value
            };
    
            // Safely check if any field in `ewayBill` has a non-null, non-empty value
            const isAnyFieldFilled = Object.values(updatedSection).some(fieldValue => {
                return fieldValue !== null && typeof fieldValue === 'string' && fieldValue.trim() !== '';
            });
    
            if (isAnyFieldFilled) {
                // If any field is filled, mark all fields as required
                return {
                    ...prevData,
                    [section]: updatedSection,
                    ewayBillFieldsRequired: true
                };
            } else {
                // If no field is filled, allow fields to not be required
                return {
                    ...prevData,
                    [section]: updatedSection,
                    ewayBillFieldsRequired: false
                };
            }
        });
    };

    const handleDispatchFieldsChange = (e) => {
      const { name, value } = e.target;
      const [section, field] = name.split('.');
    
      setInvoiceData(prevData => {
        // Update the field's value
        const updatedSection = {
          ...prevData[section],
          // Set null if the value is an empty string
          [field]: value.trim() === '' ? null : value
        };
    
        // Check if any field in the section is filled (i.e., not null or empty)
        const isAnyFieldFilled = Object.values(updatedSection).some(fieldValue => {
          return fieldValue !== null && fieldValue !== '';
        });
    
        // Check if all fields in the section are empty (null or empty strings)
        const areAllFieldsEmpty = Object.values(updatedSection).every(fieldValue => {
          return fieldValue === null || fieldValue === '';
        });
    
        // If all fields are empty, set the entire section to null
        if (areAllFieldsEmpty) {
          return {
            ...prevData,
            [section]: null,
            dispatchFieldsRequired: false // Fields are not required when all are empty
          };
        } 
    
        // If any field is filled, mark all fields as required
        if (isAnyFieldFilled) {
          return {
            ...prevData,
            [section]: updatedSection,
            dispatchFieldsRequired: true // Fields are required when at least one is filled
          };
        }
    
        // If no fields are filled, keep the updated section (fields not required)
        return {
          ...prevData,
          [section]: updatedSection,
          dispatchFieldsRequired: false
        };
      });
    };
    

  const handleShippingFieldsChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');

    setInvoiceData(prevData => {
        // Update the field's value
        const updatedSection = {
            ...prevData[section],
            // Set null if the value is an empty string
            [field]: value.trim() === '' ? null : value
        };

        // Check if any field in the section is filled
        const isAnyFieldFilled = Object.values(updatedSection).some(fieldValue => {
            return fieldValue !== null && typeof fieldValue === 'string' && fieldValue.trim() !== '';
        });

        // Check if all fields in the section are empty (null or empty strings)
        const areAllFieldsEmpty = Object.values(updatedSection).every(fieldValue => {
            return fieldValue === null || (typeof fieldValue === 'string' && fieldValue.trim() === '');
        });

        if (areAllFieldsEmpty) {
            // If all fields are empty, set the entire section to null
            return {
                ...prevData,
                [section]: null,
                shippingBillFieldsRequired: false // Fields are not required when all are empty
            };
        } else if (isAnyFieldFilled) {
            // If any field is filled, mark all fields as required
            return {
                ...prevData,
                [section]: updatedSection,
                shippingBillFieldsRequired: true // Fields are required when at least one is filled
            };
        } else {
            // If no fields are filled, keep the updated section (fields not required)
            return {
                ...prevData,
                [section]: updatedSection,
                shippingBillFieldsRequired: false
            };
        }
    });
};


const handleContractFieldsChange = (e) => {
  const { name, value } = e.target;

  // Split the input name by '.' to get section and array index with field
  const [section, subSectionWithIndex, field] = name.split('.');

  // Extract the index and the actual field name (for example, "ContrDtls[0]" gives index 0)
  const match = subSectionWithIndex.match(/(\w+)\[(\d+)\]/);
  const subSection = match[1]; // "ContrDtls"
  const index = match[2]; // Index, e.g., 0

  setInvoiceData((prevData) => {
    // Get the existing data for the section and sub-section
    const sectionData = prevData[section] || {};
    const subSectionData = sectionData[subSection] || [];

    // Copy the subSection data to avoid mutating the state directly
    const updatedSubSectionData = [...subSectionData];

    // If field is PORefDt, convert date format to DD/MM/YYYY
    const formattedValue = value.trim() === '' ? null : (field === 'PORefDt' ? value.split('-').reverse().join('/') : value);

    // Update the specific field at the correct index
    updatedSubSectionData[index] = {
      ...updatedSubSectionData[index],
      [field]: formattedValue,  // Use the formatted value if it's a date
    };

    // Return the updated state object
    return {
      ...prevData,
      [section]: {
        ...sectionData,
        [subSection]: updatedSubSectionData,
      },
    };
  });
};

const handleBankFieldsChange = (e) => {
  const { name, value } = e.target;
  const [section, field] = name.split('.');

  setInvoiceData(prevData => {
    // Set value to null if it's an empty string, otherwise use the provided value
    const updatedValue = value.trim() === '' ? null : value;

    return {
      ...prevData,
      [section]: { ...prevData[section], [field]: updatedValue }
    };
  });
};



      const handleOtherChrgChange = (index, e) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value) || 0;  // Ensure the input value is a valid number
    
        // Create a copy of the charges array
        const newCharges = [...charges];
        newCharges[index] = parsedValue;
    
        // Update the charges state
        setCharges(newCharges);
    
        // Calculate the sum of all charges
        const sumCharges = newCharges.reduce((acc, curr) => acc + (curr || 0), 0);
    
        // Update the invoiceData state with the new sum and total invoice value
        setInvoiceData(prevData => {
            // Calculate the original total invoice value before adding new charges
            const originalTotInvoiceValue = parseFloat(prevData.ValDtls.TotInvVal) - parseFloat(prevData.ValDtls.OthChrg || 0);
    
            // Calculate the new total invoice value
            const newTotInvoiceValue = (originalTotInvoiceValue + sumCharges).toFixed(2);
    
            console.log("Total invoice value after update: " + newTotInvoiceValue);
    
            return {
                ...prevData,
                ValDtls: {
                    ...prevData.ValDtls,
                    OthChrg: sumCharges,
                    TotInvVal: parseFloat(newTotInvoiceValue)  // Update the total invoice value
                }
            };
        });
    };

  //   const handleCessValueChange = (e) => {
  //     const { value } = e.target;
  //     // const parsedValue = parseFloat(value) || 0;  // Ensure the input value is a valid number
  //     // const originalTotInvoiceValue = parseFloat(prevData.ValDtls.TotInvVal) - parseFloat(prevData.ValDtls.OthChrg || 0);
  
  //     // // Calculate the new total invoice value
  //     // const newTotInvoiceValue =  (originalTotInvoiceValue + parsedValue).toFixed(2);
  //     // // Create a copy of the charges array
  //     // // const newCharges = [...charges];
  //     // // newCharges[index] = parsedValue;
  
  //     // // Update the charges state
  //     // // setCharges(newCharges);
  //     // console.log("Total invoice value after update cess Value: " + newTotInvoiceValue);
  //     // // Calculate the sum of all charges
  //     // // const sumCharges = newCharges.reduce((acc, curr) => acc + (curr || 0), 0);
  
  //     // // Update the invoiceData state with the new sum and total invoice value
  //     // setInvoiceData(prevData => {
  //     //     // Calculate the original total invoice value before adding new charg
  
  //     //     return {
  //     //         ...prevData,
  //     //         ValDtls: {
  //     //             ...prevData.ValDtls,
  //     //             CesVal: value,
  //     //             TotInvVal: parseFloat(newTotInvoiceValue)  // Update the total invoice value
  //     //         }
  //     //     };
  //     // });
  // };


      const handleLessDiscountChange = (e) => {
      const { value } = e.target;
      const parsedValue = parseFloat(value) || 0;  // Ensure the input value is a valid number
      console.log("Total invoice value after update cess Value: " + parsedValue);
      
      
  
      // Calculate the new total invoice value
      
      // Create a copy of the charges array
      // const newCharges = [...charges];
      // newCharges[index] = parsedValue;
  
      // Update the charges state
      // setCharges(newCharges);
      
      // Calculate the sum of all charges
      // const sumCharges = newCharges.reduce((acc, curr) => acc + (curr || 0), 0);
  
      // Update the invoiceData state with the new sum and total invoice value
      setInvoiceData(prevData => {
          // Calculate the original total invoice value before adding new charg

          const originalTotInvoiceValue = parseFloat(prevData.ValDtls.TotInvVal) + parseFloat(prevData.ValDtls.Discount || 0)

          const newTotInvoiceValue =  (originalTotInvoiceValue - parsedValue).toFixed(2);

          
  
          return {
              ...prevData,
              ValDtls: {
                  ...prevData.ValDtls,
                  Discount: parsedValue,
                  TotInvVal: parseFloat(newTotInvoiceValue)  // Update the total invoice value
              }
          };
      });
      };

      // const handleTCSRate = (e) => {  
      //   const { value } = e.target;
      //   const parsedValue = parseFloat(value) || 0;  // Ensure the input value is a valid number
    
      //   setInvoiceData(prevData => {
      //   // Calculate the original total invoice value before adding new charg

      //   const originalTotInvoiceValue = parseFloat(prevData.ValDtls.TotInvVal) + parseFloat(prevData.ValDtls.Discount || 0)

      //   const newTotInvoiceValue =  (originalTotInvoiceValue - parsedValue).toFixed(2);

      //   return {
      //       ...prevData,
      //       ValDtls: {
      //           ...prevData.ValDtls,
      //           Discount: parsedValue,
      //           TotInvVal: parseFloat(newTotInvoiceValue)  // Update the total invoice value
      //       }
      //   };
      //   });
      // };

      const handleRoundOffChange = (e) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value) || 0;  // Ensure the input value is a valid number
        console.log("Total invoice value after update cess Value: " + parsedValue);
        
        setInvoiceData(prevData => {
            // Calculate the original total invoice value before adding new charg
  
            const originalTotInvoiceValue = parseFloat(prevData.ValDtls.TotInvVal) - parseFloat(prevData.ValDtls.RndOffAmt || 0)
  
            const newTotInvoiceValue =  (originalTotInvoiceValue + parsedValue).toFixed(2);
  
            return {
                ...prevData,
                ValDtls: {
                    ...prevData.ValDtls,
                    RndOffAmt: parsedValue,
                    TotInvVal: parseFloat(newTotInvoiceValue)  // Update the total invoice value
                }
            };
        });
        };
      
      const handleDropdownChange = (name, value) => {

        // setBuyerPos(value);
        const [section, field] = name.split('.');

        setInvoiceData(prevData => ({
          ...prevData,
          [section]: { ...prevData[section], [field]: value }
        }));  
        // setSellerState(value)
        // checkIsIntrastate("BUYER", value);
        setBuyerState(value);
        
      };

      const handleExportDropdownChange = (name, value) => {

        const [section, field] = name.split('.');

        setInvoiceData(prevData => ({
          ...prevData,
          [section]: { ...prevData[section], [field]: value }
        }));  

        
      };
      
      const handleSellerDropdownChange = (selectedValue) => {
        
        setSellerId(selectedValue)
        
 
      };
      
      const handleCustomerDropdownChange = (selectedValue) => {
        
        // setCustomerId(selectedValue)
        fetchCustomerDataFromApi(selectedValue);
 
      };
      
      const handleTransporterDropdownChange = (selectedValue) => {
        
        // setCustomerId(selectedValue)
        fetchTransporterDataFromApi(selectedValue);
 
      };
      const handleBankDropdownChange = (selectedValue) => {
        
        // setCustomerId(selectedValue)
        fetchBankDataFromApi(selectedValue);
 
      };

      const handleItemDropdownChange = (selectedValue, itemIndex) => {
        console.log(itemIndex);
        
        // setCustomerId(selectedValue)
        fetchItemDataFromApi(selectedValue, itemIndex);
 
      };

      const handleAddItem = () => {
          // Initialize a new item with default values
          const newItem = {
              PrdSlNo: null,
              OrgCntry: null,
              OrdLineRef: null,
              TotItemVal: 0,
              OthChrg: 0,
              StateCesNonAdvlAmt: 0,
              StateCesAmt: 0,
              StateCesRt: 0,
              CesNonAdvlAmt: 0,
              CesAmt: 0,
              CesRt: 0,
              SgstAmt: 0,
              CgstAmt: 0,
              IgstAmt: 0,
              Qty: 0,
              AssAmt: 0,
              PreTaxVal: '',
              Discount: 0,
              TotAmt: '',
              UnitPrice: 0,
              Unit: '',
              FreeQty: '',
              GstRt: 0,
              Barcde: null,
              HsnCd: 0,
              IsServc: 'N',
              PrdDesc: null,
              SlNo: items.length + 1 // Set SlNo based on the current length of items
          };
      
          // Update the state with the new item
          setItems(prevItems => [...prevItems, newItem]);
          setInvoiceData(prevData => ({
              ...prevData,
              ItemList: [...prevData.ItemList, newItem]
          }));
      };

      const removeItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
        setInvoiceData(prevData => ({
            ...prevData,
            ItemList: newItems.map((item, idx) => ({
                ...item,
                SlNo: idx + 1
            }))
        }));
      };

      const handleAddCharges = () => {
          setChargesItems([...Chargesitems, { id: Chargesitems.length + 1 }]); // Add new item to the list
      };

      const removeChargeItem = (index) => {
          const newItems = Chargesitems.filter((item, i) => i !== index);
          setChargesItems(newItems);
      };

      const handleStartDateChange = (e) => {
        const { name, value } = e.target;
        // Split the date and reformat it as DD/MM/YYYY
        const [year, month, day] = value.split('-');
        const formattedDate = `${day}/${month}/${year}`;
        
        // Split the name field to get section and field details
        const [section, subSection, field2] = name.split('.');
    
        // If the target field is inside a nested structure (like RefDtls.PrecDocDtls.InvDt)
        if (section === 'RefDtls' && subSection === 'PrecDocDtls') {
            setInvoiceData(prevData => {
                // Safely handle arrays and objects inside the section/subSection
                const updatedArray = prevData[section]?.[subSection] 
                    ? [...prevData[section][subSection]] // Clone the existing array if it exists
                    : []; // Initialize an empty array if it doesn't exist
    
                // If there is already an object, update the field in that object, else push a new one
                if (updatedArray.length > 0) {
                    updatedArray[0] = { ...updatedArray[0], [field2]: formattedDate };
                } else {
                    updatedArray.push({ [field2]: formattedDate });
                }
    
                return {
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [subSection]: updatedArray // Update the array in subSection
                    }
                };
            });
        } else {
            // For simple (non-nested) fields
            setInvoiceData(prevData => ({
                ...prevData,
                [section]: { ...prevData[section], [subSection]: formattedDate }
            }));
        }
    };
    

      const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;  // Get the current checked state from the event
    
        // Update the Isservice field in invoiceData based on the current checkbox state
        const [section, field] = name.split('.');
        setInvoiceData(prevData => ({
            ...prevData,
            [section]: { 
                ...prevData[section], 
                [field]: checked ? 'Y' : 'N'  // Use the event's checked value
            }
        }));
    
        // Update the isChecked state
        setIsChecked(checked);
    };

      const handleServiceCheckboxChange = (index, e) => {
      const { name, checked } = e.target;  // Get the current checkbox state
  
      const updatedChecks = checked ? 'Y' : 'N';  // Set to 'Y' if checked, otherwise 'N'
  
      // Create a copy of the specific item and update its IsServc field
      const updatedItem = { 
          ...invoiceData.ItemList[index], 
          [name]: updatedChecks  // Update the IsServc field based on checkbox state
      };
  
      // Create a new array with the updated item
      const newItems = [...invoiceData.ItemList];
      newItems[index] = updatedItem;
  
      // Update the state with the new array of items
      setInvoiceData(prevData => ({
          ...prevData,
          ItemList: newItems  // Make sure you're updating the entire ItemList
      }));
    };

    //This function is run after select excel file that modal shall be open.
    const handleImportGstinDetail = () => {
    setIsModalOpen(true);

      };

//This function is run when we click on close icon or button.
       const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
      };

      const handleGetModal = (event) => {
        event.preventDefault();
        // setIsModalOpen(false);
        console.log('get gst detail btn clicked ' + gstNumberGet);
        
        fetchGstDetailApi(gstNumberGet)
         // Close the modal
      };
      
      const handleGetGstinDetail = (e) => {

        const {value} = e.target;
        console.log(value);

        setGstNumberGet(value);
        
        // fetchGstDetailApi(value);

        // const file = e.target.files[0];
        // readExcelFile(file);
      };


      const fetchGstDetailApi = async (value) => {
        try {

          const response = await axiosInstance.post('/getGst', {gstin:value}); // Use Axios to make the GET request
          
          const Address1 = response.data.refreshResponse.data.pradr.addr.bnm +', ' +response.data.refreshResponse.data.pradr.addr.st + ', ' +response.data.refreshResponse.data.pradr.addr.loc;
          // setSellerState(response.data.State);
          if (response.data.refreshResponse.status_cd === "1") {
            console.log(response.data);
            setAlertMessage('Customer Details imported successfully')
            setAlertVariant('success');;

            setShowAlert(true);
            setIsModalOpen(false)

            // setGstinData(response.data.refreshResponse.data);

            const Gstin =  response.data.refreshResponse.data.gstin
            const StateCode = Gstin.substring(0, 2);

            handleDropdownChange('BuyerDtls.Stcd', StateCode);
            handleDropdownChange('BuyerDtls.Pos', StateCode);

            setCustomerData({
              Gstin: response.data.refreshResponse.data.gstin,
              LegalName: response.data.refreshResponse.data.lgnm,
              Tradename: response.data.refreshResponse.data.tradeNam,
              Address1: Address1,
              Address2: '',
              City: response.data.refreshResponse.data.pradr.addr.dst,
              pinCode: response.data.refreshResponse.data.pradr.addr.pncd,
              State: response.data.refreshResponse.data.pradr.addr,
              Ph: '',
              Em: ''
            })
          
          const buyerDetails  = {
            Gstin: response.data.refreshResponse.data.gstin,
            LglNm: response.data.refreshResponse.data.lgnm,
            TrdNm: response.data.refreshResponse.data.tradeNam,
            Addr1: Address1,
            Addr2: '',
            Loc: response.data.refreshResponse.data.pradr.addr.dst,
            Pin: response.data.refreshResponse.data.pradr.addr.pncd,
            Stcd: StateCode,
            Pos: StateCode,
            Ph: '',
            Em: ''
          }
          setInvoiceData(prevData => ({ ...prevData, BuyerDtls: buyerDetails }));
          }else{
            console.log(response.data);
            setAlertMessage(response.data.refreshResponse.error.message)
            setAlertVariant('danger');
            setShowAlert(true);
            setIsModalOpen(false)
          }
          
          
           // Axios automatically parses JSON responses
        } catch (error) {
          console.error('Error fetching data:', error);
          // setLoading(false);
          // console.log('Please select Seller');
          setAlertMessage('Seller data caused server error')
          setAlertVariant('warning');
          setShowAlert(true);
          setIsModalOpen(false)
          
        }
    };
     const handleCancelButton = () => {
      navigate(-1);; // Close the modal
      };
  
      return(
        <>
    {loading ? (
      <TopLoader />
    ) : (
          <>
          <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
{isModalOpen && (
        <>
      <div className="modal-backdrop fade show"/>
          <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <form className='formDiv' onSubmit={handleGetModal} name="taxInvoiceForm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Get Details From GSTN</h1>
                  <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                <div className='impGstin'><p id='impGstinText'>GSTIN / UIN :</p>
                    <input id='impGSTINField' type="text" className="form-control"  onChange={handleGetGstinDetail}  required/>
                  </div>
                </div>  
                <div className="modal-footer">
                  <button type="button" class="btn  btn-dark" id="cancelinvbtn" onClick={handleCloseModal}>Cancel</button>
                  <button type="submit" className="btn  btn-dark" id="saveinvbtn"  >Get Details</button>
                </div>
              </div></form>
            </div>
          </div></>
        )}
{showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? alertMessage : alertMessage}
        onClose={() => setShowAlert(false)} 
        />
      )}
          <div className='taxinvoicediv'>
          <form className='formDiv' onSubmit={handleSubmit} name="taxInvoiceForm">
          <GenerateFormTitle title="0. Seller Details*" />
          <div className='customerdtlDiv'>
          <div className='row g-3'>
          {/* <div className="col-md-2"> */}
      {/* <label for="inputState" className="form-label">Select Seller:</label> */}
      {/* <SelectSeller onSelect={handleSellerDropdownChange} requiredProp = {true}/> */}
    {/* </div> */}
    <div className="col-md-2">
      <label for="inputPassword4"  className="form-label">GSTIN:</label>
      <input type="text" name='SellerDtls.Gstin'  value={SellerData.GSTIN} className="form-control" id="inputPassword4" disabled/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Trade Name:</label>
      <input type="text" name='SellerDtls.TrdNm'  value={SellerData.TradeName} className="form-control" id="inputAddress" disabled/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress2" className="form-label">Legal Name:</label>
      <input type="text" name='SellerDtls.LglNm' onChange={handleCustomerFieldsChange} value={SellerData.legalName} className="form-control" id="inputAddress2" disabled/>
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">Address 1:</label>
      <input type="text" name='SellerDtls.Addr1' onChange={handleCustomerFieldsChange} value={SellerData.Address1} className="form-control" id="inputCity" disabled/>
    </div></div></div>
          <div className='ItemTitle'><GenerateFormTitle title="1. Customer Details*" />
            <button  type="button" class="btn  btn-dark" className="AddItembtn" onClick={handleImportGstinDetail}>Import Detail from GSTIN</button></div>
          <div className='customerdtlDiv'>
          <div className='row g-3'>      
  <div className="col-md-2">
      <label for="inputState" className="form-label">Customer:</label>
      {/* <SelectCustomer disabledProp={isirnGenerated} onSelect={handleCustomerDropdownChange} requiredProp = {false} /> */}
      <Selecter APILink='customers' Label='Tradename' selecterName='Customer' onSelect={handleCustomerDropdownChange}  disabledProp={isirnGenerated}   />
    </div>
    <div className="col-md-2">
      <label for="inputPassword4"  className="form-label">GSTIN:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Gstin' value={invoiceData.BuyerDtls?.Gstin || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputPassword4" required/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Trade Name:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.TrdNm' value={invoiceData.BuyerDtls?.TrdNm || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputAddress" p/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress2" className="form-label">Legal Name:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.LglNm' value={invoiceData.BuyerDtls?.LglNm || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputAddress2" required/>
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">Address 1:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Addr1' value={invoiceData.BuyerDtls?.Addr1 || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputCity" required/>
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">Address 2:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Addr2' value={invoiceData.BuyerDtls?.Addr2 || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputCity"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">City:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Loc' value={invoiceData.BuyerDtls?.Loc || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputZip" required/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Pincode:</label>
      <input type="number" disabled={isirnGenerated} name='BuyerDtls.Pin' value={invoiceData.BuyerDtls?.Pin || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputZip" required/>
    </div>
    <div className="col-md-2">
      <label for="inputState" className="form-label">State:</label>
      <StateDropdown name='BuyerDtls.Stcd' disabledProp={isirnGenerated} selectedState={invoiceData.BuyerDtls?.Stcd || ""} onSelect={(value) => handleDropdownChange('BuyerDtls.Stcd', value)} required/>
    </div>
    <div className="col-md-2">
      <label for="inputState" className="form-label">Place of Supply:</label>
      <StateDropdown name='BuyerDtls.Pos' disabledProp={isirnGenerated} selectedState={invoiceData.BuyerDtls?.Pos || ""} onSelect={(value) => handleDropdownChange('BuyerDtls.Pos', value)} requiredProp = {true} />
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Phone:</label>
      <input type="number" disabled={isirnGenerated} name='BuyerDtls.Ph' value={invoiceData.BuyerDtls?.Ph || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Email:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Em' value={invoiceData.BuyerDtls?.Em || ""} onChange={handleCustomerFieldsChange} className="form-control" id="inputZip"/>
    </div>
</div></div>
          {/* <hr className="my-4 mb-0 mt-2"></hr> */}
          <GenerateFormTitle title="2. Document Details*" />
          <div className='customerdtlDiv'>
  <div className="row g-3">
  <div className="col-md-2">
      <label for="inputState" className="form-label">Document Type:</label>
      <select id="inputState" disabled={isirnGenerated} name='DocDtls.Typ' value={invoiceData.DocDtls.Typ} onChange={handleCustomerFieldsChange} className="form-select" required>
        <option className='option'>{defaultDocumentType}</option>
      </select>
    </div>
    <div className="col-md-2">
      <label for="inputPassword4" className="form-label">Invoice Number:</label>
      <input type="Text" disabled={isirnGenerated} name='DocDtls.No' value={invoiceData.DocDtls.No} onChange={handleCustomerFieldsChange} className="form-control" id="inputPassword4" required/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Invoice Date:</label>
      <Form.Group>
      <div className='datepickdiv'>
        <Form.Control disabled={isirnGenerated} type="date" name='DocDtls.Dt' max={currentDate} value={invoiceData.DocDtls.Dt.split('/').reverse().join('-')} className='form-control2' onChange={handleStartDateChange} required/></div>
      </Form.Group>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Transaction Type:</label>
      
      {(DocTyp === 'INV' & SupTyp != 'EXPWOP' & SupTyp != 'EXPWP')  && (
      <select id="inputState" disabled={isirnGenerated} name='TranDtls.SupTyp' value={invoiceData.TranDtls.SupTyp} onChange={handleCustomerFieldsChange} className="form-select" required>
        <option className='option' value="B2B">B2B Invoice</option>
        <option className='option' value="SEZWP">SEZ With Payment</option>
        <option className='option' value="SEZWOP">SEZ Without Payment</option>
        <option className='option' value="DEXP">Deemed Export</option>
      </select>)}
      {(DocTyp === 'CRN' || defaultDocumentType === 'DBN') && (
      <select id="inputState" disabled={isirnGenerated} name='TranDtls.SupTyp' value={invoiceData.TranDtls.SupTyp} onChange={handleCustomerFieldsChange} className="form-select" required>
        <option className='option' value="B2B">B2B Invoice</option>
        <option className='option' value="SEZWP">SEZ With Payment</option>
        <option className='option' value="SEZWOP">SEZ Without Payment</option>
        <option className='option' value="DEXP">Deemed Export</option>
        <option className='option' value="EXPWOP">Export Without Payment</option>
        <option className='option' value="EXPWP">Export with Payment</option>
      </select>)}
      {(SupTyp === 'EXPWOP' || SupTyp === 'EXPWP') && (
      <select id="inputState" disabled={isirnGenerated} name='TranDtls.SupTyp' value={invoiceData.TranDtls.SupTyp} onChange={handleCustomerFieldsChange} className="form-select" required>
        <option className='option' value="EXPWOP">Export Without Payment</option>
        <option className='option' value="EXPWP">Export with Payment</option>
      </select>)}
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">E-Commerce GSTIN:</label>
      <input type="text" disabled={isirnGenerated} name='TranDtls.EcmGstin' onChange={handleCustomerFieldsChange} className="form-control" id="inputCity"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Dispatch Document No.:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Gstin' onChange={handleCustomerFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Dispatch Doc Date:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Gstin' onChange={handleCustomerFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Dispatch GSTIN:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Gstin' onChange={handleCustomerFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Terms Of Delivery:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Gstin' onChange={handleCustomerFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Terms & Condition:</label>
      <input type="text" disabled={isirnGenerated} name='BuyerDtls.Gstin' onChange={handleCustomerFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
    <div className="form-check form-switch" id='igstonintraswitch'>
    <input className="form-check-input" disabled={isirnGenerated} name='TranDtls.IgstOnIntra' checked={isChecked.igstOnIntra} 
    onChange={handleCheckboxChange} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
    <label className="form-check-label" for="flexSwitchCheckDefault">Igst on Intra State</label>
    </div></div>
    <div className="col-md-2">
    <div className="form-check form-switch" id='igstonintraswitch'>
    <input className="form-check-input" disabled={isirnGenerated} name='TranDtls.RegRev' checked={isChecked.isRegRev} 
  onChange={handleCheckboxChange} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
    <label className="form-check-label" for="flexSwitchCheckDefaul">Is Reverse Charge</label>
    </div></div>
  </div></div>
          {/* <hr className="my-4 mb-0 mt-2"></hr> */} 
          <div className='ItemTitle'><GenerateFormTitle title="3. Item Details*" />
          <button  type="button" class="btn  btn-dark" className="AddItembtn" onClick={handleAddItem}>Add More Item</button>
          </div>
          
          <div className='customerdtlDiv'>
  <div className='Itemtablediv'>
      {/* <hr className="my-4  mb-0 mt-2"></hr> */}
          <div className="table-responsive">
          <table className="table table-sm itemTable">
    <thead>
        <tr>
          <th>Sl No.</th>
          <th>Item Name</th>
          {/* <th>Item Description</th> */}
          <th>Is Service</th>
          <th>HSN Code</th>
          <th>QTY</th>
          <th>Price</th>
          <th>Unit</th>
          <th>Discount</th> 
          <th>Tax Rate</th>
          <th>Taxable Value</th> 
          <th>Action</th> 
        </tr>
      </thead>
      <tbody>
                    {invoiceData.ItemList.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}.</td>
                        <td className='ItemNameSection' ><div style={{ backgroundColor: 'white' }}>
                          {/* <input className="form-control itemnm" type="text" name='PrdDesc' onChange={(e) => handleItemFieldsChange(index, e)}  required/> */}
                          {/* <SelectItem name="selectItem" disabledProp={isirnGenerated} itemIndex={index} onSelect={handleItemDropdownChange} requiredProp = {false} /> */}
      <Selecter APILink='getStock' Label='ItemName' selecterName='Item' Index={index} onSelect={handleItemDropdownChange}  disabledProp={isirnGenerated}   />
                          
                        <div className="form-floating"><textarea className="form-control itemdesc1" disabled={isirnGenerated} onChange={(e) => handleItemFieldsChange(index, e)} name='PrdDesc' value={invoiceData.ItemList[index].PrdDesc} style={{ paddingTop: '5px' }} type="text" /></div></div></td>
                        {/* <td><input className="form-control itemdesc1" type="text" /></td> */}
                        <td>
                          <div className  ="form-check form-switch isServcCheck">
                            <input className="form-check-input isServiceCheck" disabled={isirnGenerated}  type="checkbox" name="IsServc" style={{ width: '40px', marginLeft: '-15px' }} checked={item.IsServc === 'Y'}  // Control based on the value in ItemList
                              onChange={(e) => handleServiceCheckboxChange(index, e)} role="switch" id={`flexSwitchCheckDefault-${index}`}/>
                          </div>
                        </td>
                        <td><input className="form-control itemhsn" disabled={isirnGenerated} value={invoiceData.ItemList[index].HsnCd} type="text" name='HsnCd' onChange={(e) => handleItemFieldsChange(index, e)}  required/></td>
                        <td><input className="form-control itemqty" disabled={isirnGenerated} value={invoiceData.ItemList[index].Qty}  type="number" step="0.001"  name='Qty' onChange={(e) => handleItemFieldsChange(index, e)}  /></td>
                        <td><input className="form-control itemprice"  disabled={isirnGenerated} value={invoiceData.ItemList[index].UnitPrice} type="number" step="0.001" name='UnitPrice' onChange={(e) => handleItemFieldsChange(index, e)}  /></td>
                        <td> 
                          <select id="inputState" disabled={isirnGenerated} value={invoiceData.ItemList[index].Unit}  className="form-select itemunit"  name='Unit' onChange={(e) => handleItemFieldsChange(index, e)}>
                          <option value="">Unit</option>
          <option value="BAG">BAGS</option>
          <option value="BAL">BALE</option>
          <option value="BDL">BUNDLES</option>
          <option value="BKL">BUCKLES</option>
          <option value="BOU">BILLION OF UNITS</option>
          <option value="BOX">BOX</option>
          <option value="BTL">BOTTLES</option>
          <option value="BUN">BUNCHES</option>
          <option value="CAN">CANS</option>
          <option value="CCM">CUBIC CENTIMETERS</option>
          <option value="CMS">CENTIMETERS</option>
          <option value="CBM">CUBIC METERS</option>
          <option value="CTN">CARTONS</option>
          <option value="DOZ">DOZENS</option>
          <option value="DRM">DRUMS</option>
          <option value="GGK">GREAT GROSS</option>
          <option value="GMS">GRAMMES</option>
          <option value="GRS">GROSS</option>
          <option value="GYD">GROSS YARDS</option>
          <option value="KGS">KILOGRAMS</option>
          <option value="KLR">KILOLITRE</option>
          <option value="KME">KILOMETRE</option>
          <option value="LTR">LITRES</option>
          <option value="MLS">MILLI LITRES</option>
          <option value="MLT">MILILITRE</option>
          <option value="MTR">METERS</option>
          <option value="MTS">METRIC TON</option>
          <option value="NOS">NUMBERS</option>
          <option value="OTH">OTHERS</option>
          <option value="PAC">PACKS</option>
          <option value="PCS">PIECES</option>
          <option value="PRS">PAIRS</option>
          <option value="QTL">QUINTAL</option>
          <option value="ROL">ROLLS</option>
          <option value="SET">SETS</option>
          <option value="SQF">SQUARE FEET</option>
          <option value="SQM">SQUARE METERS</option>
          <option value="SQY">SQUARE YARDS</option>
          <option value="TBS">TABLETS</option>
          <option value="TGM">TEN GROSS</option>
          <option value="THD">THOUSANDS</option>
          <option value="TON">TONNES</option>
          <option value="TUB">TUBES</option>
          <option value="UGS">US GALLONS</option>
          <option value="UNT">UNITS</option>
          <option value="YDS">YARDS</option>
                          </select>
                        </td>
                        <td><input className="form-control disField" disabled={isirnGenerated} step="0.01" value={invoiceData.ItemList[index].Discount} name='Discount' onChange={(e) => handleItemFieldsChange(index, e)} type="number"  /></td>
                        <td>
                          <select className="form-select itemTaxRate" disabled={isirnGenerated} value={invoiceData.ItemList[index].GstRt} name='GstRt' type="number"  onChange={(e) => handleItemFieldsChange(index, e)} required>
                          <option value="0" selected>0</option>
          <option type="number" value="0.25">0.25</option>
          <option value="1">1</option>
          <option value="1.5">1.5</option>
          <option value="3">3</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7.5">7.5</option>
          <option value="12">12</option>
          <option value="18">18</option>
          <option value="28">28</option>
                          </select>
                        </td>
                        <td><input type='number' disabled={isirnGenerated} className="form-control disField"  name="AssAmt" value={item.AssAmt}  onChange={(e) => handleItemFieldsChange(index, e)} required/></td>
                        <td><h4 className='dltitem' onClick={() => removeItem(index)}>x</h4></td>
                      </tr>
                    ))}
                  </tbody>
      
      </table></div></div>


      {/* <button type="button" class="btn  btn-dark" id="otherchrgbtn">Add Other Charges {<MdOutlineExpandCircleDown></MdOutlineExpandCircleDown>}</button> */}
      <div className='ItemTitle'><GenerateFormTitle title="Additional Charges" />
          <button  type="button" class="btn  btn-dark" className="AddItembtn" onClick={handleAddCharges}>Add More Charges</button>
          </div>
      <div className='belowitemDiv'>

        <div className='otherchrgTableDiv'>
      {/* <hr className="my-4  mb-0 mt-2"></hr> */}
          <div className="table-responsive">
          <table className="table table-sm">
      <thead>
        <tr>
          <th>Sl No.</th>
          {/* <th>Taxable?</th> */}
          <th>Item Description</th>
          {/* <th>Tax Rate</th> */}
          {/* <th>HSN/SAC Code</th> */}
          <th>Amount</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {Chargesitems.map((item, index) => (
        <tr key={item.id}>
          <td>{index + 1}.</td>
          {/* <td>
            <select id="inputState" className="form-select">
        <option className='option' selected>No</option>
        <option className='option'>Yes</option>
      </select></td> */}
          <td><input className="form-control itemdesc" disabled={isirnGenerated} type="text" readonly/></td>
          {/* <td><select id="inputState" className="form-select">
          <option value="0" selected>0</option>
          <option value="0.1">0.1</option>
          <option value="0.25">0.25</option>
          <option value="1">1</option>
          <option value="1.5">1.5</option>
          <option value="3">3</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7.5">7.5</option>
          <option value="12">12</option>
          <option value="18">18</option>
          <option value="28">28</option>
      </select></td> */}
          {/* <td><input className="form-control itemhsn1" type="number" /></td> */}
          <td><input className="form-control itemprice1" disabled={isirnGenerated} name='ValDtls.OthChrg' onChange={(e) => handleOtherChrgChange(index, e)}
                type="number"  /></td>
      <td><h4 className='dltitem' onClick={() => removeChargeItem(index)}>x</h4></td>
        </tr>
        ))}
      </tbody>
      
          </table></div></div>

        <div className='totaldiv'>
          {/* <div className='totaltextsDiv'> */}
          {/* <p className='totaltextsfont1'>Subtotal</p> */}
          {/* <p className='totaltextsfont2'>{invoiceData.ValDtls.AssVal}</p></div> */}


          {/* <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Other Charges taxable</p>
          <p className='totaltextsfont2'>0.00</p></div> */}

          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Taxable Value</p>
          <p className='totaltextsfont2'>{invoiceData.ValDtls.AssVal}</p></div>

          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>IGST</p>
          <p className='totaltextsfont2'>{invoiceData.ValDtls.IgstVal}</p></div>


          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>CGST</p>
          <p className='totaltextsfont2'>{invoiceData.ValDtls.CgstVal}</p></div>


          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>SGST</p>
          <p className='totaltextsfont2'>{invoiceData.ValDtls.SgstVal}</p></div>


          {/* <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Cess</p>
          <p className='totaltextsfont2'><input className="form-control cessfield" onChange={handleCessValueChange} type="number" readonly/></p></div> */}


          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Other Charges</p>
          <p className='totaltextsfont2'>{invoiceData.ValDtls.OthChrg}</p></div>


          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Less:Discount</p>
            <p className='totaltextsfont2'><input className="form-control cessfield" disabled={isirnGenerated} onChange={(e) => handleLessDiscountChange(e)} type="number" /></p></div>

  {/* 
          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Invoice Value</p>
          <p className='totaltextsfont2'>0.00</p></div> */}


          {/* <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>TCS %</p>
          <p className='totaltextsfont2'><input className="form-control cessfield" onChange={(e) => handleTCSRate(e)} type="number"/></p></div> */}


          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Round Off</p>
          <p className='totaltextsfont2'><input className="form-control cessfield" disabled={isirnGenerated} step="0.001" onChange={(e) => handleRoundOffChange(e)} type="number" /></p></div>

          <div className='totaltextsDiv'>
          <p className='totaltextsfont1'>Total</p>
          <p className='totaltextsfont2'>{invoiceData.ValDtls.TotInvVal}</p></div>



        </div>
      </div>

  </div>
          <GenerateFormTitle title="4. E-way Bill Details" />
          <div className='customerdtlDiv'>
  <div className="row g-3">
  <div className="col-md-2">
      <label for="inputState" className="form-label">Select Transporter:</label>
      
      <Selecter APILink='getTranspoter' Label='TransporterName' selecterName='Transporter' onSelect={handleTransporterDropdownChange}  disabledProp={isirnGenerated}   />
    
    </div>
    <div className="col-md-2">
      <label for="inputPassword4" className="form-label">Transport ID/GSTIN:</label>
      <input type="text" disabled={isirnGenerated} name='EwbDtls.TransId' value={invoiceData.EwbDtls?.TransId || ''} onChange={handleEwayBillFieldsChange} 
         className="form-control" id="inputPassword4"/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Transport Name:</label>
      <input type="text" disabled={isirnGenerated} name='EwbDtls.TransName' value={invoiceData.EwbDtls?.TransName || ''} onChange={handleEwayBillFieldsChange} 
         className="form-control" id="inputAddress" p/>
    </div>
    <div className="col-md-2">
      <label for="inputState" className="form-label">Transportation Mode:</label>
      <select id="inputState" disabled={isirnGenerated} name='EwbDtls.TransMode' value={invoiceData.EwbDtls?.TransMode || ''} onChange={handleEwayBillFieldsChange} 
         className="form-select">
        <option value="">Trans Mode</option>
        <option value="1" className='option'>Road</option>
        <option value="2" className='option'>Rail</option>
        <option value="3" className='option'>Air</option>
        <option value="4" className='option'>Ship</option>
      </select>
    </div>
    <div className="col-md-2">
      <label for="inputState" className="form-label">Vehicle Number:</label>
      <input type="text" disabled={isirnGenerated} name='EwbDtls.VehNo' value={invoiceData.EwbDtls?.VehNo || ''} onChange={handleEwayBillFieldsChange} 
          className="form-control" id="inputAddress" p/>
    </div>
    <div className="col-md-2">
      <label for="inputState" className="form-label">Vehicle Type:</label>
      <select id="inputState" disabled={isirnGenerated} name='EwbDtls.VehType' value={invoiceData.EwbDtls?.VehType || ''} onChange={handleEwayBillFieldsChange} 
         className="form-select">
        <option className='option' selected>Regular</option>
        <option className='option'>ODC</option>
      </select>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Distance:</label>
      <input type="text" disabled={isirnGenerated} name='EwbDtls.Distance' value={invoiceData.EwbDtls?.Distance || ''} onChange={handleEwayBillFieldsChange} 
         className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Transport Doc No:</label>
      <input type="text" disabled={isirnGenerated} name='EwbDtls.TransDocNo' value={invoiceData.EwbDtls?.TransDocNo || ''} onChange={handleEwayBillFieldsChange} 
         className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Transport Date:</label>
      <input type="text" disabled={isirnGenerated} name='EwbDtls.TransDocDt' value={invoiceData.EwbDtls?.TransDocDt || ''} onChange={handleEwayBillFieldsChange} 
         className="form-control" id="inputZip"/>
    </div>
  </div></div>
          <GenerateFormTitle title="5. Dispatch Details" />
          <div className='customerdtlDiv'>
  <div className="row g-3">
  <div className="col-md-2">
      <label for="inputState" className="form-label">Company Name:</label>
      <input type="text" disabled={isirnGenerated} name='DispDtls.Nm' value={invoiceData.DispDtls?.Nm || ''} 
      onChange={handleDispatchFieldsChange} required={invoiceData.dispatchBillFieldsRequired} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputPassword4" className="form-label">Company Adddress:</label>
      <input type="text" disabled={isirnGenerated} name='DispDtls.Addr1' value={invoiceData.DispDtls?.Addr1 || ''} 
      onChange={handleDispatchFieldsChange} required={invoiceData.dispatchBillFieldsRequired} className="form-control" id="inputPassword4"/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Company Address 2:</label>
      <input type="text" disabled={isirnGenerated} name='DispDtls.Addr2' value={invoiceData.DispDtls?.Addr2 || ''} 
      onChange={handleDispatchFieldsChange} className="form-control" id="inputAddress" p/>
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">Company Location:</label>
      <input type="text" disabled={isirnGenerated} name='DispDtls.Loc' value={invoiceData.DispDtls?.Loc || ''} 
      onChange={handleDispatchFieldsChange} required={invoiceData.dispatchBillFieldsRequired} className="form-control" id="inputCity"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Company Pincode.:</label>
      <input type="number" disabled={isirnGenerated} name='DispDtls.Pin' value={invoiceData.DispDtls?.Pin || ''} 
      onChange={handleDispatchFieldsChange} required={invoiceData.dispatchBillFieldsRequired} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Company State:</label>
      <StateDropdown name = "DispDtls.Stcd" disabledProp={isirnGenerated} selectedState={invoiceData.DispDtls?.Stcd || ""}  onSelect={(value) => handleDropdownChange('DispDtls.Stcd', value)} requiredProp = {invoiceData.dispatchBillFieldsRequired} />
    </div>
    
    
  </div></div>
          <GenerateFormTitle title="6. Consignee/ Ship-to" />
          <div className='customerdtlDiv'>
  <div className="row g-3">
    <div className="col-md-2">
      <label for="inputPassword4" className="form-label">GSTIN:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.Gstin' value={invoiceData.ShipDtls?.Gstin || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputPassword4"/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Trade Name:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.TrdNm' value={invoiceData.ShipDtls?.TrdNm || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputAddress" p/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress2" className="form-label">Legal Name:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.LglNm' value={invoiceData.ShipDtls?.LglNm || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputAddress2"/>
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">Address 1:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.Addr1' value={invoiceData.ShipDtls?.Addr1 || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputCity"/>
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">Address 2:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.Addr2' value={invoiceData.ShipDtls?.Addr2 || ''} 
      onChange={handleShippingFieldsChange}  className="form-control" id="inputCity"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">City:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.Loc' value={invoiceData.ShipDtls?.Loc || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Pincode:</label>
      <input type="number" disabled={isirnGenerated} name='ShipDtls.Pin' value={invoiceData.ShipDtls?.Pin || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputState" className="form-label">State:</label>
      <StateDropdown name = "ShipDtls.Stcd" disabledProp={isirnGenerated} selectedState={invoiceData.ShipDtls?.Stcd || ""}  onSelect={(value) => handleDropdownChange('ShipDtls.Stcd', value)} requiredProp = {invoiceData.shippingBillFieldsRequired} />
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Contact Person Name:</label>
      <input type="text" disabled={isirnGenerated}  className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Phone:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.Ph' value={invoiceData.ShipDtls?.Ph || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Email:</label>
      <input type="text" disabled={isirnGenerated} name='ShipDtls.Em' value={invoiceData.ShipDtls?.Em || ''} 
      onChange={handleShippingFieldsChange} required={invoiceData.shippingBillFieldsRequired} className="form-control" id="inputZip"/>
    </div>
  </div></div>
          <GenerateFormTitle title="7. Contract Details" />
          <div className='customerdtlDiv'>
  <div className="row g-3">
    <div className="col-md-2">
      <label for="inputPassword4" className="form-label">Vendor PO No.:</label>
      <input type="text" disabled={isirnGenerated} name='RefDtls.ContrDtls[0].PORefr' value={invoiceData?.RefDtls?.ContrDtls[0]?.PORefr || ''}
      onChange={handleContractFieldsChange} className="form-control" id="inputPassword4"/>
    </div>
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Vendor PO Date:</label>
      <Form.Group>
      <div className='datepickdiv'>
        <Form.Control type="date" disabled={isirnGenerated} name='RefDtls.ContrDtls[0].PORefDt' max={currentDate} value={invoiceData?.RefDtls?.ContrDtls?.[0]?.PORefDt?.split('/').reverse().join('-') || ''} // Handle null value safely by providing an empty string as fallback className='form-control2' 
        onChange={handleContractFieldsChange} /></div>
      </Form.Group>
    </div>
  </div></div>
          <GenerateFormTitle title="8. Payment Details" />
          <div className='customerdtlDiv'>
  <div className="row g-3">
  
  <div className="col-md-2">
      <label for="inputState" className="form-label">Select Bank:</label>
    
      <Selecter APILink='getBankDetails' Label='NameofBank' selecterName='Bank' onSelect={handleBankDropdownChange}  disabledProp={isirnGenerated}   />
    
    </div>
    
    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Payee Name:</label>
      <input type="text" disabled={isirnGenerated} name='PayDtls.Nm' value={invoiceData.PayDtls?.Nm || ''} 
      onChange={handleBankFieldsChange} className="form-control" id="inputAddress" p/>
    </div>

    <div className="col-md-2">
      <label for="inputAddress" className="form-label">Payee A/C Number:</label>
      <input type="text" disabled={isirnGenerated} name='PayDtls.AccDet' value={invoiceData.PayDtls?.AccDet || ''} 
      onChange={handleBankFieldsChange} className="form-control" id="inputAddress" p/>
    </div>

    <div className="col-md-2">
      <label for="inputState" className="form-label">Name of Bank:</label>
      <select id="inputState" 
      onChange={handleBankFieldsChange} disabled={isirnGenerated}  className="form-select">
      <option value="Bank of Baroda">Bank of Baroda</option>
    <option value="Bank of India">Bank of India</option>
    <option value="Bank of Maharashtra">Bank of Maharashtra</option>
    <option value="Canara Bank">Canara Bank</option>
    <option value="Central Bank of India">Central Bank of India</option>
    <option value="Indian Bank">Indian Bank</option>
    <option value="Indian Overseas Bank">Indian Overseas Bank</option>
    <option value="Punjab and Sind Bank">Punjab and Sind Bank</option>
    <option value="Punjab National Bank">Punjab National Bank</option>
    <option value="State Bank of India">State Bank of India</option>
    <option value="UCO Bank">UCO Bank</option>
    <option value="Union Bank of India">Union Bank of India</option>
    <option value="Axis Bank Ltd.">Axis Bank Ltd.</option>
    <option value="City Union Bank Ltd.">City Union Bank Ltd.</option>
    <option value="DCB Bank Ltd">DCB Bank Ltd</option>
    <option value="Federal Bank Ltd.">Federal Bank Ltd.</option>
    <option value="HDFC Bank Ltd.">HDFC Bank Ltd.</option>
    <option value="ICICI Bank Ltd.">ICICI Bank Ltd.</option>
    <option value="IDBI Bank Ltd.">IDBI Bank Ltd.</option>
    <option value="IDFC FIRST Bank Ltd">IDFC FIRST Bank Ltd</option>
    <option value="IndusInd Bank Ltd">IndusInd Bank Ltd</option>
    <option value="Jammu and Kashmir Bank Ltd.">Jammu and Kashmir Bank Ltd.</option>
    <option value="Karnataka Bank Ltd.">Karnataka Bank Ltd.</option>
    <option value="Karur Vysya Bank Ltd.">Karur Vysya Bank Ltd.</option>
    <option value="Kotak Mahindra Bank Ltd.">Kotak Mahindra Bank Ltd.</option>
    <option value="RBL Bank Ltd">RBL Bank Ltd</option>
    <option value="South Indian Bank Ltd.">South Indian Bank Ltd.</option>
    <option value="Yes Bank Ltd.">Yes Bank Ltd.</option>
    <option value="Dhanlaxmi Bank Ltd.">Dhanlaxmi Bank Ltd.</option>
    <option value="Bandhan Bank Ltd.">Bandhan Bank Ltd.</option>
    <option value="CSB Bank Ltd.">CSB Bank Ltd.</option>
      </select>
    </div>

    <div className="col-md-2">
      <label for="inputZip" className="form-label">Branch or IFSC Code.:</label>
      <input type="text" disabled={isirnGenerated} name='PayDtls.FinInsBr' value={invoiceData.PayDtls?.FinInsBr || ''} 
      onChange={handleBankFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputCity" className="form-label">Mode of Payment:</label>
      <input type="text" disabled={isirnGenerated} name='PayDtls.Mode' value={invoiceData.PayDtls?.Mode || ''} 
      onChange={handleBankFieldsChange} className="form-control" id="inputCity"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Payment Term:</label>
      <input type="text" disabled={isirnGenerated} name='PayDtls.PayTerm' value={invoiceData.PayDtls?.PayTerm || ''} 
      onChange={handleBankFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Payment Instruction:</label>
      <input type="text" disabled={isirnGenerated} name='PayDtls.PayInstr' value={invoiceData.PayDtls?.PayInstr || ''} 
      onChange={handleBankFieldsChange} className="form-control" id="inputZip"/>
    </div>
    <div className="col-md-2">
      <label for="inputZip" className="form-label">Direct Debit:</label>
      <input type="text" disabled={isirnGenerated} name='PayDtls.DirDr' value={invoiceData.PayDtls?.DirDr || ''} 
      onChange={handleBankFieldsChange} className="form-control" id="inputZip"/>
    </div>
  </div></div>
  {(defaultDocumentType === 'CRN' || defaultDocumentType === 'DBN') && (
  <><GenerateFormTitle title="9. Preceding Document Details" /><div className='customerdtlDiv'>
                        <div className="row g-3">

                          <div className="col-md-2">
                            <label for="inputPassword4" className="form-label">Preceding Invoice Number:</label>
                            <input type="text" value={invoiceData.RefDtls?.PrecDocDtls?.[0]?.InvNo || null} onChange={handlePrecDocDtlsFieldsChange} disabled={isirnGenerated} name='RefDtls.PrecDocDtls.InvNo'  className="form-control" id="inputPassword4" />
                          </div>
                          <div className="col-md-2">
                            <label for="inputAddress" className="form-label">Preceding Invoice Date:</label>
                            <Form.Group>
      <div className='datepickdiv'>
        <Form.Control disabled={isirnGenerated} type="date" name='RefDtls.PrecDocDtls.InvDt' max={currentDate} value={invoiceData.RefDtls?.PrecDocDtls?.[0]?.InvDt?.split('/').reverse().join('-') || ''} className='form-control2' onChange={handleStartDateChange}/></div>
      </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <label for="inputCity" className="form-label">Other Reference:</label>
                            <input type="text" value={invoiceData.RefDtls?.PrecDocDtls?.[0]?.OthRefNo || ""} disabled={isirnGenerated} name='RefDtls.PrecDocDtls.OthRefNo' onChange={handlePrecDocDtlsFieldsChange} className="form-control" id="inputCity" />
                          </div>

                        </div></div></> )}

                        {(SupTyp === 'EXPWOP' || SupTyp === 'EXPWP') && (
    <><GenerateFormTitle title="10. Export Details*" /><div className='customerdtlDiv'>
                        <div className="row g-3">
                          <div className="col-md-2">
                            <label for="inputState" className="form-label">Shipping Bill Date:</label>
                            <Form.Group>
      <div className='datepickdiv'>
        <Form.Control disabled={isirnGenerated} type="date" name='ExpDtls.ShipBDt' max={currentDate} value={invoiceData.ExpDtls?.ShipBDt?.split('/').reverse().join('-') || ''} className='form-control2' onChange={handleStartDateChange}/></div>
      </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <label for="inputPassword4" className="form-label">Shipping Bill No.:</label>
                            <input type="text" disabled={isirnGenerated} name='ExpDtls.ShipBNo' value={invoiceData.ExpDtls?.ShipBNo || ''} onChange={handleExportFieldsChange} className="form-control" id="inputPassword4" />
                          </div>
                          <div className="col-md-2">
                            <label for="inputState" className="form-label">Source Port:</label>
                            <PortSelector name='ExpDtls.Port' selectedPort={invoiceData.ExpDtls?.Port || ""} disabledProp={isirnGenerated} onSelect={(value) => handleExportDropdownChange('ExpDtls.Port', value)}    />
                          </div>
                          {/* <div className="col-md-2">
                            <label for="inputAddress" className="form-label">Destination Port:</label>
                            <input type="text" disabled={isirnGenerated} name='ExpDtls.ShipBNo' value={invoiceData.ExpDtls?.ShipBNo || ''} onChange={handleExportFieldsChange} className="form-control" id="inputAddress" p />
                          </div> */}
                          {/* <div className="col-md-2">
                            <label for="inputCity" className="form-label">Destination:</label>
                            <input type="text" className="form-control" id="inputCity" />
                          </div> */}
                          <div className="col-md-2">
                            <label for="inputState" className="form-label">Country Code:</label>
                            <CountrySelector name='ExpDtls.CntCode' selectedCountry={invoiceData.ExpDtls?.CntCode || ""} disabledProp={isirnGenerated} onSelect={(value) => handleExportDropdownChange('ExpDtls.CntCode', value)}    />
                          </div>
                          {/* <div className="col-md-2">
                            <label for="inputState" className="form-label">Dispatched Through:</label>
                            <select id="inputState" className="form-select">
                              <option className='option' selected>Invoice</option>
                              <option className='option'>...</option>
                            </select>
                          </div> */}
                          {/* <div className="col-md-2">
                            <label for="inputZip" className="form-label">Vessel/Flight No.:</label>
                            <input type="text" className="form-control" id="inputZip" />
                          </div> */}
                          <div className="col-md-2">
                            <label for="inputState" className="form-label">Refund Claim:</label>
                            <select id="inputState" disabled={isirnGenerated} name='ExpDtls.RefClm' value={invoiceData.ExpDtls?.RefClm || ''} onChange={handleExportFieldsChange} className="form-select">
                              <option className='option' value={"N"} selected>N</option>
                              <option className='option' value={"Y"}>Y</option>
                            </select>
                          </div>
                          <div className="col-md-2">
                            <label for="inputZip" className="form-label">Export Duty:</label>
                            <input type="number" disabled={isirnGenerated} name='ExpDtls.ExpDuty' value={invoiceData.ExpDtls?.ExpDuty || ''} onChange={handleExportFieldsChange} className="form-control" id="inputZip" />
                          </div>
                          <div className="col-md-2">
                            <label for="inputState" className="form-label">Currency Code:</label>
                            <CurrencySelector name='ExpDtls.ForCur' selectedCurrency={invoiceData.ExpDtls?.ForCur || ""} disabledProp={isirnGenerated} onSelect={(value) => handleExportDropdownChange('ExpDtls.ForCur', value)}    />
                          </div>
                          <div className="col-md-2">
                            <label for="inputZip" className="form-label">Exchange Rate:</label>
                            <input type="text" className="form-control" id="inputZip" />
                          </div>
                          {/* <div className="col-md-2">
                            <label for="inputZip" className="form-label">Lut No.:</label>
                            <input type="text" className="form-control" id="inputZip" />
                          </div> */}
                          {/* <div className="col-md-2">
                            <label for="inputZip" className="form-label">POR By Pre-Carrier:</label>
                            <input type="text" className="form-control" id="inputZip" />
                          </div> */}
                        </div></div></>)}






  <div className='submitinvbtndiv'>
              <button type="submit" class="btn  btn-dark" id="saveinvbtn" >Save</button>
          <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
          
  </form></div>
              
          
          
        </>
        )}
        </>
      )
    
    };
  export default Taxinvoice;