// Alert.js
import React from 'react';
import './Alert1.css'

const Alert = ({ title, variant, message, onClose }) => {

    const alertClass = `alert alert-${variant} alert-dismissible fade show`;
  return (
    <div className='alertDiv'>
    <div className={alertClass} role="alert">
  <strong>{title}</strong> {message}
  <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div></div>
  );
};

export default Alert;
