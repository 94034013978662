import React from 'react';
import './subscription.css';

const SubscriptionStatus = ({
  isActive,
  renewalDate,
  invoiceLimit,
  totalInvoicesGenerated,
  contactNumber,
  contactEmail,
}) => {
  return (
    <div className="subscription-status">
      <h2>Subscription Status</h2>
      {isActive ? (
        <div className="status active">
          <p>Your account is <strong>activated</strong> on an annual plan.</p>
          <p>Renewal Date: <span>{renewalDate}</span></p>
          <p>Invoices Allowed: <strong>{invoiceLimit}</strong> per year</p>
          <p>Invoices Generated: <strong>{totalInvoicesGenerated}</strong></p>
        </div>
      ) : (
        <div className="status inactive">
          <p>Your account is currently <strong>not activated</strong>.</p>
          <p>Please subscribe to activate your account.</p>
        </div>
      )}

      <div className="contact-info">
        <p>For renewal, contact:</p>
        <p>Phone: <a href={`tel:${contactNumber}`}>{contactNumber}</a></p>
        <p>Email: <a href={`mailto:${contactEmail}`}>{contactEmail}</a></p>
      </div>
    </div>
  );
};

export default SubscriptionStatus;
