import React from 'react';
import Select from 'react-select';
import './portCodeDropdown.css'


const portData = [
  { code: "INNRP6", description: "AA LTD." },
  { code: "INAPI6", description: "AAP-SEZ AHMEDABAD" },
  { code: "INACH1", description: "Achra" },
  { code: "INQRP6", description: "ADANI ICD KILARAIPUR" },
  { code: "INJSM6", description: "ADANI POWER SEZ" },
  { code: "INGGE6", description: "AEPL SEZ GURGAON" },
  { code: "INBSW6", description: "AFS KAPASHERA" },
  { code: "INAGTB", description: "Agartala" },
  { code: "INIXA4", description: "Agartala" },
  { code: "INAGX4", description: "Agatti Island" },
  { code: "INAGI1", description: "Agatti Island" },
  { code: "INAGR4", description: "Agra" },
  { code: "INAGR6", description: "Agra" },
  { code: "INBLJ6", description: "Agra" },
  { code: "INAMD4", description: "Ahmedabad" },
  { code: "INAMD5", description: "Ahmedabad" },
  { code: "INAMD6", description: "Ahmedabad" },
  { code: "INADA6", description: "Ahmedabad Adalaj" },
  { code: "INPUA6", description: "AIGP SEZ WAGHOLI" },
  { code: "INAJL4", description: "Aizawl" },
  { code: "INAKD4", description: "Akola" },
  { code: "INALA1", description: "ALANG SBY" },
  { code: "INABG1", description: "Alibag" },
  { code: "INIXD4", description: "Allahabad" },
  { code: "INALF1", description: "Allepey" },
  { code: "INAMG6", description: "Amingaon (Gauhati)" },
  { code: "INAMI1", description: "Amini Island" },
  { code: "INASR6", description: "Amritsar" },
  { code: "INATQ4", description: "Amritsar" },
  { code: "INATQ6", description: "Amritsar" },
  { code: "INASR2", description: "Amritsar Railway Stn" },
  { code: "INNNN6", description: "AMRL SEZ NANGUNERI" },
  { code: "INBUL6", description: "AN FTWZ BULANDSHAHR" },
  { code: "INAPT6", description: "Anaparti" },
  { code: "INADI1", description: "Androth Island" },
  // Add more data here
];

const PortSelector = ({ name, onSelect, disabledProp, selectedPort }) => {
    const options = portData.map(port => ({
      value: port.code,
      label: `${port.code} - ${port.description}`,
    }));

    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: 27, // Set a specific height
          minHeight: 27, // Set a minimum height
          padding: '0', // Remove all padding
            // lineHeight: '10px', // Set line height to match height for vertical alignment
            fontSize: '12px',
            marginTop: '3px',
        //   fontSize: '12px',
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: '12px',
          padding: '0px', // Decrease font size
        }),
        menu: (provided) => ({
          ...provided,
          fontSize: '12px', // Decrease font size for dropdown
        }),
        option: (provided) => ({
          ...provided,
          padding: 8, // Decrease padding for options
          fontSize: '12px', // Decrease font size for options
        }),
      };

      const selectedOption = options.find(option => option.value === selectedPort);
  
    return (
      <Select
        name={name}
        options={options}
        isDisabled={disabledProp}
        onChange={(selectedOption) => onSelect(selectedOption ? selectedOption.value : '')}
        placeholder="Select Port"
        isSearchable
        styles={customStyles}
        value={selectedOption || null}
      />
    );
  };
  
  export default PortSelector;
