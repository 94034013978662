import React from 'react'

import './customerdtl.css'

function Documentdtl() {

return(
<div className='customerdtlDiv'>
<form className="row g-3">
<div className="col-md-2">
    <label for="inputState" className="form-label">Document Type:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Invoice Number:</label>
    <input type="password" className="form-control" id="inputPassword4"/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Transaction Type:</label>
    <input type="text" className="form-control" id="inputAddress" p/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">E-Commerce GSTIN:</label>
    <input type="text" className="form-control" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Dispatch Document No.:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Dispatch Doc Date:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Dispatch GSTIN:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Terms Of Delivery:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Terms & Condition:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
  <div className="form-check form-switch" id='igstonintraswitch'>
  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  <label className="form-check-label" for="flexSwitchCheckDefault">Igst on Intra State</label>
  </div></div>
  <div className="col-md-2">
  <div className="form-check form-switch" id='igstonintraswitch'>
  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  <label className="form-check-label" for="flexSwitchCheckDefaul">Is Reverse Charge</label>
  </div></div>
</form></div>
)
    
}

export default Documentdtl;
