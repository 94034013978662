import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../api';

// Helper to set access token and its expiration
export function setAccessToken(expiresIn) {
  const expirationTime = Date.now() + expiresIn * 1000; // expiresIn is in seconds
  localStorage.setItem("accessTokenExpiration", expirationTime);
}

export function isTokenExpired(token) {
    // Check if the token is expired (you can parse the token and check the "exp" claim)
    // Return true if expired, false otherwise
}

// Refresh the access token using the refresh token
export async function refreshAccessToken() {
  try {
    const axiosInstance = axios.create({
      withCredentials: true,
    });
    await axiosInstance.post("https://rapidbill.in/api/refresh-token", {}, { withCredentials: true });
    // const { accessToken, expiresIn } = response.data;
    
    setAccessToken(1800);
    return true;
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    return false;
  }
}

  // Handle API responses to check for token expiration
export function handleApiError(error, navigate) {
    

  console.log('Handle API error function called... REMOVE IF NOT REQUIRED');
  
    // if (error.response && (error.response.status === 401 || error.response.status === 500)) {

    //   if (error.response.data.message === "jwt expired") {
    //     logoutUser(navigate);
    //   }


    //   if (error.response.status === 401) {
    //     logoutUser(navigate);
        
    //   }

    
    //   // Token has expired, log the user out
      
      
    // }
}

 //Handle delete cookie
export function deleteCookie(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

  // Logout the user
export async function logoutUser(navigate) {
  try {
    const axiosInstance = axios.create({
      withCredentials: true,
    });
    await axiosInstance.post("https://rapidbill.in/api/logout");
    // const { accessToken, expiresIn } = response.data;   
    console.log("Session Logout");
    localStorage.removeItem('sellerGstin');
    localStorage.removeItem('userid');
    localStorage.removeItem('seller_id');
    localStorage.removeItem('accessTokenExpiration');
    navigate('/login');
    return true;
  } catch (error) {
    console.error("Failed to LOGOUT:", error);
    return false;
  }
    
    // deleteCookie('refreshToken');
    // deleteCookie('accessToken');
  
    // Redirect the user to the login page using the passed-in navigate function
    
}