import React, { useEffect, useState } from 'react';
import "../../Generate.css"
import axiosInstance from '../../api';
import Select from 'react-select';
import '../CustomDropdown/portCodeDropdown.css'




const  Selecter = (props) => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const userId = localStorage.getItem('userid');
  const Label = props.Label;



  useEffect(() => {
    // Fetch data from your API here
    // const objectIdUserId = userId;
    //  console.log(userId);
    fetchDataFromApi();
  }, [userId]);


  const fetchDataFromApi = async () => {
    try {

      const response = await axiosInstance.get(`/${props.APILink}/${userId}`); // Use Axios to make the GET request
      
      setData(response.data);
      // setSellerGStin(response.data.GSTIN);
      // console.error('seller data is :', response.data);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
    //   handleApiError(error, navigate)
    }
  };
  

  const options = data.map(item => ({
    value: item._id,
    label: item[Label],
  }));

  const handleSelectChange = (selectedOption) => {
    setSelectedItem(selectedOption); // Set to full option object
    props.onSelect(selectedOption ? selectedOption.value : '', props.Index);
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 27, // Set a specific height
      minHeight: 27, // Set a minimum height
      padding: '0', // Remove all padding
        // lineHeight: '10px', // Set line height to match height for vertical alignment
        fontSize: '12px',
        marginTop: '3px',
    //   fontSize: '12px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '12px',
      padding: '0px', // Decrease font size
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '12px', // Decrease font size for dropdown
    }),
    option: (provided) => ({
      ...provided,
      padding: 8, // Decrease padding for options
      fontSize: '12px', // Decrease font size for options
    }),
  };

  const selectedOption = options.find(option => option.value === props.selectedOne);
  
  return (
//     <select id="state" value={selectedItem} disabled = {props.disabledProp} onChange={handleSelectChange} required={props.requiredProp}>
//     <option value="" disabled hidden>
//       Select a {props.selecterName} 
//     </option>
//     {data.map((item, index) => (
//       <option key={index} value={item._id}>
//         {item.props.optionLabel}
//       </option>
//     ))}
//   </select>

  <Select
  name={props.name}
  options={options}
  isDisabled={props.disabledProp}
  onChange={handleSelectChange}
  // onChange={(selectedOption) => props.onSelect(selectedOption ? selectedOption.value : '')}
  placeholder= {`Select ${props.selecterName}`} 
  isSearchable
  styles={customStyles}
  value={selectedItem}
  required={props.requiredprop}
/>
  );
};

export default Selecter;
