import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
      <div className="footer-company">
          <p>&copy; {new Date().getFullYear()} Rapid Technologies Private Limited.</p>
          <div className="footer-links">
          <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
          <span className="footer-divider">|</span>
          <a href="/terms-conditions" className="footer-link">Terms & Conditions</a>
        </div>
        </div>
        
        
      </div>
    </footer>
  );
};

export default Footer;
