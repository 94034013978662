import {React, useState, useEffect} from 'react'
import './Masterforms.css'
import { SlPeople } from 'react-icons/sl';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import axiosInstance from '../../api';


function AddCustomer() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);
  const defaultCustomerType = 'Regular';

  const [formData, setFormData] = useState({
    CustomerType: defaultCustomerType,
    Gstin: '',
    Tradename: null,
    LegalName: null,
    Address1: null,
    Address2: null,
    City: null,
    pinCode: null,
    State: null,
    phone: null,
    email: null,
    author: userId,  
  });

  const {CustomerType, Gstin, Tradename, LegalName, Address1, Address2, City, pinCode, phone, email,  } = formData;

  useEffect(() => {
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    }); // Adjust the time as needed
  }, [setProgress]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setFormData({
      ...formData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(formData);
    

    try {
      setShowAlert(false);
      const response = await axiosInstance.post('/addCustomer', formData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Customer Added successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?success=customer added successfully!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Customer added failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    
  

  
    // Make API requests or perform other actions here
  };

  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };

    return(
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Seller Added!' : 'Seller Failed'}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<SlPeople></SlPeople>} Add New Customer</h4><button type="button" className="btn  btn-primary imprtgstin">Import Details from GSTIN</button>
        <div className='AddDiv'><form className="row g-3" onSubmit={handleSubmit}>
<div className="col-md-2">
    <label for="inputState" className="form-label">Customer Type:</label>
    <select id="inputState" name='CustomerType' value={CustomerType} onChange={handleChange} className="form-select Addmasterform">
      <option className='option' selected>Regular</option>
      <option className='option'>Unregistered</option>
      <option className='option'>Consumer</option>
      <option className='option'>Overseas</option>
      <option className='option'>Special Economic Zone</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">GSTIN:</label>
    <input type="text" name='Gstin' value={Gstin} onChange={handleChange} className="form-control Addmasterform" id="inputPassword4" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label" >Trade Name:</label>
    <input type="text" name='Tradename' value={Tradename} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress2" className="form-label">Legal Name:</label>
    <input type="text" name='LegalName' value={LegalName} onChange={handleChange} className="form-control Addmasterform" id="inputAddress2" required/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 1:</label>
    <input type="text" name='Address1' value={Address1} onChange={handleChange} className="form-control Addmasterform" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 2:</label>
    <input type="text" name='Address2' value={Address2} onChange={handleChange} className="form-control Addmasterform" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">City:</label>
    <input type="text" name='City' value={City} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Pincode:</label>
    <input type="text" name='pinCode' value={pinCode} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">State:</label>
    <StateDropdown selectedState={formData.State || ""} onSelect={(value) => handleDropdownChange(value)}  />
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Phone:</label>
    <input type="number" name='phone' value={phone} onChange={handleChange} className="form-control Addmasterform" id="inputZip" />
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Email:</label>
    <input type="email" name='email' value={email} onChange={handleChange} className="form-control Addmasterform" id="inputZip" />
  </div>
  <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" class="btn  btn-dark"  onClick={handleCancelButton} id="cancelinvbtn">Cancel</button></div>
</form></div>
        
            
        </>

    )
    
}

export default AddCustomer;