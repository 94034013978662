import {React, useState} from 'react'
import './Masterforms.css'
import { AiOutlineUserAdd } from 'react-icons/ai';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { Link,useNavigate } from 'react-router-dom';
import TopLoadingBar from 'react-top-loading-bar';
import axiosInstance from './../../api';




function AddUserRole(){
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);

  const [isChecked, setIsChecked] = useState({
    can_generate: false,
    can_cancel: false,
    can_add: false,
    can_edit: false,
    to_download: false
  })
  const value = isChecked ? 'Y' : 'N';

  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Username: '',
    password: '',
    can_generate: 'N',
    can_cancel: 'N',
    can_add: 'N',
    can_edit: 'N',
    to_download: 'N',
    author: userId, 
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setIsChecked({ ...isChecked, [name]: checked });
    setFormData({
      ...formData,
      [name]: checked ? 'Y' : 'N',
    });
  };

  const {Name, Email, Username, password, can_generate, can_cancel, can_add, can_edit, to_download, author   } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setFormData({
      ...formData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(formData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.post('/addRole', formData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Seller Added successful:', response.data);
      setAlertVariant('success');

      navigate('../Setting?UserAdded=Data added successfully!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Seller added failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    
  

  
    // Make API requests or perform other actions here
  };
  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };

    return(
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'User Added!' : 'Failed to add User'}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<AiOutlineUserAdd></AiOutlineUserAdd>}Add New User</h4>
        <div className='AddDiv'>
            <form onSubmit={handleSubmit} className="row g-3">
              
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Name:</label>
    <input name='Name' value={Name} onChange={handleChange} type="text" className="form-control Addmasterform" id="inputPassword4" required/>
  </div>
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Email.:</label>
    <input name='Email' value={Email} onChange={handleChange} type="Email" className="form-control Addmasterform" id="inputPassword4" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label" >Username:</label>
    <input name='Username' value={Username} onChange={handleChange} type="text" className="form-control Addmasterform" id="inputAddress" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label" >Password:</label>
    <input name='password' value={password} onChange={handleChange} type="text" className="form-control Addmasterform" id="inputAddress"required/>
  </div>
  <div className='userRolepermissiondiv'>

<div className="col-md-2 userroleCol">
  <div className="form-check form-switch" id='igstonintraswitch'>
  <input className="form-check-input" name="can_generate" checked={isChecked.can_generate} onChange={handleCheckboxChange} type="checkbox" role="switch" id="flexSwitchCheckDefault1"/>
  <label className="form-check-label userRolePermLabel" for="flexSwitchCheckDefault">1. Can generate E-invoice</label>
  </div></div>
  <div className="col-md-2 userroleCol">
  <div className="form-check form-switch" id='igstonintraswitch'>
  <input className="form-check-input" name="can_cancel" checked={isChecked.can_cancel} onChange={handleCheckboxChange} type="checkbox" role="switch" id="flexSwitchCheckDefault2"/>
  <label className="form-check-label" for="flexSwitchCheckDefault">2. Can Cancel E-invoice</label>
  </div></div>
  <div className="col-md-2 userroleCol">
  <div className="form-check form-switch" id='igstonintraswitch'>
  <input className="form-check-input" name="can_add" checked={isChecked.can_add} onChange={handleCheckboxChange} type="checkbox" role="switch" id="flexSwitchCheckDefault3"/>
  <label className="form-check-label" for="flexSwitchCheckDefault">3. Can Add New Invoice</label>
  </div></div>
  <div className="col-md-2 userroleCol">
  <div className="form-check form-switch" id='igstonintraswitch'>
  <input className="form-check-input" name="can_edit" checked={isChecked.can_edit} onChange={handleCheckboxChange} type="checkbox" role="switch" id="flexSwitchCheckDefault4"/>
  <label className="form-check-label" for="flexSwitchCheckDefault">4. Can Edit</label>
  </div></div>
  <div className="col-md-2 userroleCol">
  <div className="form-check form-switch" id='igstonintraswitch'>
  <input className="form-check-input" name="to_download" checked={isChecked.to_download} onChange={handleCheckboxChange} type="checkbox" role="switch" id="flexSwitchCheckDefault5"/>
  <label className="form-check-label" for="flexSwitchCheckDefault">5. Allow to download</label>
  </div></div>
</div>

{/* <h4 className='MasterFormTitle'> Give Permission</h4> */}


<div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div></form>
</div>
        
            
        </>

    )
    
}

export default AddUserRole;