import React from 'react'
import './ListContent1.css'

function ListContent() {

    return(
<div className='listcontent'>
        </div>
    )
    
}

export default ListContent;