import {React, useState} from 'react'
import './Masterforms.css'
import { SlPeople } from 'react-icons/sl';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import { MdOutlinePriceCheck } from 'react-icons/md';
import axiosInstance from './../../api';


function AddOtherCharges() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);

  const [isChecked, setIsChecked] = useState(false);
  const value = isChecked ? 'Y' : 'N';

  const [formData, setFormData] = useState({
    Taxable: null,
    ItemDescription: null,
    HSN_SAC_Code: null,
    Taxrate: null,
    Amount: null,
    author: userId,  
  });

  const handleCheckboxChange = () => {
    // Toggle the checked state
    setIsChecked(!isChecked);
    
    // Update the Isservice field in formData
    setFormData({
      ...formData,
      Isservice: isChecked ? 'N' : 'Y', // Reverse the value when the checkbox is checked or unchecked
    });
  };

  const {Taxable, ItemDescription, HSN_SAC_Code, Taxrate , Amount, author} = formData

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setFormData({
      ...formData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(formData);
    

    try {
      setShowAlert(false);
    
      const response = await axiosInstance.post('/addcharges', formData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Othar Charge Added successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?OthchrgAdded=Otherchrg added successfully!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Other Charge added failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    // Make API requests or perform other actions here
  };

  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };

    return(
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Other Charge Added!' : 'Failed to add Other charge'}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<MdOutlinePriceCheck></MdOutlinePriceCheck>} Add Other Charges</h4>
        <div className='AddDiv'>
        <form onSubmit={handleSubmit}>

            <table className="table table-sm">
    <thead>
      <tr>
        {/* <th>Sl No.</th> */}
        <th>Taxable?</th>
        <th>Item Description</th>
        <th>Tax Rate</th>
        <th>HSN/SAC Code</th>
        <th>Amount</th>
        {/* <th>Action</th> */}
      </tr>
    </thead>
    <tbody>
      <tr>
        {/* <td>1.</td> */}
        <td><select id="inputState" name='Taxable' value={Taxable} onChange={handleChange} className="form-select Addmasterform">
        <option value="">Is taxable</option>
        <option value="Y">Yes</option>
        <option value="N">No</option>
    </select></td>
        <td><input className="form-control itemdesc Addmasterform" type="text" name='ItemDescription' value={ItemDescription} onChange={handleChange} required/></td>
        <td><select id="inputState" name='Taxrate' value={Taxrate} onChange={handleChange} className="form-select Addmasterform">
        <option value="0" selected>0</option>
        <option value="0.1">0.1</option>
        <option value="0.25">0.25</option>
        <option value="1">1</option>
        <option value="1.5">1.5</option>
        <option value="3">3</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7.5">7.5</option>
        <option value="12">12</option>
        <option value="18">18</option>
        <option value="28">28</option>
    </select></td>
        <td><input className="form-control itemhsn Addmasterform" type="text" name='HSN_SAC_Code' value={HSN_SAC_Code} onChange={handleChange} /></td>
        <td><input className="form-control itemprice Addmasterform" type="text" name='Amount' value={Amount} onChange={handleChange}/></td>
    {/* <td><h4 className='dltitem'>x</h4></td> */}


      </tr>
    </tbody>
    
        </table>
        <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
        </form>
        </div>
        
        
    
        </>

    )
    
}

export default AddOtherCharges;