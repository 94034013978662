import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import TopLoader from '../TopLoader/TopLoader';
import axiosInstance from '../../api';
import './header1.css'
import './stockHeader.css'


function Header1(props) {


  const [showAlert, setShowAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  const [alertVariant, setAlertVariant] = useState('success');

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;

  const userId = localStorage.getItem('userid');

  const [selectedItems, setSelectedItems] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDataFromApi();
  }, [userId]);


  const fetchDataFromApi = async () => {
    try {
 
      const response = await axiosInstance.get(`/getCharges/${userId}`); // Use Axios to make the GET request
      
      setData(response.data);
      // setSellerGStin(response.data.GSTIN);
      console.log('Other charge data is :', response.data);
      setLoading(false);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
      handleApiError(error, navigate)
    }
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Create a function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the start and end index for the items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the items to display on the current page
  const itemsToDisplay = data.slice(startIndex, endIndex);

  // Determine the number of page numbers to display
  const maxPageNumbers = 3;

  const toggleCheckbox = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const isItemChecked = (item) => selectedItems.includes(item);

  const toggleSelectAll = () => {
    if (selectedItems.length === itemsToDisplay.length) {
      setSelectedItems([]);
      
    } else {
      setSelectedItems(itemsToDisplay.map((item) => item._id));
    }
  };

  const deleteItems = async () => {

    if (selectedItems.length > 0) {
      // Redirect to the edit page with the selected item's ID
      try {
        // Extract selected item IDs
        
        const itemIds = selectedItems.map((item) => item);
        console.log(itemIds);
  
        // Send a DELETE request to your API with the selected item IDs

        await axiosInstance.delete(`/deletecharges`, {
          data: { ids: itemIds }, // Pass the selected item IDs as data
        });
  
        // After successful deletion, refresh the data
        fetchDataFromApi();
        console.log("Customer has been deleted successfully");
      setAlertMessage('Deleted Successfully')
      setAlertVariant('success');
      setShowAlert(true);
        // Clear the selected items
        setSelectedItems([]);
      } catch (error) {
        console.error('Error deleting items:', error);
        handleApiError(error, navigate);
      }
    } else {
      // You can provide a user-friendly error message if no item or more than one item is selected.
      console.log('Please select item to delete.');
      setAlertMessage('Please select item to delete.')
      setAlertVariant('warning');
      setShowAlert(true);
    }

    
  };

  const editItem = () => {
    // Check if only one item is selected for editing
    if (selectedItems.length === 1) {
      // Redirect to the edit page with the selected item's ID
      navigate(`/App/EditOtherCharge/${selectedItems[0]}`);
    } else {
      // You can provide a user-friendly error message if no item or more than one item is selected.
      console.log('Please select one item to edit.');
      setAlertMessage('Please select one item to edit')
      setAlertVariant('warning');
      setShowAlert(true);
    }
  };

    return(

        <>
        {loading ? (
      <TopLoader />
    ) : (
        <>
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? alertMessage : alertMessage}
        onClose={() => setShowAlert(false)}
        />
      )}
        <div className='btnsDiv'>
        <div><Link to='/App/AddOtherCharges'><button type="button" class="btn btn-primary btn-sm" id='adsellerbtn'>Add New Other charges</button></Link></div>
        <div><button onClick={editItem} type="button" className="btn btn-primary" id='editbtn'>Edit</button></div>
        <div><button onClick={deleteItems}  type="button" className="btn btn-primary" id='editbtn'>Delete</button></div>
 </div>
      <div>{props.tab4c}</div>
        <div className='tablediv'>
        <hr className="my-4 mb-0 mt-2"></hr>
        <div className="table-responsive">
        
        <table className="table table-sm " id='masterTable'>
      
    <thead>
      <tr>
        <th style={{width: "100px"}}><div className="form-check">
  <input className="form-check-input" onChange={toggleSelectAll} checked={itemsToDisplay.length > 0 && selectedItems.length === itemsToDisplay.length} type="checkbox" value="" id="flexCheckChecked" />
</div></th>
        <th style={{width: "200px"}}>{props.c1}</th>
        <th style={{width: "200px"}}>{props.c2}</th>
        <th style={{width: "200px"}}>{props.c3}</th>
        <th >{props.c4}</th>    

      </tr>
    </thead>
  </table>
  </div>

        <div className="table-responsive" id='tableBodyDiv'>
        
        <table className="table table-hover table-sm " id='masterTable'>
<tbody >
  
    {itemsToDisplay.map((item, index) => (
                <tr key={index}
                onClick={() => toggleCheckbox(item._id)}
                className={isItemChecked(item._id) ? 'selected-row' : ''}>
                  <td style={{width: "100px"}}>
                    <div className="form-check">
                      <input className="form-check-input" checked={isItemChecked(item._id)} onChange={() => toggleCheckbox(item._id)} type="checkbox" value="" id="flexCheckChecked" />
                    </div>
                  </td>
                  <td style={{width: "200px"}}>{item.ItemDescription}</td>
                  <td style={{width: "200px"}}>{item.Taxable}</td>
                  <td style={{width: "200px"}}>{item.HSN_SAC_Code}</td>
                  <td>{item.Taxrate}</td> 
                </tr>
              ))}
    </tbody>
  </table>
  </div>

  <div>
          <ul className="pagination">
            {currentPage > 1 && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  &laquo;
                </button>
              </li>
            )}

            {Array.from({ length: totalPages > maxPageNumbers ? maxPageNumbers : totalPages }, (_, i) => (
              <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(i + 1)}>
                  {i + 1}
                </button>
              </li>
            ))}

            {currentPage < totalPages && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  &raquo;
                </button>
              </li>
            )}
          </ul>
        </div>
  
  
  </div>
  </>
        )}
        </>
    )
    
}


export default Header1;