import React from 'react'
import Navbar from '../Components/LandingPage/Navbar';
import './Signup.css';
import { Link,useNavigate } from 'react-router-dom';
import { useState, } from 'react';
import StateDropdown from '../Components/CustomDropdown/StateDropdown';
import axios from 'axios';
import Alert from '../Components/CustomAlert/Alert1';
import { handleApiError } from '../Authentication/AuthService';
import Footer from '../Components/CustomFooter/footer';
import TopLoadingBar from 'react-top-loading-bar';
import axiosInstance from '../api';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


function Signup() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
    
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

  const [formData, setFormData] = useState({
    fullName: '',
    UserName: '',
    email: '',
    password: '',
    phonenumber: '',
    companyName: '',
    gstinNumber: '',
    PanNumber: '',
    address1: '',
    address2: '',
    city: '',
    pincode: '',
    state: '',
  });

  const { fullName, UserName, email, password, phonenumber, companyName, gstinNumber, PanNumber,address1,
  address2, city, pincode, state } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleDropdownChange = (value) => {
    // Update the selected state in the form data
    setFormData({
      ...formData,
      state: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(formData);

    try {
      setShowAlert(false);
      const axiosInstance = axios.create({
        withCredentials: true,
      });  
      const response = await axiosInstance.post('https://rapidbill.in/api/Signup', formData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Signup successful:', response.data);
      setAlertVariant('success');
      setFormData([]);
      navigate('/login?Registered=signup success!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Signup failed:', error.response.data);
      setAlertVariant('danger');
      setAlertMessage(error.response.data.message)

      handleApiError(error)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    setShowAlert(true);
  
    // Make API requests or perform other actions here
  };

return(

<>
<TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
<Navbar title = "Rapid Invoice" aboutText = "About us" hidden = {true}/>/

<div className='mainSignup'>

<h1 className='headersignup'>Create an account</h1>
{showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? alertMessage : alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
    <main className="form-signin w-100 m-auto">
  <form className='SignUpFormDiv' onSubmit={handleSubmit}>
    <div className='SignUpChildDiv'>
    <div className='PersonalDetailSection'>
    <h1 className='SectionHeading'>Personal Details</h1>
    {/* <img className="mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
    {/* <h1 className="h3 mb-3 fw-normal">Please sign in</h1> */}
<div className='PersonalDetailDiv'>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='fullName' value={fullName} onChange={handleChange} className="SignupFormControl form-control" id="floatingInput" placeholder="Full Name" required/>
      <label for="floatingInput" className='SignupFloatingInput'>Full Name</label>
    </div>
    <div className="SignUpFormFloating form-floating">
      <input type="email" name='email' value={email} onChange={handleChange} className="SignupFormControl form-control" id="floatingPassword" placeholder="email@gmail.com" required/>
      <label for="floatingPassword" className='SignupFloatingInput'>Email</label>
    </div></div>
    <div className='PersonalDetailDiv'>
    <div className="SignUpFormFloating form-floating">
      <input type="number" name='phonenumber' value={phonenumber} onChange={handleChange} className="SignupFormControl form-control" id="floatingInput" placeholder="name@example.com" required/>
      <label for="floatingInput" className='SignupFloatingInput'>Phone Number</label>
    </div>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='UserName' value={UserName} onChange={handleChange} min={5} max={30} className="SignupFormControl form-control" id="floatingPassword" placeholder="Password" required/>
      <label for="floatingPassword" className='SignupFloatingInput'>Username</label>
    </div></div>
    <div className='PersonalDetailDiv'>
    <div className="SignUpFormFloating form-floating">
      <input type={showPassword ? 'text' : 'password'} name='password' value={password} onChange={handleChange} className="SignupFormControl form-control" id="floatingInput" placeholder="Passwords" minLength={5} required/>
      <label for="floatingInput" className='SignupFloatingInput'>Password</label>
      <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </span>
    </div>
    <div className="SignUpFormFloating form-floating">
      <input type={showPassword ? 'text' : 'password'} className="SignupFormControl form-control" id="floatingPassword" placeholder="Password" minLength={5} required/>
      <label for="floatingPassword" className='SignupFloatingInput'>Confirm Password</label>
      <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </span>
    </div></div>
    </div>
    <div className='PersonalDetailSection'>
    <h1 className='SectionHeading'>Company Details</h1>
    {/* <img className="mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
    {/* <h1 className="h3 mb-3 fw-normal">Please sign in</h1> */}
<div className='PersonalDetailDiv'>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='companyName' value={companyName} onChange={handleChange}  className="SignupFormControl form-control" id="floatingInput" placeholder="FullName" required/>
      <label for="floatingInput" className='SignupFloatingInput'>Company Name</label>
    </div>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='gstinNumber' value={gstinNumber} onChange={handleChange} className="SignupFormControl form-control" id="floatingPassword" placeholder="email@gmail.com" minLength={15} maxLength={15} required/>
      <label for="floatingPassword" className='SignupFloatingInput'>GSTIN Number</label>
    </div></div>
    <div className='PersonalDetailDiv'>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='PanNumber' value={PanNumber} onChange={handleChange} className="SignupFormControl form-control" id="floatingInput" placeholder="name@example.com" required/>
      <label for="floatingInput" className='SignupFloatingInput'>PAN Number</label>
    </div>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='address1' value={address1} onChange={handleChange} className="SignupFormControl form-control" id="floatingPassword" placeholder="Password" required/>
      <label for="floatingPassword" className='SignupFloatingInput'>Address 1</label>
    </div></div>
    <div className='PersonalDetailDiv'>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='address2' value={address2} onChange={handleChange} className="SignupFormControl form-control" id="floatingInput" placeholder="name@example.com"/>
      <label for="floatingInput" className='SignupFloatingInput'>Address 2</label>
    </div>
    <div className="SignUpFormFloating form-floating">
      <input type="text" name='city' value={city} onChange={handleChange} className="SignupFormControl form-control" id="floatingPassword" placeholder="Password" required/>
      <label for="floatingPassword" className='SignupFloatingInput'>City</label>
    </div></div>
    <div className='PersonalDetailDiv'>
    <div className="SignUpFormFloating form-floating">
      <input type="number" name='pincode' value={pincode} onChange={handleChange} className="SignupFormControl form-control" id="floatingInput" placeholder="name@example.com" minLength={6} required/>
      <label for="floatingInput" className='SignupFloatingInput'>Pincode</label>
    </div>
    <div className="SignUpFormFloating form-floating">

    <StateDropdown selectedState={formData.state || ""} onSelect={(value) => handleDropdownChange(value)}  required/></div>
    </div>
    </div>
    <div className='submitBtnDiv'><button id = "SignupBtnClick" className="btn btn-lg btn-primary" type="submitsignup">Sign up</button>
    <Link className="nav-link" to="/Login">{<p className='signinlink'>Sign in</p>}</Link>
    {/* <p className="mt-5 mb-3 text-muted">&copy; 2017–2022</p> */}</div>
    </div>
  </form>
</main>
    </div>
    <Footer/>
    </>

    )
    
}

export default Signup;