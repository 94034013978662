import { useState,React, useEffect } from 'react'
import './CustomNav.css'
import { GiQueenCrown } from 'react-icons/gi';
import { Link,useNavigate } from 'react-router-dom';
import { FaBars, FaFontAwesome, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import { logoutUser } from '../../Authentication/AuthService';
import axios from 'axios';
import axiosInstance from '../../api';
import TopLoadingBar from 'react-top-loading-bar';
import { async } from './../../Authentication/AuthService';

function CustomNav() {

    const [SelectedSeller, setselectedSeller] = useState(localStorage.getItem('sellerGstin'));
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [image, setImage] = useState(null);
    const [isActive, setisActive] = useState(false)
    if (SelectedSeller === null) {
        setselectedSeller("Please select seller")
          
    }

    useEffect(() => {
      const fetchProfileImage = async () => {
          try {
              const response = await axiosInstance.get('/profile-image', {
                  responseType: 'blob',
                  withCredentials: true, // Ensure cookies are sent if using auth
              });
  
              const imageUrl = URL.createObjectURL(response.data);
              setImage(imageUrl);
          } catch (error) {
              console.error('Error fetching profile image:', error);
          }
      };
  
      fetchProfileImage();
  }, []);

  async function logOut() {
    setProgress(30);
    await logoutUser(navigate);
    setProgress(100);
};
const settingClick = () => {

        navigate('./Setting');
  
        // navigate('./Setting?tab=set');
   
  };
  const subscriptionClick = () => {

    navigate('./Setting?tab=subscription');

};
const myAccountClick = () => {


    navigate('./Setting?tab=profile');

};
    

    return(
<>
<div className='customNav'>
<div className='container' id='customNavcon'>
{/* <!-- Example single danger button --> */}
<div className='element'><p id='selecterSellerText'>Selected Seller GSTIN : {SelectedSeller}</p></div> 
<div className='element'>
    </div>   
<div className='element'>
<div className="dropdown">
  <img className='dpicon' data-bs-toggle="dropdown"  src={image || '/nodp.png'} aria-expanded="false" alt="" />
  <ul className="dropdown-menu">
    {/* <li><button className="dropdown-item" >Generate e-way</button></li>
    <li><button className="dropdown-item" >Cancel e-invoice</button></li> */}
    <li><button className="dropdown-item" onClick={myAccountClick}>My account</button></li>
    <li><button className="dropdown-item" >Contact support team </button></li>
    <li><button className="dropdown-item" onClick={subscriptionClick} >Subscription plan</button></li> 
    <li><button className="dropdown-item" onClick={settingClick} >Settings</button></li> 
    <li><hr class="dropdown-divider"/></li>
    <li><button className="dropdown-item" onClick={logOut} >Log Out</button></li>
  </ul>
</div>

</div>

</div>

</div>
<TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />



</>

    )
    
}

export default CustomNav;