import React from 'react'

import './itemdtl.css'
import GenerateFormTitle from '../../Components/NotUsable/GenerateFormTitle';
import {MdOutlineExpandCircleDown } from "react-icons/md";

function Documentdtl() {

return(
<div className='customerdtlDiv'>
<div className='Itemtablediv'>
    {/* <hr className="my-4  mb-0 mt-2"></hr> */}
        <div className="table-responsive">
        <table className="table table-sm">
   <thead>
      <tr>
        <th>Sl No.</th>
        <th>Item Name</th>
        <th>Item Description</th>
        <th>Is Service</th>
        <th>HSN Code</th>
        <th>QTY</th>
        <th>Price</th>
        <th>Unit</th>
        <th>Discount</th> 
        <th>Tax Rate</th>
        <th>Taxable Value</th> 
        <th>Action</th> 
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1.</td>
        <td><input className="form-control itemnm" type="text" readonly/></td>
        <td><input className="form-control itemdesc" type="text" readonly/></td>
        <td><div className="form-check form-switch" id=''>
  <input className="form-check-input" style={{ width: '100%' }} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  </div></td>
        <td><input className="form-control itemhsn" type="text" readonly/></td>
        <td><input className="form-control itemqty" type="text" readonly/></td>
        <td><input className="form-control itemprice" type="text" readonly/></td>

        <td><select id="inputState" className="form-select itemunit">
      <option className='option' selected>OTH</option>
      <option className='option'>MTR</option>
    </select></td>

        <td><input className="form-control itemdiscount" type="text" readonly/></td>

        <td><select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select></td>

    <td><input className="form-control itemdiscount" type="text" readonly/></td>
    <td><h4 className='dltitem'>x</h4></td>


      </tr>
      <tr>
        <td>1.</td>
        <td><input className="form-control itemnm" type="text" readonly/></td>
        <td><input className="form-control itemdesc" type="text" readonly/></td>
        <td><div className="form-check form-switch" id=''>
  <input className="form-check-input" style={{ width: '100%' }} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  </div></td>
        <td><input className="form-control itemhsn" type="text" readonly/></td>
        <td><input className="form-control itemqty" type="text" readonly/></td>
        <td><input className="form-control itemprice" type="text" readonly/></td>

        <td><select id="inputState" className="form-select itemunit">
      <option className='option' selected>OTH</option>
      <option className='option'>MTR</option>
    </select></td>

        <td><input className="form-control itemdiscount" type="text" readonly/></td>

        <td><select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select></td>

    <td><input className="form-control itemdiscount" type="text" readonly/></td>
    <td><h4 className='dltitem'>x</h4></td>


      </tr>
      <tr>
        <td>1.</td>
        <td><input className="form-control itemnm" type="text" readonly/></td>
        <td><input className="form-control itemdesc" type="text" readonly/></td>
        <td><div className="form-check form-switch" id=''>
  <input className="form-check-input" style={{ width: '100%' }} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  </div></td>
        <td><input className="form-control itemhsn" type="text" readonly/></td>
        <td><input className="form-control itemqty" type="text" readonly/></td>
        <td><input className="form-control itemprice" type="text" readonly/></td>

        <td><select id="inputState" className="form-select itemunit">
      <option className='option' selected>OTH</option>
      <option className='option'>MTR</option>
    </select></td>

        <td><input className="form-control itemdiscount" type="text" readonly/></td>

        <td><select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select></td>

    <td><input className="form-control itemdiscount" type="text" readonly/></td>
    <td><h4 className='dltitem'>x</h4></td>


      </tr>
    </tbody>
    
    </table></div></div>


    {/* <button type="button" class="btn  btn-dark" id="otherchrgbtn">Add Other Charges {<MdOutlineExpandCircleDown></MdOutlineExpandCircleDown>}</button> */}
    <GenerateFormTitle title="Additional Charges" />
    <div className='belowitemDiv'>

      <div className='otherchrgTableDiv'>
    {/* <hr className="my-4  mb-0 mt-2"></hr> */}
        <div className="table-responsive">
        <table className="table table-sm">
    <thead>
      <tr>
        <th>Sl No.</th>
        <th>Taxable?</th>
        <th>Item Description</th>
        <th>Tax Rate</th>
        <th>HSN/SAC Code</th>
        <th>Amount</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1.</td>
        <td><select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select></td>
        <td><input className="form-control itemdesc" type="text" readonly/></td>
        <td><select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select></td>
        <td><input className="form-control itemhsn" type="text" readonly/></td>
        <td><input className="form-control itemprice" type="text" readonly/></td>
    <td><h4 className='dltitem'>x</h4></td>


      </tr>
    </tbody>
    
        </table></div></div>

      <div className='totaldiv'>
        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Subtotal</p>
        <p className='totaltextsfont2'>0.00</p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Other Charges taxable</p>
        <p className='totaltextsfont2'>0.00</p></div>

        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Taxable Value</p>
        <p className='totaltextsfont2'>0.00</p></div>

        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>IGST</p>
        <p className='totaltextsfont2'>0.00</p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>CGST</p>
        <p className='totaltextsfont2'>0.00</p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>SGST</p>
        <p className='totaltextsfont2'>0.00</p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Cess</p>
        <p className='totaltextsfont2'><input className="form-control cessfield" type="text" readonly/></p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Other Charges</p>
        <p className='totaltextsfont2'>0.00</p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Less:Discount</p>
        <p className='totaltextsfont2'><input className="form-control cessfield" type="text" readonly/></p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Invoice Value</p>
        <p className='totaltextsfont2'>0.00</p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>TCS %</p>
        <p className='totaltextsfont2'>0.00</p></div>


        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Round Off</p>
        <p className='totaltextsfont2'><div className="form-check form-switch" id='roundoffswitch'>
  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
  </div></p></div>

        <div className='totaltextsDiv'>
        <p className='totaltextsfont1'>Total</p>
        <p className='totaltextsfont2'>0.00</p></div>



      </div>
    </div>

</div>
)
    
}

export default Documentdtl;
