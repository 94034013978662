import {React, useState, useEffect } from 'react'
import GenerateFormTitle from '../../Components/NotUsable/GenerateFormTitle';
import GenerateForm from '../../Components/NotUsable/GenerateForm';
import MasterTab from '../../Components/Dashboard/Headtitle'
import Button from '../../Components/ListHeader/button';
import Button2 from '../../Components/ListHeader/button2';
import Button3 from '../../Components/ListHeader/button3';
import CustomerHeader from '../../Components/ListHeader/customerHeader'
import Alert from '../../Components/CustomAlert/Alert1';
import StockHeader from '../../Components/ListHeader/stockHeader'
import TransporterHeader from '../../Components/ListHeader/transporterHeader'
import OthChrgHeader from '../../Components/ListHeader/othchrgHeader'
import BankHeader from '../../Components/ListHeader/bankHeader'
import { useNavigate } from 'react-router-dom'; 
import { useLocation,useParams,  } from 'react-router-dom';
import TopLoadingBar from 'react-top-loading-bar';



function InformMail() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('success');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const successMessage = queryParams.get('success');
  const CustomerEditedMsg = queryParams.get('EditedDone');
  const StockEditedMsg = queryParams.get('Stockedit');
  const stockAddedMessage = queryParams.get('StockAdded');
  const transAddedMessage = queryParams.get('TransporterAdd');
  const transEditedMessage = queryParams.get('TransporterEdit');
  const OthchrgAddedMessage = queryParams.get('OthchrgAdded');
  const OthchrgEditedMessage = queryParams.get('OthchrgEdit');
  const BankAddedMessage = queryParams.get('BankAdded');
  const BankEditedMessage = queryParams.get('BankEdit');
  const SettingTab = queryParams.get('tab');
  const [selectedTab, setSelectedTab] = useState('');
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (successMessage) {
      setAlertMessage('Customer Added!');
      setAlertVariant('success');
      setShowAlert(true);
    } else if (stockAddedMessage) {
      setSelectedTab('Stock Item');
      setAlertMessage('Stock Item Added!');
      setAlertVariant('success');
      setShowAlert(true);
    }
     else if (transAddedMessage) {
      setSelectedTab('Transporter');
      setAlertMessage('Transporter Added!');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (OthchrgAddedMessage) {
      setSelectedTab('Other Charges');
      setAlertMessage('OtherCharge Added!');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (BankAddedMessage) {
      setSelectedTab('Bank');
      setAlertMessage('Bank Added!');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (CustomerEditedMsg) {
      setAlertMessage('Customer Updated');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (StockEditedMsg) {
      setSelectedTab('Stock Item');
      setAlertMessage('Item Details Updated');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (OthchrgEditedMessage) {
      setSelectedTab('Other Charges');
      setAlertMessage('Other Charge Details Updated');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (transEditedMessage) {
      setSelectedTab('Transporter');
      setAlertMessage('Transport Details Updated');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (BankEditedMessage) {
      setSelectedTab('Bank');
      setAlertMessage('Bank Details Updated');
      setAlertVariant('success');
      setShowAlert(true);
    }
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });
  }, [successMessage, stockAddedMessage, transAddedMessage, OthchrgAddedMessage, BankAddedMessage]);

  const handleCloseAlert = () => {
    // Remove the query parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('EditedDone');
    searchParams.delete('success');
    navigate(location.pathname + '?' + searchParams.toString());
    // Close the alert
    setShowAlert(false);
  };
 

    return(

        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        {showAlert && (
        <div className="success-message">{<Alert
          variant={alertVariant}
          message={alertMessage}
          onClose={handleCloseAlert}  
          />}</div>
      )}
        <div>

        <MasterTab 

        selectedTab={selectedTab}
        
        tab1="Customer" 
        tab2="Stock Item" 
        tab3="Transporter" 
        tab4="Other Charges" 
        tab5= "Bank"
        
        // tab1btn = {<Button name = 'Add New Customer' link = '/App/AddCustomer' />}
        // tab1btn2 = {<Button2 name = 'Edit' link = '/App/AddCustomer'/>}
        // tab1btn3 = {<Button3 name = 'Delete' link = '/App/AddCustomer'/>}

        // tab2btn = {<Button name = 'Add New Stock Item' link = '/App/AddStockItem'/>}
        // tab2btn2 = {<Button2 name = 'Edit' link = '/App/AddCustomer'/>}
        // tab2btn3 = {<Button3 name = 'Delete' link = '/App/AddCustomer'/>}

        // tab3btn = {<Button name = 'Add New Transporter' link = '/App/AddTransporter' />}
        // tab3btn2 = {<Button2 name = 'Edit' link = '/App/AddCustomer'/>}
        // tab3btn3 = {<Button3 name = 'Delete' link = '/App/AddCustomer'/>}

        // tab4btn = {<Button name = 'Add New Other Charges' link = '/App/AddOtherCharges' />}
        // tab4btn2 = {<Button2 name = 'Edit' link = '/App/AddCustomer'/>}
        // tab4btn3 = {<Button3 name = 'Delete' link = '/App/AddCustomer'/>}

        // tab5btn = {<Button name = 'Add New bank' link = '/App/AddBank'/>}
        // tab5btn2 = {<Button2 name = 'Edit' link = '/App/AddCustomer'/>}
        // tab5btn3 = {<Button3 name = 'Delete' link = '/App/AddCustomer'/>}
        

        tab1c = {<CustomerHeader c1 ="GSTIN/UIN" c2="Trade Name" c3="Legal Name" c4="Email" c5="Work Phone" />}
        tab2c = {<StockHeader c1 ="Item Name" c2="Is Service" c3="Measurement Unit" />}
        tab3c = {<TransporterHeader c1 ="Transporter Gstin/ID" c2="Transporter Name" c3="Vehicle Number" c4 = "Vehicle Type" />}
        tab4c = {<OthChrgHeader c1 ="Description" c2="Taxable" c3="HSN/SAC" c4="Tax Rate"/>}
        tab5c = {<BankHeader c1 ="Bank Name" c2="Account Number" c3="Payee Name" c4 = "IFSC/BRANCH" />}
  
   
        ></MasterTab>
        
        
        </div>
        </>
    )
    
}

export default InformMail;