import React from 'react'

import './customerdtl.css'

function Dispatchdtl() {

return(
<div className='customerdtlDiv'>
<form className="row g-3">
<div className="col-md-2">
    <label for="inputState" className="form-label">Company Name:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Company Adddress:</label>
    <input type="password" className="form-control" id="inputPassword4"/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Company Address 2:</label>
    <input type="text" className="form-control" id="inputAddress" p/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Company Location:</label>
    <input type="text" className="form-control" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Company Pincode.:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Company State:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>

  
</form></div>
)
    
}

export default Dispatchdtl;
