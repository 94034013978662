import {React, useState, useEffect} from 'react'
import './Masterforms.css'
import { SlPeople } from 'react-icons/sl';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import { CiBank } from 'react-icons/ci';
import { useParams } from 'react-router-dom';
import TopLoader from '../TopLoader/TopLoader';
import axiosInstance from '../../api';




function EditBank() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);

  const [isChecked, setIsChecked] = useState(false);
  const { bankId } = useParams();
  const [loading, setLoading] = useState(true);

  const [BankData, setBankData] = useState({
    PayeeName: null,
    Payeeaccountno: null,
    NameofBank: null,
    Branchcode: null,
    Bankaddress: null,
    author: userId
  });


  const {PayeeName, Payeeaccountno, NameofBank, Branchcode, Bankaddress, author} = BankData

  useEffect(() => {
    fetchDataFromApi();
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });
  }, []);

  const fetchDataFromApi = async () => {
    try {
 
      const response = await axiosInstance.get(`/BankDtl/${bankId}`); // Use Axios to make the GET request
      
      const BankData = response.data || {}; // Ensure OtherChargeData is not null or undefined
        setBankData(BankData[0]);
      // setSellerGStin(response.data.GSTIN);
      console.log('OtherCharge data is :', response.data);
      console.log('OtherCharge data from OtherChargeData var :', BankData[0]);
      setLoading(false);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
      handleApiError(error, navigate)
      setLoading(false);
      setAlertMessage("Server could not responding")
      setAlertVariant("danger")
      setShowAlert(true)
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankData({ ...BankData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setBankData({
      ...BankData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(BankData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.put(`/BankDetails/${bankId}`, 
      
        BankData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Bank Edited successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?BankEdit=Bank edited successfully!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Bank edited failed:', error.response.data);
      setAlertVariant('danger');
      setAlertMessage('Server could not responding')

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    // Make API requests or perform other actions here
  };
  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };

    return(
        <>
    {loading ? (
      <TopLoader />
    ) : (
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Bank detail updated' : alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<CiBank></CiBank>} Edit Bank Detail</h4>
        <div className='AddDiv'>

        <form onSubmit={handleSubmit} className="row g-3"> 
      <div className="col-md-2">
        <label for="inputAddress" className="form-label">Payee Name:</label>
        <input type="text" name='PayeeName' value={BankData.PayeeName} onChange={handleChange}  className="form-control Addmasterform" id="inputAddress" />
      </div>
    
      <div className="col-md-2">
        <label for="inputAddress" className="form-label">Payee A/C Number:</label>
        <input type="text" name='Payeeaccountno' value={BankData.Payeeaccountno} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" required/>
      </div>
    
      <div className="col-md-2">
        <label for="inputState" className="form-label">Name of Bank:</label>
        <select id="inputState" name='NameofBank' value={BankData.NameofBank} onChange={handleChange} className="form-select Addmasterform" required>
        <option value="">Select a Bank</option>
  <option value="Bank of Baroda">Bank of Baroda</option>
  <option value="Bank of India">Bank of India</option>
  <option value="Bank of Maharashtra">Bank of Maharashtra</option>
  <option value="Canara Bank">Canara Bank</option>
  <option value="Central Bank of India">Central Bank of India</option>
  <option value="Indian Bank">Indian Bank</option>
  <option value="Indian Overseas Bank">Indian Overseas Bank</option>
  <option value="Punjab and Sind Bank">Punjab and Sind Bank</option>
  <option value="Punjab National Bank">Punjab National Bank</option>
  <option value="State Bank of India">State Bank of India</option>
  <option value="UCO Bank">UCO Bank</option>
  <option value="Union Bank of India">Union Bank of India</option>
  <option value="Axis Bank Ltd.">Axis Bank Ltd.</option>
  <option value="City Union Bank Ltd.">City Union Bank Ltd.</option>
  <option value="DCB Bank Ltd">DCB Bank Ltd</option>
  <option value="Federal Bank Ltd.">Federal Bank Ltd.</option>
  <option value="HDFC Bank Ltd.">HDFC Bank Ltd.</option>
  <option value="ICICI Bank Ltd.">ICICI Bank Ltd.</option>
  <option value="IDBI Bank Ltd.">IDBI Bank Ltd.</option>
  <option value="IDFC FIRST Bank Ltd">IDFC FIRST Bank Ltd</option>
  <option value="IndusInd Bank Ltd">IndusInd Bank Ltd</option>
  <option value="Jammu and Kashmir Bank Ltd.">Jammu and Kashmir Bank Ltd.</option>
  <option value="Karnataka Bank Ltd.">Karnataka Bank Ltd.</option>
  <option value="Karur Vysya Bank Ltd.">Karur Vysya Bank Ltd.</option>
  <option value="Kotak Mahindra Bank Ltd.">Kotak Mahindra Bank Ltd.</option>
  <option value="RBL Bank Ltd">RBL Bank Ltd</option>
  <option value="South Indian Bank Ltd.">South Indian Bank Ltd.</option>
  <option value="Yes Bank Ltd.">Yes Bank Ltd.</option>
  <option value="Dhanlaxmi Bank Ltd.">Dhanlaxmi Bank Ltd.</option>
  <option value="Bandhan Bank Ltd.">Bandhan Bank Ltd.</option>
  <option value="CSB Bank Ltd.">CSB Bank Ltd.</option>
        </select>
      </div>
    
      <div className="col-md-2">
        <label for="inputZip" className="form-label">Branch or IFSC Code.:</label>
        <input type="text" name='Branchcode' value={BankData.Branchcode} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
      </div>
      <div className="col-md-2">
        <label for="inputZip" className="form-label">Bank Address:</label>
        <input type="text" name='Bankaddress' value={BankData.Bankaddress} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
      </div>
      <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
    </form>
        </div>
        
            
        </>
        )}
</>

    )
    
}

export default EditBank;