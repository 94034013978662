import React from 'react'
import './Navbar.css';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types'


export default function Navbar(Props) {
  
  return (
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark fixed-top" id='nav1' >
  <div className = "container-fluid">
    <a className="navbar-brand" href="/">{Props.title}</a>  
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul hidden={Props.hidden} className="navbar-nav mr-auto">
      <li className="nav-item">
        <a className="nav-link" href="#">Use cases </a>
      </li>
      <li className="nav-item">
      <a className="nav-link" href="#">Blogs</a>
      </li>
      <li className="nav-item">
      <a className="nav-link" href="#">Pricing</a>
      </li>
      </ul>
      
      {/* </div>   */}
    </div>
  </div>
  <Link className="navloginbtn" hidden={Props.hidden} to="./Login">{<button type="button" className="btn btn-primary" id='navloginbtn'>Login</button>}</Link>
      <Link className="navsignupbtn" hidden={Props.hidden} to="./Signup">{<button type="button" className="btn btn-primary" id='navloginbtn'>Signup</button>}</Link>  
</nav> 
  )


}

Navbar.propTypes = {
    title: PropTypes.string
}