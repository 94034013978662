import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaHome, FaBlogger, FaLock, FaMoneyBill, FaUser, } from "react-icons/fa";
import {SlSocialFacebook} from "react-icons/sl"
import { MdEmail } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { FiSettings } from "react-icons/fi"; 
import { AiFillHeart, AiTwotoneFileExclamation,AiOutlineFileAdd, AiOutlineDatabase } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { SiWritedotas } from "react-icons/si";
import { useState } from "react";
import "../CustomNav/CustomNav.css"
import CustomNav from '../CustomNav/CustomNav';
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from './SidebarMenu'
import * as XLSX from 'xlsx';
import { useEffect } from 'react';
import { saveAs } from 'file-saver';
import SelectList from "../CustomDropdown/selectsellerDropdown";
import { CheckRequiredFieldAndDataformat, ValidateIfDocNumberSame } from "../../Script/ValidateImportExcel";

const routes = [

  {
    path: "/App/",
    name: "My Invoices",
    icon: <AiTwotoneFileExclamation />,
  },

  {
    path: "/Generate/settings",
    name: "Add New",
    icon: <AiOutlineFileAdd />,
    exact: true,
    subRoutes: [
      {
        path: "TaxInvoice?DocTyp=INV&SupTyp=B2B",
        name: "Tax Invoice",
        // icon: <FaUser />,
      },
      {
        path: "ExportInvoice?DocTyp=INV&SupTyp=EXPWOP",
        name: "Export Invoice",
        // icon: <FaLock />,
      },
      {
        path: "CreditNote?DocTyp=CRN&SupTyp=B2B",
        name: "Credit Note",
        // icon: <FaMoneyBill />,
      },
      {
        path: "DebitNote?DocTyp=DBN&SupTyp=B2B",
        name: "Debit Note",
        // icon: <FaLock />,
      },
      // {
      //   path: "B2cInvoice",
      //   name: "B2C Invoice",
      //   // icon: <FaMoneyBill />,
      // },
      {
        path: "#",
        name: "Import from Excel",
        // icon: <FaMoneyBill />,
      },
    ],
  },

  {
    path: "Masters",
    name: "Masters",
    icon: <AiOutlineDatabase />,
  },

  {
    path: "Setting",
    name: "Settings",
    icon: <FiSettings />,
  },


];

const selectStyle = {
  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  // fontWeight: 500,
  fontSize: '13px',
  height: '35px'  // Change the font size as needed
  // You can further customize other font properties like fontStyle, lineHeight, etc.
};
const SideBar = ({ children }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // Add the state variable for the modal
  const toggle = () => setIsOpen(!isOpen);
  const [jsonInvoice, setJsonData] = useState(null);
  const [invoices, setInvoices] = useState({});
  const [showExcelError, setshowExcelError] = useState([]);

  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const showAnimation2 = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.1,
      },
    },
  };


  //                              * CODE START FOR IMPORT INVOICE FROM EXCEL*

//This function is run after select excel file to import
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    readExcelFile(file);
  };


//This function is called by HANDLE FILE CHANGE FUNCTION TO EXTRACT , TO VALIDATE & TO STRUCTURE IN VALID FORMAT
  const readExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];

      const ExcelReceivedArray = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); console.log(ExcelReceivedArray);
      const headers = ExcelReceivedArray[1];
      const invoicesArray = ExcelReceivedArray.slice(2);
      const DuplicateInvoiceArray = [];
      
  //Step -1 This Function first check that in excel mandatory field is not empty & have valid data format
      const ExcelFirstValidation = CheckRequiredFieldAndDataformat(ExcelReceivedArray);
      const FirstValidationErrorList = ExcelFirstValidation[1];
      var ExcelSecondValidation = false;

      if (ExcelFirstValidation[0]) {
        
        console.log("First Validation Complete(Mandatory fields & Valid data format)");
        
        const length = invoicesArray.length;
        const ite = []

        // Code if document number same than add some more validation and to structure.
        for (let i = 0; i < length; i++) {
          
          const docNumber = invoicesArray[i][5];
          console.log(i);
          ite.push(invoicesArray[i])
          // Count the occurrences of the current document number
          let count = 1;
          // Loop through the rest of the array to check for additional occurrences
          for (let j = i + 1; j < length; j++) {
            if (invoicesArray[j][5] === docNumber) {
              //This function validate that if doc no. same than some other filds should be same.
              const SecondValidation = ValidateIfDocNumberSame(invoicesArray, i, j);
              ExcelSecondValidation  = SecondValidation[0];

              if (ExcelSecondValidation === true) {
                console.log("Second Validation Complete (Validation required if Document Number same)");
                // multipleItemInvoiceHandle();
                count++;
              }else{

                console.log(ExcelSecondValidation[1]);
                console.log("Second validation Failed");
              }
              
            }
          }
          
          // If the count is greater than or equal to 2, push all occurrences to DuplicateInvoiceArray
          if (count >= 2) {

            for (let j = i; j < i + count; j++) {
              
              const items = [];

              for (let index = 34; index < 68; index++) {


                
                items.push(invoicesArray[j][index])
                

              }
              DuplicateInvoiceArray.push(items);
              // console.log(i);

              // DuplicateInvoiceArray[0] = invoicesArray[j]

            }
          }
          // Skip the checked items in the next iteration
          i += count - 1;
          
          }

          console.log(ite);

          console.log(DuplicateInvoiceArray);


        // Function to manage multiple item invoices


          if (ExcelSecondValidation === true) {

            console.log("multipleItemInvoiceHandle function is running");
            console.log(DuplicateInvoiceArray);

            

            for (let index = 0; index < DuplicateInvoiceArray.length; index++) {
              const doc1 = DuplicateInvoiceArray[index][5];

              for (let index2 = index+1; index2 < DuplicateInvoiceArray.length; index2++) {

                
                if (DuplicateInvoiceArray[index2][5] = doc1) {
                  const itemList = []

                  itemList[index] = DuplicateInvoiceArray[index]
                  itemList[index2] = DuplicateInvoiceArray[index2]
                  console.log(itemList);



                
                }
                
              }
            
              
              
            }
            
            

            
          }
          

        
        // This function is for invoicesArray element map with excel headers and create object as key-value pair(*NOT REQUIRED*)
        const invoicesData = invoicesArray.map((row, rowIndex) => {
          const invoiceObj = {}
          headers.forEach((header, index) => {
          invoiceObj[header] = row[index];
          });
        return invoiceObj
          });
        setInvoices(invoicesData);

         // This function is run to beautify above invoicesData(*NOT REQUIRED*).   
        const normalizedinvoiceData = invoicesData.map((dataObject) => {
            const normalizedData = {};
            for (const key in dataObject) {
              const normalizedKey = key.replace(/["']/g, "").trim(); // Remove quotes and spaces from key
              normalizedData[normalizedKey] = dataObject[key];
            }
            return normalizedData;
          }); 
          // console.log(normalizedinvoiceData);
      
        
     }else{      
          setshowExcelError(FirstValidationErrorList);
          console.log("First validation Failed");
        }
      
    };
    reader.readAsArrayBuffer(file);
  };

 
//This function is run after select excel file that modal shall be open.
  const handleImportExcelClick = () => {
    setIsModalOpen(true);

  };

//This function is run when we click on close icon or button.
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };


//                              * CODE END FOR IMPORT INVOICE FROM EXCEL*
  

  return (
    <>
    <div className="main-container">
    {/* <p>{showExcelError[2].whichRow}</p> */}
    {/* <p>{showExcelError[2].whichMesage}</p>      */}
      {isModalOpen && (
        <>
      <div className="modal-backdrop fade show"/>
          <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Import Excel Template (building...)</h1>
                  <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
                </div>
                <div className="modal-body">
                <div>
      {/* <input type="file" accept=".xlsx, .xls" onChange={handleFileChange}  /> */}
    </div>
                </div>
                <div className="modal-footer">
                  <button type="button" class="btn  btn-dark" id="cancelinvbtn" onClick={handleCloseModal}>Cancel</button>
                  <button type="button" className="btn  btn-dark" id="saveinvbtn" disabled>Save</button>
                </div>
              </div>
            </div>
          </div></>
        )}
        <motion.div
          animate={{
            width: isOpen ? "280px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  Rapid Invoice
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars cursor-pointer">
              <FaBars onClick={toggle} />
            </div>
          </div>
          {/* <div className="searchs">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}
          <section className="routes">

          <AnimatePresence>
              {isOpen && (
                <motion.div
                  variants={showAnimation2}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo1"
                >
                  <SelectList />
                </motion.div>
              )}
            </AnimatePresence>




          
            {
            routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    key={index}
                    importClick={handleImportExcelClick}
                  />
                );
              }
              

              return (
                <>


                 <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  

                  
                  

                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink></>

              );
              })}
          </section>
          {/* <div className="sidebar-footer">
    {isOpen && (
      <>
      <p className="copyright">© {new Date().getFullYear()} MyGSTCafe. All rights reserved.</p>
      <div className="footerlinkDiv">
        <NavLink to="/privacy-policy" className="footer-link">
          Privacy Policy
        </NavLink>
        <span className="footer-divider2">|</span>
        <NavLink to="/terms-conditions" className="footer-link">
          Terms & Conditions
        </NavLink></div>
        
      </>
    )}
    
  </div> */}
        </motion.div>

        <main className="sidebarMain">

    <CustomNav></CustomNav>
    <div className='bottomline'></div>
          {children}</main>
      </div>
    </>
  );
};

export default SideBar;
