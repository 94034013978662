import React from 'react'

import './customerdtl.css'



function Contractdtl() {

return(
<div className='customerdtlDiv'>
<form className="row g-3">
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Vendor PO No.:</label>
    <input type="password" className="form-control" id="inputPassword4"/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Vendor PO Date:</label>
    <input type="text" className="form-control" id="inputAddress" p/>
  </div>
</form></div>
)
    
}

export default Contractdtl;
