import React, { useState } from 'react';
import './StateDropdown.css'
import Select from 'react-select';
import './portCodeDropdown.css'

const StateDropdown = ({name, onSelect, requiredProp, selectedState, disabledProp }) => {
  // const [selectedState, setSelectedState] = useState('');

  const indianStates = {
    'JAMMU AND KASMIR': '01',
    'HIMACHAL PRADESH': '02',
    'PUNJAB': '03',
    'CHANDIGARH': '04',
    'UTTARAKHAND': '05',
    'HARYANA': '06',
    'DELHI': '07',
    'RAJASTHAN': '08',
    'UTTAR PRADESH': '09',
    'BIHAR': '10',
    'SIKKIM': '11',
    'ARUNACHAL PRADESH': '12',
    'NAGALAND': '13',
    'MANIPUR': '14',
    'MIZORAM': '15',
    'TRIPURA': '16',
    'MEGHALAYA': '17',
    'ASSAM': '18',
    'WEST BENGAL': '19',
    'JHARKHAND': '20',
    'ORISSA': '21',
    'CHHATTISGARH': '22',
    'MADHYA PRADESH': '23',
    'GUJARAT': '24',
    'DAMAN AND DIU': '25',
    'DADAR AND NAGAR HAVELI': '26',
    'MAHARASTRA': '27',
    'KARNATAKA': '29',
    'GOA': '30',
    'LAKSHADWEEP': '31',
    'KERALA': '32',
    'TAMIL NADU': '33',
    'PONDICHERRY': '34',
    'ANDAMAN AND NICOBAR': '35',
    'TELENGANA': '36',
    'ANDHRA PRADESH': '37',
    'LADAKH': '38',
    'OTHER TERRITORY': '97',
    'OTHER COUNTRIES': '96'
  };

 
    const options = Object.keys(indianStates).map(state => ({
      value: indianStates[state],
      label: `${state} - ${indianStates[state]}`,
    }));

    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: 27, // Set a specific height
          minHeight: 27, // Set a minimum height
          padding: '0', // Remove all padding
            // lineHeight: '10px', // Set line height to match height for vertical alignment
            fontSize: '12px',
            marginTop: '3px',
        //   fontSize: '12px',
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: '12px',
          padding: '0px', // Decrease font size
        }),
        menu: (provided) => ({
          ...provided,
          fontSize: '12px', // Decrease font size for dropdown
        }),
        option: (provided) => ({
          ...provided,
          padding: 8, // Decrease padding for options
          fontSize: '12px', // Decrease font size for options
        }),
      };

      const selectedOption = options.find(option => option.value === selectedState);

  return (
    <Select
        name={name}
        options={options}
        isDisabled={disabledProp}
        required={requiredProp}
        onChange={(selectedOption) => onSelect(selectedOption ? selectedOption.value : '')}
        placeholder="Select State"
        isSearchable
        styles={customStyles}
        value={selectedOption || null}
      />
  );
};

export default StateDropdown;
