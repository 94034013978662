import "./Generate.css";

import SideBar from "./Components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

import Setting from "./pages/Setting";
import { useEffect } from 'react';

import B2CInvoice from "./pages/InvoiceType/B2cInvoice";
import ExportInvoice from "./pages/InvoiceType/ExportInvoice";
import CreditNote from "./pages/InvoiceType/CreditNote";
import DebitNote from "./pages/InvoiceType/DebitNote";

import MyInvoices from "./pages/MyinvoicePage/MyInvoices";
import TaxInvoice from "./pages/InvoiceType/Taxinvoice";
import Masters from "./pages/MyinvoicePage/Masters";


import Addseller from "./Components/OtherForm/Addseller";
import AddCustomer from "./Components/OtherForm/AddCustomer";
import AddStockItem from "./Components/OtherForm/AddStockItem";
import AddOtherCharges from "./Components/OtherForm/OtherCharges";
import AddTransporter from "./Components/OtherForm/AddTransporter";
import AddUserRole from "./Components/OtherForm/AddUserRole";
import AddBank from "./Components/OtherForm/AddBank";
import PrivateRoute from "./Authentication/PrivateRoute";
import EditCustomer from "./Components/OtherForm/EditCustomer";
import EditStockItem from "./Components/OtherForm/EditStockItem";
import EditOtherCharge from "./Components/OtherForm/EditOtherCharge";
import EditTransporter from "./Components/OtherForm/EditTransporter";
import EditBank from "./Components/OtherForm/EditBank";
import AddSeller from "./Components/OtherForm/Addseller";
import EditSeller from "./Components/OtherForm/EditSeller";





function Generate() {

  const navigate = useNavigate();


  useEffect(() => {
    // Check if user ID is present in local storage
    const userId = localStorage.getItem('userid');
  
    if (!userId) {
      // Redirect to the login page if user ID is not present
      navigate('/login');
    }

  }, [navigate]);
  return (

    <>
<div className="container">
    </div>

    <div className="vertical"> 
    
    <SideBar>
         <Routes> 
          <Route path="/" element={<MyInvoices />} />
          <Route path="/B2CInvoice" element={<B2CInvoice />} />
          <Route path="/ExportInvoice" element={<TaxInvoice />} />
          <Route path="/TaxInvoice" element={<TaxInvoice />} />
          <Route path="/CreditNote" element={<TaxInvoice />} />
          <Route path="/DebitNote" element={<TaxInvoice />} />
          <Route exact path="/Setting" element={<Setting />} />
          <Route exact path="/Masters" element={<Masters   />} />
          <Route exact path="/AddSeller" element={<Addseller/>} />
          <Route exact path="/AddCustomer" element={<AddCustomer/>} />
          <Route exact path="/AddStockItem" element={<AddStockItem/>} />
          <Route exact path="/AddOtherCharges" element={<AddOtherCharges/>} />
          <Route exact path="/AddTransporter" element={<AddTransporter/>} />
          <Route exact path="/AddUserRole" element={<AddUserRole/>} />
          <Route exact path="/AddBank" element={<AddBank/>} />
          <Route exact path="/EditCustomer/:customerId" element={<EditCustomer/>} />
          <Route exact path="/EditStockItem/:stockId" element={<EditStockItem/>} />
          <Route exact path="/EditOtherCharge/:otherChargeId" element={<EditOtherCharge/>} />
          <Route exact path="/EditTransporter/:transporterId" element={<EditTransporter/>} />
          <Route exact path="/EditBank/:bankId" element={<EditBank/>} />
          <Route exact path="/AddSeller" element={<AddSeller/>} />
          <Route exact path="/EditSeller/:sellerId" element={<EditSeller/>} />


          {/* <Route path="/MyInvoices" element={<MyInvoices />} /> */}
          
          {/* <Route path="/InformMail" element={<InformMail />} />
          <Route path="/offerMail" element={<OfferMail />} />
          <Route path="/Reminder" element={<Reminder />} />
          <Route path="/SubjectMail" element={<SubjectMail />} /> */}

          <Route path="*" element={<> not founxxd</>} />
          {/* </R> */}
         </Routes></SideBar>
         
         
       
       </div>
    </>
  );
}

export default Generate;