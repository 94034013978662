import {React, useState, useEffect} from 'react'
import './Masterforms.css'
import { MdOutlineManageAccounts } from 'react-icons/md';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { Link,useNavigate } from 'react-router-dom';
import TopLoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';
import TopLoader from '../TopLoader/TopLoader';
import axiosInstance from '../../api';
import { FaEye, FaEyeSlash } from 'react-icons/fa';




function EditSeller() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);
  const { sellerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
    
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };


  const [SellerData, setSellerData] = useState({
    GSTIN: null,
    IEC: null,
    MSME_REG_NO: null,
    TradeName: null,
    legalName: null,
    Address1: null,
    Address2: null,
    Seller_ID: null,
    NIC_username: null,
    NIC_Password: null,
    City: null,
    Pincode: null,
    State: null,
    Phone: null,
    Email: null,
    author: userId,  
  });

  const {GSTIN, IEC, MSME_REG_NO, TradeName, legalName, Address1, Address2, Seller_ID, NIC_username,
    NIC_Password, City, Pincode, State, Phone, Email, author   } = SellerData;

    useEffect(() => {
        fetchDataFromApi();
        setProgress(30);
        // Simulate an asynchronous operation (e.g., fetching data or loading resources)
        // Replace the setTimeout with your actual asynchronous operation
        setTimeout(() => {
          setProgress(100); // Set isLoading to false when the component is done loading
        });
      }, []);
    
      const fetchDataFromApi = async () => {
        try {

          const response = await axiosInstance.get(`/SellerDtl/${sellerId}`); // Use Axios to make the GET request
          
          const SellerData = response.data || {}; // Ensure OtherChargeData is not null or undefined
            setSellerData(SellerData);
          // setSellerGStin(response.data.GSTIN);
          console.log('Seller data is :', response.data);
          console.log('Seller data from OtherChargeData var :', SellerData);
          setLoading(false);
           // Axios automatically parses JSON responses
        } catch (error) {
          console.error('Error fetching data:', error);
          handleApiError(error, navigate)
          setLoading(false);
          setAlertMessage("Server could not responding")
          setAlertVariant("danger")
          setShowAlert(true)
        }
      };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSellerData({ ...SellerData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setSellerData({
      ...SellerData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(SellerData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.put(`/Seller/${sellerId}`, 
      
        SellerData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Seller Added successful:', response.data);
      setAlertVariant('success');

      navigate('../Setting?EditSeller=Seller added successfully!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Seller added failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    
  

  
    // Make API requests or perform other actions here
  };
  
const handleCancelButton = () => {
  navigate(-1);; // Close the modal
  };
    return(
        <>
    {loading ? (
      <TopLoader />
    ) : (
        <>
      <div>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Seller Updated!' : 'alertMessage'}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<MdOutlineManageAccounts></MdOutlineManageAccounts>} Add New Seller Account</h4><button type="button" className="btn  btn-primary imprtgstin">Import Details from GSTIN</button>
        
        <div className='AddDiv'><form className="row g-3" onSubmit={handleSubmit}>

  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">GSTIN:</label>
    <input type="text" name='GSTIN' value={SellerData.GSTIN} onChange={handleChange} className="form-control Addmasterform" id="inputPassword4" required/>
  </div>
 
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">IEC:</label>
    <input type="text"name='IEC' value={SellerData.IEC} onChange={handleChange} className="form-control Addmasterform" id="inputPassword4" />
  </div>
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">MSME Reg. No.:</label>
    <input type="text" name='MSME_REG_NO' value={SellerData.MSME_REG_NO} onChange={handleChange} className="form-control Addmasterform" id="inputPassword4"/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label" >Trade Name:</label>
    <input type="text" name='TradeName' value={SellerData.TradeName} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress2" className="form-label">Legal Name:</label>
    <input type="text" name='legalName' value={SellerData.legalName} onChange={handleChange} className="form-control Addmasterform" id="inputAddress2"/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 1:</label>
    <input type="text" name='Address1' value={SellerData.Address1} onChange={handleChange} className="form-control Addmasterform" id="inputCity" required/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 2:</label>
    <input type="text" name='Address2' value={SellerData.Address2} onChange={handleChange} className="form-control Addmasterform" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">City:</label>
    <input type="text" name='City' value={SellerData.City} onChange={handleChange} className="form-control Addmasterform" id="inputZip" required/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Pincode:</label>
    <input type="number" name='Pincode' value={SellerData.Pincode} onChange={handleChange} className="form-control Addmasterform" id="inputZip" minLength={6} required/>
  </div>
  <div className="col-md-2">
  <label for="inputZip" className="form-label">Select State:</label>
  <StateDropdown selectedState={SellerData.State || ""} onSelect={(value) => handleDropdownChange(value)} />
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Phone:</label>
    <input type="number" name='Phone' value={SellerData.Phone} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Email:</label>
    <input type="email" name='Email' value={SellerData.Email} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Seller Id:</label>
    <input type="number" name='Seller_ID' value={SellerData.Seller_ID} onChange={handleChange} className="form-control Addmasterform" id="inputZip" required/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">NIC USERNAME:</label>
    <input type="text" name='NIC_username' value={SellerData.NIC_username} onChange={handleChange} className="form-control Addmasterform" id="inputZip" required/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">NIC PASSWORD:</label>
    <div className='nicPasswordDiv'>
    <input type={showPassword ? 'text' : 'password'} name='NIC_Password' value={SellerData.NIC_Password} onChange={handleChange}  className="form-control Addmasterform" id="inputZip" required/>
    <span className="password-toggle-icon" id='nicPasswordEye' onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </span>
    </div>
  </div>
  <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>

</form></div></div>
            
        </>
        )}
</>

    )
    
}

export default EditSeller;