import React, { useState, useEffect } from 'react'
import './Masterforms.css'
import { MdOutlineManageAccounts } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'; 
import { Button, Image, Modal } from 'react-bootstrap';
import axios from 'axios';
import axiosInstance from './../../api';




function AddSeller() {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState(null);

  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };
  const [image, setImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userid, setuserid] = useState(localStorage.getItem('userid'));

  useEffect(() => {
    const fetchProfileImage = async () => {
        try {
            const response = await axiosInstance.get('/profile-image', {
                responseType: 'blob',
                withCredentials: true, // Ensure cookies are sent if using auth
            });

            const imageUrl = URL.createObjectURL(response.data);
            setImageSrc(imageUrl);
            setImage(imageUrl);
        } catch (error) {
            console.error('Error fetching profile image:', error);
        }
    };

    fetchProfileImage();
}, []);
  
  const handleUploadLogo = async (event) => {
    event.preventDefault();
    const fileInput = document.querySelector('input[type="file"]');
    const formData = new FormData();
    formData.append('userId', userid);  
    if (fileInput.files[0]) {
      formData.append('logo', fileInput.files[0]);
      

      try {

        const response = await axiosInstance.post('/upload-logo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data);
        alert('Logo uploaded successfully!');
      } catch (error) {
        console.error('Error uploading logo:', error);
        alert('Error uploading logo.');
      }
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the uploaded image URL
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };
  
  const handleRemoveImage = async () => {
    try {

      const response = await axiosInstance.delete(`/delete-logo`);
  
      // Reset the image state after a successful response
      setImage(null);
      alert('Image removed successfully!');
    } catch (error) {
      console.error('Error removing image:', error);
      alert('Error removing image. Please try again.');
    }
  };

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

    return(
        <>
        <h4 className='MasterFormTitle'>{<MdOutlineManageAccounts></MdOutlineManageAccounts>} Edit Profile</h4>
        <div className='AddDiv'><form className="row g-3">
        <div className="text-center">
      <div className="profile-picture-container">
        {image ? (
          <Image src={image} roundedCircle className="img-fluid profile-image" alt="Profile" />
        ) : (
          <div className="default-image d-flex align-items-center justify-content-center">
            <span>No Image</span>
          </div>
        )}
      </div>

      <div className="mt-3">
        <Button variant="primary" onClick={handleModalShow}>Upload Logo</Button>{' '}
        {image && (
          <Button variant="danger" onClick={handleRemoveImage}>Remove Logo</Button>
        )}
      </div>

      {/* Modal for uploading image */}
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUploadLogo}>
            <input 
              type="file" 
              accept="image/*" 
              onChange={handleImageChange} 
              style={{ display: 'block', margin: 'auto' }} // Center the file input
            />
            <Button type="submit" variant="success" className="mt-2">Upload Logo</Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>Close</Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .profile-picture-container {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0 auto;
          border: 2px solid #ccc;
        }
        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: translate(-68%, -14%);
        }
        .default-image {
          width: 100%;
          height: 100%;
          color: #888;
          font-size: 1.2em;
          background-color: #f0f0f0;
        }
      `}</style>
    </div>
  <div className="col-md-2 profileformCol">
    <label for="inputPassword4" className="form-label">Full Name:</label>
    <input type="password" className="form-control Addmasterform" id="inputPassword4"/>
  </div>
  <div className="col-md-2 profileformCol">
    <label for="inputPassword4" className="form-label">Email Address:</label>
    <input type="password" className="form-control Addmasterform" id="inputPassword4"/>
  </div>
  <div className="col-md-2 profileformCol">
    <label for="inputPassword4" className="form-label">Phone Number.:</label>
    <input type="password" className="form-control Addmasterform" id="inputPassword4"/>
  </div>
  <h4 className='MasterFormTitle'>Company Details</h4>
  <div className="col-md-2 profileformCol">
    <label for="inputAddress" className="form-label" >Company Name:</label>
    <input type="text" className="form-control Addmasterform" id="inputAddress" p/>
  </div>

  <div className="col-md-2 profileformCol">
    <label for="inputAddress2" className="form-label">GSTIN:</label>
    <input type="text" className="form-control Addmasterform" id="inputAddress2"/>
  </div>
  <div className="col-md-2 profileformCol">
    <label for="inputCity" className="form-label">Address 1:</label>
    <input type="text" className="form-control Addmasterform" id="inputCity"/>
  </div>
  <div className="col-md-2 profileformCol">
    <label for="inputCity" className="form-label">Address 2:</label>
    <input type="text" className="form-control Addmasterform" id="inputCity"/>
  </div>
  <div className="col-md-2 profileformCol">
    <label for="inputZip" className="form-label">City:</label>
    <input type="text" className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2 profileformCol">
    <label for="inputZip" className="form-label">Pincode:</label>
    <input type="text" className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2 profileformCol">
    <label for="inputState" className="form-label">State:</label>
    <select id="inputState" className="form-select Addmasterform">
      <option className='option' selected>Select State</option>
      <option className='option'>...</option>
    </select>
  </div>
</form></div>
        
            <div className='submitinvbtndiv'>
            <button type="button" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
        </>

    )
    
}

export default AddSeller;