import {React, useState, useEffect} from 'react'
import './Masterforms.css'
import { BsBoxFill } from 'react-icons/bs';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';
import TopLoader from '../TopLoader/TopLoader';
import axiosInstance from '../../api';


function EditStockItem() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);
  const [isChecked, setIsChecked] = useState(true);
  const value = isChecked ? 'Y' : 'N';
  const { stockId } = useParams();
  const [loading, setLoading] = useState(true);

  const [StockData, setStockData] = useState({
    ItemName: null,
    ItemDescription: null,
    Isservice: 'N',
    HSNCode: null,
    QTY: null,
    Price: null,
    Unit: null,
    TaxRate: null
  });

  const handleCheckboxChange = () => {
    // Toggle the checked state
    const newIsChecked = !isChecked;
    
    // Update the Isservice field in formData
    setStockData({
      ...StockData,
      Isservice: newIsChecked ? 'Y' : 'N', // Reverse the value when the checkbox is checked or unchecked
    });
    
    // Update the isChecked state
    setIsChecked(newIsChecked);
  };


  useEffect(() => {
    fetchDataFromApi();
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });
  }, []);

  const fetchDataFromApi = async () => {
    try {

      const response = await axiosInstance.get(`/StockDtl/${stockId}`); // Use Axios to make the GET request
      
      const StockData = response.data || {}; // Ensure StockData is not null or undefined
        setStockData(StockData);
      // setSellerGStin(response.data.GSTIN);
      console.log('Stock data is :', response.data);
      // console.log('Stock data var :', response.data);
      setLoading(false);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
      handleApiError(error, navigate)
      setLoading(false);
      setAlertMessage("Server could not responding")
      setAlertVariant("danger")
      setShowAlert(true)
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setStockData({ ...StockData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setStockData({
      ...StockData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(StockData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.put(`/Stock/${stockId}`, 
      
      StockData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('StockItem Edited successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?Stockedit=StockItem Updated');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('StockItem updated failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    
  

  
    // Make API requests or perform other actions here
  };
  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };
    return(
      <>
    {loading ? (
      <TopLoader />
    ) : (
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Stock Updated' : alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<BsBoxFill></BsBoxFill>} Edit Item</h4>
        <div className='AddDiv'>
          <form onSubmit={handleSubmit}><div className='Itemtablediv'>
        <div className="table-responsive">
        <table className="table table-sm">
   <thead>
      <tr>
        {/* <th>Item Type</th> */}
        <th>Item Name</th>
        <th>Item Description</th>
        <th>Is Service</th>
        <th>HSN Code</th>
        <th>QTY</th>
        <th>Price</th>
        <th>Unit</th>
        {/* <th>Discount</th>  */}
        <th>Tax Rate</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        {/* <td></td> */}
        <td><input name='ItemName' value={StockData.ItemName} onChange={handleChange} className="form-control Addmasterform" type="text" required/></td>
        <td><input name='ItemDescription' value={StockData.ItemDescription} onChange={handleChange} className="form-control Addmasterform" type="text" readonly/></td>
        <td><div className="form-check form-switch" id=''>
  <input className="form-check-input" style={{ width: '100%' }} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={StockData.Isservice === 'Y' ? isChecked : false}
  onChange={handleCheckboxChange} />
        <input type="hidden" id="hiddenValue" value={value} />
  </div></td>
        <td><input name='HSNCode' value={StockData.HSNCode} onChange={handleChange} className="form-control itemhsn Addmasterform" type="text" readonly/></td>
        <td><input name='QTY' value={StockData.QTY} onChange={handleChange} className="form-control itemqty Addmasterform" type="text" readonly/></td>
        <td><input name='Price' value={StockData.Price} onChange={handleChange} className="form-control itemprice Addmasterform" type="text" readonly/></td>

        <td><select id="inputState" name='Unit' value={StockData.Unit} onChange={handleChange} className="form-select itemunit Addmasterform">
        <option value="">Select a unit</option>
        <option value="BAG">BAGS</option>
        <option value="BAL">BALE</option>
        <option value="BDL">BUNDLES</option>
        <option value="BKL">BUCKLES</option>
        <option value="BOU">BILLION OF UNITS</option>
        <option value="BOX">BOX</option>
        <option value="BTL">BOTTLES</option>
        <option value="BUN">BUNCHES</option>
        <option value="CAN">CANS</option>
        <option value="CCM">CUBIC CENTIMETERS</option>
        <option value="CMS">CENTIMETERS</option>
        <option value="CBM">CUBIC METERS</option>
        <option value="CTN">CARTONS</option>
        <option value="DOZ">DOZENS</option>
        <option value="DRM">DRUMS</option>
        <option value="GGK">GREAT GROSS</option>
        <option value="GMS">GRAMMES</option>
        <option value="GRS">GROSS</option>
        <option value="GYD">GROSS YARDS</option>
        <option value="KGS">KILOGRAMS</option>
        <option value="KLR">KILOLITRE</option>
        <option value="KME">KILOMETRE</option>
        <option value="LTR">LITRES</option>
        <option value="MLS">MILLI LITRES</option>
        <option value="MLT">MILILITRE</option>
        <option value="MTR">METERS</option>
        <option value="MTS">METRIC TON</option>
        <option value="NOS">NUMBERS</option>
        <option value="OTH">OTHERS</option>
        <option value="PAC">PACKS</option>
        <option value="PCS">PIECES</option>
        <option value="PRS">PAIRS</option>
        <option value="QTL">QUINTAL</option>
        <option value="ROL">ROLLS</option>
        <option value="SET">SETS</option>
        <option value="SQF">SQUARE FEET</option>
        <option value="SQM">SQUARE METERS</option>
        <option value="SQY">SQUARE YARDS</option>
        <option value="TBS">TABLETS</option>
        <option value="TGM">TEN GROSS</option>
        <option value="THD">THOUSANDS</option>
        <option value="TON">TONNES</option>
        <option value="TUB">TUBES</option>
        <option value="UGS">US GALLONS</option>
        <option value="UNT">UNITS</option>
        <option value="YDS">YARDS</option>
    </select></td>

        {/* <td><input className="form-control itemdiscount" type="text" readonly/></td> */}

        <td><select id="inputState" name='TaxRate' value={StockData.TaxRate} onChange={handleChange} className="form-select Addmasterform">
      <option value="0" selected>0</option>
        <option value="0.1">0.1</option>
        <option value="0.25">0.25</option>
        <option value="1">1</option>
        <option value="1.5">1.5</option>
        <option value="3">3</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7.5">7.5</option>
        <option value="12">12</option>
        <option value="18">18</option>
        <option value="28">28</option>
    </select></td>


      </tr>
    </tbody>
    
    </table></div></div><div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div></form></div>
        
            
        </>

)}
</>

    )
    
}

export default EditStockItem;