import { useState, useEffect } from 'react';
// import { Tabs, TabList, Tab } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import Dashboard from './dashboard';
import Saved from './Saved';
import './headtitle.css'
import ListHeader from '../ListHeader/sellerHeader'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const Headtitle = (props)=> {

  const [key, setKey] = useState(props.tab1);

  

  useEffect(() => {
    if (props.selectedTab === "Stock Item") {

      setKey(props.tab2)
      
    } else if (props.selectedTab === "Transporter") {

      setKey(props.tab3)
      
    } else if (props.selectedTab === "Other Charges") {

      setKey(props.tab4)
      
    } else if (props.selectedTab === "Bank") {

      setKey(props.tab5)
      
    }else if (props.selectedTab === "Add User & Role") {

      setKey(props.tab2)
      
    }
    else if (props.selectedTab === "Subscription Plan") {

      setKey(props.tab4)
      
    }
    else if (props.selectedTab === "Profile") {

      setKey(props.tab3)
      
    }
  }, [props.selectedTab, props.tab2]);
  

  return (
  
    
    <div className='settingnav'>
    <Tabs
      activeKey={key}
      onSelect={(k) => setKey(k)}
      id="uncontrolled-tab-example"
      className="mb-3"
      // variant='pills'  
    > 
      <Tab eventKey={props.tab1} title={props.tab1} tabClassName='sellertitle'>
      <div className='btnsDiv'>{props.tab1btn} {props.tab1btn2} {props.tab1btn3}</div>
      <div>{props.tab1c}</div>  
      </Tab>
      <Tab eventKey={props.tab2} title={props.tab2} tabClassName='sellertitle'><div id = "menu1">
      <div className='btnsDiv'>{props.tab2btn} {props.tab2btn2} {props.tab2btn3}</div>
      {props.tab2c}</div>
      </Tab>
      <Tab eventKey={props.tab3} title={props.tab3} tabClassName='sellertitle'><div id = "menu2">
      <div className='btnsDiv'>{props.tab3btn} {props.tab3btn2} {props.tab3btn3}</div>
      {props.tab3c}</div>
      </Tab>
      <Tab eventKey={props.tab4} title={props.tab4} tabClassName='sellertitle'><div id = "menu2">
      <div className='btnsDiv'>{props.tab4btn} {props.tab4btn2} {props.tab4btn3}</div>
      {props.tab4c}</div>
      </Tab>
      <Tab eventKey={props.tab5} title={props.tab5} tabClassName='sellertitle'><div id = "menu2">
      <div className='btnsDiv'>{props.tab5btn} {props.tab5btn2} {props.tab5btn3}</div>
      {props.tab5c}</div>
      </Tab>
    </Tabs></div>
  );
}

export default Headtitle;
