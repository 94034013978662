// import SideBar from "src/Components/Sidebar/SidebarMenu.jsx";
import {React, useState, useEffect} from 'react'
import '../Components/CustomNav/CustomNav.css'
import Headtitle from '../Components/Dashboard/Headtitle';
import SettingTab from '../Components/Dashboard/Headtitle'
import SellerHeader from '../Components/ListHeader/sellerHeader';
import UserRoleHeader from '../Components/ListHeader/userRoleHeader'
import Button from '../Components/ListHeader/button'
import EditProfile from '../Components/OtherForm/EditProfile';
import Alert from '../Components/CustomAlert/Alert1';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import TopLoadingBar from 'react-top-loading-bar';
import AddUserRole from '../Components/OtherForm/AddUserRole';
import SubscriptionPage from './subscription';
import axiosInstance from './../api';



const Dashboard = () => { 

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const successMessage = queryParams.get('success');
  const SellerEditedMessage = queryParams.get('EditSeller');
  const UserRoleAddedMessage = queryParams.get('UserAdded');
  const Settingtab = queryParams.get('tab');
  const userId = localStorage.getItem('userid');
  const [selectedTab, setSelectedTab] = useState('');
  const [progress, setProgress] = useState(0);

  const [subscriptionStatusData, setSubscriptionStatusData] = useState([]);

  const isActive = subscriptionStatusData?.isActive ?? false; // or false
  const renewalDate = subscriptionStatusData?.renewalDate ?? ""; // format as needed
  const invoiceLimit = subscriptionStatusData?.invoiceLimit ?? "";; // example limit for the plan
  const totalInvoicesGenerated = subscriptionStatusData?.totalInvoicesGenerated ?? ""; // number of invoices generated
  const contactNumber = subscriptionStatusData?.contactNumber ?? "+916387196377";
  const contactEmail = subscriptionStatusData?.contactEmail ?? "sales@rapidinvoice.com";

 

  const navigate = useNavigate();



  useEffect(() => {
    getSubsriptionStatus();
    console.log(subscriptionStatusData);
    if (successMessage) {
      setAlertMessage('Seller Added!');
      setAlertVariant('success');
      setShowAlert(true);
    } 
    else if (Settingtab === 'subscription') {
      setSelectedTab('Subscription Plan');
    }
    else if (Settingtab === 'profile') {
      setSelectedTab('Profile');
    }
    else if (SellerEditedMessage) {
      setSelectedTab('Seller Account');
      setAlertMessage('Seller Details Updated');
      setAlertVariant('success');
      setShowAlert(true);
    }
    else if (UserRoleAddedMessage) {
      setSelectedTab('Add User & Role');
      setAlertMessage('User Added!');
      setAlertVariant('success');
      setShowAlert(true);
    }
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });
  }, [successMessage, UserRoleAddedMessage, Settingtab]);

  const handleCloseAlert = () => {
    // Remove the query parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('EditedDone');
    searchParams.delete('success');
    navigate(location.pathname + '?' + searchParams.toString());
    // Close the alert
    setShowAlert(false);
  };

  const getSubsriptionStatus = async () => {

    try {

      const response = await axiosInstance.get(`/subscription/${userId}`); // Use Axios to make the GET request
      
      setSubscriptionStatusData(response.data);
      // setSellerGStin(response.data.GSTIN);
      // console.error('seller data is :', response.data);
       // Axios automatically parses JSON responses
       console.log(response.data);
    } catch (error) {
      console.error('Error fetching subscriptionStatus data:', error);
    //   handleApiError(error, navigate)
    }
  };
  console.log(isActive);

  return (
  <>
  <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        {showAlert && (
        <div className="success-message">{<Alert
          variant={alertVariant}
          message={alertMessage}
          onClose={handleCloseAlert}  
          />}</div>
      )}
  <SettingTab 

  selectedTab={selectedTab}

  tab1="Seller Account" 
  tab2="Add User & Role" 
  tab3="Profile"
  tab4="Subscription Plan"   
  
  tab1c = {<SellerHeader c1 ="Location" c2="Gstin" c3="Legal Name" c4="Trade Name" c5="Address" c6="Seller Code" />}
  tab2c = {<UserRoleHeader c1 ="ERP Id" c2="Name" c3="Email" c4="User Id" />}
  tab3c = {<EditProfile></EditProfile>}
  tab4c = {<SubscriptionPage isActive={isActive}
  renewalDate={renewalDate}
  invoiceLimit={invoiceLimit}
  totalInvoicesGenerated={totalInvoicesGenerated}
  contactNumber={contactNumber}
  contactEmail={contactEmail}/>}
  // tab3c = {<ListHeader c1 ="Location" c2="Gstin" c3="Legal Name" c4="Trade Name" c5="Address" c6="Seller Code" />}
  
  // tab1btn = {<Button name = 'Add New' link = '/App/AddSeller' />}
  // tab2btn = {<Button name = 'Create User'  link = '/App/AddUserRole' />}
  // tab3btn = {<Button name = 'Edit Profile' />}
  
  ></SettingTab>

    </>)
};

export default Dashboard;