import {React, useEffect } from 'react'
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Components/LandingPage/Navbar';
import axios from 'axios';
import { useState } from 'react';
import Alert from '../Components/CustomAlert/Alert1';
import { handleApiError } from '../Authentication/AuthService';
import TopLoader from '../Components/TopLoader/TopLoader';
import TopLoadingBar from 'react-top-loading-bar';
import { setAccessToken } from '../Authentication/AuthService';
import Footer from '../Components/CustomFooter/footer';
import { useLocation,useParams,  } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


function Login() {

  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const successMessage = queryParams.get('Registered');

  // const [passwords, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
    
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  const [formData, setFormData] = useState({
    UserName: '',
    password: '',
  });

  const { UserName, password } = formData;
  useEffect(() => {
    if (successMessage) {
      setAlertMessage('Registration Successful!');
      setAlertVariant('success');
      setShowAlert(true);
    }
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });
  }, [successMessage]);
  const handleChange = (e) => {

    // setIsLoading(true);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  if (localStorage.getItem('userid') || localStorage.getItem('sellerGstin')) {
    localStorage.removeItem('userid');
    localStorage.removeItem('sellerGstin');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(30);
    // Use the formData state for form submission
    console.log(formData);
    try {
      // localStorage.removeItem('userid'); 
      setShowAlert(false);
      const axiosInstance = axios.create({
        withCredentials: true,
      });  
      const response = await axiosInstance.post('https://rapidbill.in/api/login', formData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      // setProgress(100);
      console.log('Login successful:', response.data);
      setAlertVariant('success');
      const cookies = response.headers.get('Set-Cookie');
        // Set the cookies in the browser
        document.cookie = cookies;

        setAccessToken(1800);
      localStorage.setItem('userid',response.data.user._id)
      

      if (localStorage.getItem('userid')) {
        return navigate('/App');
      } 

    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Login failed:', error.response.data);
      setAlertVariant('danger');
      // setProgress(0);
      // handleApiError(error)
        
      
    }finally {
      // setIsLoading(false); // Step 3
      setProgress(100);
      setShowAlert(true);
    }
    
    
  
    // Make API requests or perform other actions here
  };
    return(
        <>
          <div className='loginDiv'>
          <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        <Navbar title = "Rapid Invoice" aboutText = "About us" hidden = {true}/>
    <div>
     {/* Step 2 */}
        <h1 className='header'>Already have an account ?</h1>
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? '' : 'Invalid UserId Or Password'}
        title={alertVariant === 'success' ? alertMessage : 'Login failed!'}
          onClose={() => setShowAlert(false)}
        />
      )}
    </div>  

<div className='mainLogin'>
    <main className="form-signin w-100 m-auto">
  <form onSubmit={handleSubmit}>
    {/* <img className="mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"/> */}
    {/* <h1 className="h3 mb-3 fw-normal">Please sign in</h1> */}
    <div className="form-floating">
      <input type="text" name='UserName' value={UserName} onChange={handleChange} className="form-control" id="floatingInput" placeholder="name@example.com" required/>
      <label for="floatingInput">Username</label>
    </div>
    <div className="form-floating">
      <input type={showPassword ? 'text' : 'password'} name='password' value={password} onChange={handleChange} className="form-control" id="floatingPassword" placeholder="Password" required/>
      <label for="floatingPassword">Password</label>
      <span className="password-toggle-icon" onClick={togglePasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </span>


    </div>

    <div className='submitBtnDiv' >
      <button id='LoginBtnClick' className="btn btn-lg btn-primary" type="submit">Sign in</button>
      <Link className="nav-link" to="/Signup">{<p className='signlink'>Sign up</p>}</Link>
    </div>
    {/* <p className="mt-5 mb-3 text-muted">&copy; 2017–2022</p> */}
  </form>
</main>


    </div>
    <div>
      
    </div></div>
    <Footer/>
    </>
)
}

export default Login;