import React from 'react'

import './customerdtl.css'

function Paymentdtl() {

return(
<div className='customerdtlDiv'>
<form className="row g-3">
    
<div className="col-md-2">
    <label for="inputState" className="form-label">Select Bank:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>
  
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Payee Name:</label>
    <input type="text" className="form-control" id="inputAddress" p/>
  </div>

  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Payee A/C Number:</label>
    <input type="text" className="form-control" id="inputAddress" p/>
  </div>

  <div className="col-md-2">
    <label for="inputState" className="form-label">Name of Bank:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>

  <div className="col-md-2">
    <label for="inputZip" className="form-label">Branch or IFSC Code.:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Mode of Payment:</label>
    <input type="text" className="form-control" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Payment Term:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Payment Instruction:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Direct Debit:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
</form></div>
)
    
}

export default Paymentdtl;
