import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom';
import { handleApiError } from '../../Authentication/AuthService';
import "../../Generate.css"
import axiosInstance from '../../api';





function SelectList() {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(localStorage.getItem('sellerGstin'));
  const userId = localStorage.getItem('userid');
  let sellerGstin = selectedItem;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from your API here
    // const objectIdUserId = userId;
    //  console.log(userId);
    fetchDataFromApi();
    sellerGstin = selectedItem
  }, [userId]);

  const selectStyle = {
    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    // fontWeight: 500,
    fontSize: '13px',
    height: '35px'  // Change the font size as needed
    // You can further customize other font properties like fontStyle, lineHeight, etc.
  };

  console.log('selectedItem:', selectedItem);
  
  // localStorage.setItem('sellerGstin',sellerGstin)

  const fetchDataFromApi = async () => {
    try {

      const response = await axiosInstance.get(`/getseller/${userId}`); // Use Axios to make the GET request
      
      setData(response.data);
      // setSellerGStin(response.data.GSTIN);
      // console.error('seller data is :', response.data);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
      handleApiError(error, navigate)
    }
  };


  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedItem(selectedValue);
    localStorage.setItem('sellerGstin', selectedValue);

    const selectedSeller = data.find(item => item.GSTIN === selectedValue);
    if (selectedSeller) {
      localStorage.setItem('seller_id', selectedSeller._id);
    }else{
      localStorage.removeItem('seller_id');
    }

    window.location.reload();

  };

  return (
    <div>
      {/* <h2>Seller</h2> */}
      <select className="form-select" id='sellerdrop' style={selectStyle} aria-label="Default select example" value={selectedItem} onChange={handleSelectChange}>
        <option hidden>Select Seller</option>
        {data.map((item, index) => (
          <option key={item._id} value={item.GSTIN}>
            {item.State + " ("+item.GSTIN+")"}
          </option>
        ))}
      </select>
      {selectedItem && (
        <div>

        </div>
      )}
    </div>
  );
}

export default SelectList;
