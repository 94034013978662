import {React, useState, useEffect} from 'react'
import './Masterforms.css'
import { SlPeople } from 'react-icons/sl';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';
import TopLoader from '../TopLoader/TopLoader';
import axiosInstance from '../../api';


function EditCustomer() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);
  const defaultCustomerType = 'Regular';
  const { customerId } = useParams();
  const [loading, setLoading] = useState(true);

  const [CustomerData, setCustomerData] = useState({
    CustomerType: null,
    Gstin: null,
    Tradename: null,
    LegalName: null,
    Address1: null,
    Address2: null,
    City: null,   
    pinCode: null,
    State: null,
    phone: null,
    email: null,  
  });


  useEffect(() => {
    fetchDataFromApi();
    setProgress(30);
    // Simulate an asynchronous operation (e.g., fetching data or loading resources)
    // Replace the setTimeout with your actual asynchronous operation
    setTimeout(() => {
      setProgress(100); // Set isLoading to false when the component is done loading
    });
  }, []);

  const fetchDataFromApi = async () => {
    try {

      const response = await axiosInstance.get(`/customer/${customerId}`); // Use Axios to make the GET request
      
      const customerData = response.data || {}; // Ensure customerData is not null or undefined
        setCustomerData(customerData);
      // setSellerGStin(response.data.GSTIN);
      console.log('Customer data is :', response.data);
      setLoading(false);
       // Axios automatically parses JSON responses
    } catch (error) {
      console.error('Error fetching data:', error);
      handleApiError(error, navigate)
      setLoading(false);
      setAlertMessage("Server could not responding")
      setAlertVariant("danger")
      setShowAlert(true)
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...CustomerData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setCustomerData({
      ...CustomerData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(CustomerData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.put(`/customers/${customerId}`, 
      
      CustomerData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Customer Edited successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?EditedDone=customer Updated');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Customer added failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    
  

  
    // Make API requests or perform other actions here
  };
  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };
    return(
      <>
    {loading ? (
      <TopLoader />
    ) : (
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Customer Updated' : alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<SlPeople></SlPeople>} Edit Customer</h4><button type="button" className="btn  btn-primary imprtgstin">Import Details from GSTIN</button>
        <div className='AddDiv'><form className="row g-3" onSubmit={handleSubmit}>
<div className="col-md-2">
    <label for="inputState" className="form-label">Customer Type:</label>
    <select id="inputState" name='CustomerType' value={CustomerData.CustomerType} onChange={handleChange} className="form-select Addmasterform">
      <option className='option' selected>Regular</option>
      <option className='option'>Unregistered</option>
      <option className='option'>Consumer</option>
      <option className='option'>Overseas</option>
      <option className='option'>Special Economic Zone</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">GSTIN:</label>
    <input type="text" name='Gstin' value={CustomerData.Gstin} onChange={handleChange} className="form-control Addmasterform" id="inputPassword4" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label" >Trade Name:</label>
    <input type="text" name='Tradename' value={CustomerData.Tradename} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress2" className="form-label">Legal Name:</label>
    <input type="text" name='LegalName' value={CustomerData.LegalName} onChange={handleChange} className="form-control Addmasterform" id="inputAddress2" required/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 1:</label>
    <input type="text" name='Address1' value={CustomerData.Address1} onChange={handleChange} className="form-control Addmasterform" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputCity" className="form-label">Address 2:</label>
    <input type="text" name='Address2' value={CustomerData.Address2} onChange={handleChange} className="form-control Addmasterform" id="inputCity"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">City:</label>
    <input type="text" name='City' value={CustomerData.City} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Pincode:</label>
    <input type="text" name='pinCode' value={CustomerData.pinCode} onChange={handleChange} className="form-control Addmasterform" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">State:</label>
    <StateDropdown selectedState={CustomerData.State || ""} onSelect={(value) => handleDropdownChange(value)} />
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Phone:</label>
    <input type="number" name='phone' value={CustomerData.phone} onChange={handleChange} className="form-control Addmasterform" id="inputZip" />
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Email:</label>
    <input type="email" name='email' value={CustomerData.email} onChange={handleChange} className="form-control Addmasterform" id="inputZip" />
  </div>
  <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
</form></div>
        
            
        </>

)}
</>

    )
    
}

export default EditCustomer;