import React from 'react'

import './customerdtl.css'

function Ewaybilldtl() {

return(
<div className='customerdtlDiv'>
<form className="row g-3">
<div className="col-md-2">
    <label for="inputState" className="form-label">Select Transporter:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Transport ID/GSTIN:</label>
    <input type="password" className="form-control" id="inputPassword4"/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Transport Name:</label>
    <input type="text" className="form-control" id="inputAddress" p/>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Transportation Mode:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Vehicle Number:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Vehicle Type:</label>
    <select id="inputState" className="form-select">
      <option className='option' selected>Invoice</option>
      <option className='option'>...</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Distance:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Transport Doc No:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
  <div className="col-md-2">
    <label for="inputZip" className="form-label">Transport Date:</label>
    <input type="text" className="form-control" id="inputZip"/>
  </div>
</form></div>
)
    
}

export default Ewaybilldtl;
