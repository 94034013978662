import React from 'react'
import {MdOutlineExpandCircleDown } from "react-icons/md";
import GenerateFormTitle from '../../Components/NotUsable/GenerateFormTitle';
import GenerateForm from '../../Components/NotUsable/GenerateForm';
import Customerdtl from '../../Components/InvoiceForm/customerdtl';
import Documentdtl from '../../Components/InvoiceForm/documentdtl';
import Itemdtl from '../../Components/InvoiceForm/itemdtl'
import Dispatchdtl from '../../Components/InvoiceForm/dispatchdtl';
import Consigneedtl from '../../Components/InvoiceForm/consigneedtl';
import Contractdtl from '../../Components/InvoiceForm/contractdtl';
import Paymentdtl from '../../Components/InvoiceForm/paymentdtl';
import Ewaybilldtl from '../../Components/InvoiceForm/ewaybilldtl';




function B2cinvoice() {

    return(

        <>
        <div className='taxinvoicediv'>

        <GenerateFormTitle title="1. Customer Details*" />
        <Customerdtl></Customerdtl>
        {/* <hr className="my-4 mb-0 mt-2"></hr> */}
        <GenerateFormTitle title="2. Document Details*" />
        <Documentdtl></Documentdtl>
        {/* <hr className="my-4 mb-0 mt-2"></hr> */}
        <GenerateFormTitle title="3. Item Details*" />
        <Itemdtl></Itemdtl>
        <GenerateFormTitle title="4. E-way Bill Details" />
        <Ewaybilldtl></Ewaybilldtl>
        <GenerateFormTitle title="5. Dispatch Details" />
        <Dispatchdtl></Dispatchdtl>
        <GenerateFormTitle title="6. Consignee/ Ship-to" />
        <Consigneedtl></Consigneedtl>
        <GenerateFormTitle title="7. Contract Details" />
        <Contractdtl></Contractdtl>
        <GenerateFormTitle title="8. Payment Details" />
        <Paymentdtl></Paymentdtl>



        </div>
            <div className='submitinvbtndiv'>
            <button type="button" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
        </>
    )
    
}

export default B2cinvoice;