import './App.css';

// import Navbar from './Components/Navbar';
// import PromptForm from './Components/PromptForm';
import LandingPage from './Components/LandingPage/LandingPage';
import Login from './LoginSignup/Login';
import Signup from './LoginSignup/Signup';
import Generate from './Generate';
import PrivateRoute from './Authentication/PrivateRoute';
import { BrowserRouter as Router,
  Route,
  Link,
  Routes
 } from 'react-router-dom';






function App() {


  return (
    <>
    
<Router>
<div className='containhead'>
<Routes>
    <Route exact path='/' element={<LandingPage />}/>
    <Route exact path='/Login' element={<Login />}></Route>
    <Route exact path='/Signup' element={<Signup />}></Route>
    <Route exact path='/App/*' element={<Generate/>}></Route>
</Routes>
</div>
</Router>

    </>
  );
}

export default App;
