import {React, useState} from 'react'
import './Masterforms.css'
import { SlPeople } from 'react-icons/sl';
import StateDropdown from '../CustomDropdown/StateDropdown';
import axios from 'axios';
import { handleApiError } from '../../Authentication/AuthService';
import Alert from '../CustomAlert/Alert1';
import { useNavigate } from 'react-router-dom'; 
import TopLoadingBar from 'react-top-loading-bar';
import { CiDeliveryTruck } from 'react-icons/ci';
import axiosInstance from '../../api';


function AddTransporter() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [progress, setProgress] = useState(0);

  const [isChecked, setIsChecked] = useState(false);

  const [formData, setFormData] = useState({
    TransporterId: null,
    TransporterName: null,
    TransportationMode: null,
    VehicleNumber: null,
    VehicleType: null,
    author: userId,  
  });


  const {TransporterId, TransporterName, TransportationMode, VehicleNumber, VehicleType, author} = formData

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleDropdownChange = (selectedValue) => {
    // Update the selected state in the form data
    setFormData({
      ...formData,
      State: selectedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setProgress(30);
  
    // Use the formData state for form submission
    console.log(formData);
    

    try {
      setShowAlert(false);

      const response = await axiosInstance.post('/addtranspoter', formData);
  
      // Handle the API response (e.g., display a success message, redirect, etc.)
      console.log('Transporter Added successful:', response.data);
      setAlertVariant('success');

      navigate('../Masters?TransporterAdd=Transporter added successfully!');
    } catch (error) {
      // Handle errors (e.g., display error messages to the user)
      console.error('Transporter added failed:', error.response.data);
      setAlertVariant('danger');

      handleApiError(error, navigate)
      
    }finally {
      // Set isLoading to false when the API request completes (whether it succeeds or fails)
      setProgress(100);
      setShowAlert(true);
    }
    // Make API requests or perform other actions here
  };


  const handleCancelButton = () => {
    navigate(-1);; // Close the modal
    };
    return(
        <>
        <TopLoadingBar progress={progress} color="#007bff" onLoaderFinished={() => setProgress(0)} />
        
        {showAlert && (
        <Alert
        variant={alertVariant}
        message={alertVariant === 'success' ? 'Transporter Added!' : 'Failed to add Transporter'}
          onClose={() => setShowAlert(false)}
        />
      )}
        <h4 className='MasterFormTitle'>{<CiDeliveryTruck></CiDeliveryTruck>} Add New Transporter</h4>
        <div className='AddDiv'><form onSubmit={handleSubmit} className="row g-3">
  <div className="col-md-2">
    <label for="inputPassword4" className="form-label">Transport ID/GSTIN:</label>
    <input type="text" name='TransporterId' value={TransporterId} onChange={handleChange} className="form-control Addmasterform" id="inputPassword4" required/>
  </div>
  <div className="col-md-2">
    <label for="inputAddress" className="form-label">Transport Name:</label>
    <input type="text" name='TransporterName' value={TransporterName} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" required/>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Transportation Mode:</label>
    <select id="inputState" name='TransportationMode' value={TransportationMode} onChange={handleChange} className="form-select Addmasterform">
    <option value="">Select a Mode</option>
        <option value="1">Road</option>
        <option value="2">Rail</option>
        <option value="3">Air</option>
        <option value="4">Ship</option>
    </select>
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Vehicle Number:</label>
    <input type="text" name='VehicleNumber' value={VehicleNumber} onChange={handleChange} className="form-control Addmasterform" id="inputAddress" />
  </div>
  <div className="col-md-2">
    <label for="inputState" className="form-label">Vehicle Type:</label>
    <select id="inputState" name='VehicleType' value={VehicleType} onChange={handleChange} className="form-select Addmasterform">
    <option value="">Select a Type</option>
      <option value="R">Regular</option>
      <option value="O">ODC (Over Dimension Cargo)</option>
    </select>
  </div>
  <div className='submitinvbtndiv'>
            <button type="submit" class="btn  btn-dark" id="saveinvbtn">Save</button>
        <button type="button" onClick={handleCancelButton} class="btn  btn-dark" id="cancelinvbtn">Cancel</button></div>
</form></div>

        </>

    )
    
}

export default AddTransporter;